@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";
.Page-UserProfile{
    padding: 40px 7%;
    background-color: #F2F5F8;

    .profile-wrap,.security-wrap{
        margin-bottom: 40px;
        h1{
            color: #504658;
            font-size: 1.6rem;
            font-weight: normal;
            margin-bottom: 24px;
            padding: 0 10px;
        }
    }

    .user-info-card {
        background-color: #fff;
        border-radius: 9px;
        box-shadow: 7px 4px 28px rgba(0,0,0,0.06);
    }
    
    .user-info-img-wrap {
        display: inline-flex;
        align-items: center;
    }
    
    .user-info-avtar {
        height: 60px;
        width: 60px;
        min-width: 60px;
        margin-right: 18px;
        border-radius: 50px;
        object-fit: contain;
    }
    .user-text-wrap {

        .title {
            display: block;
            color: #1C1C1C;
            font-size: 1.2rem;
        }

        .subtitle {
            display: block;
            color: #B7B7B7;
            font-size: 0.85rem;
        }
    }
    
    
    
    .user-info-header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #F2F5F8;
        padding: 30px 40px 20px 40px;
    }
    
    .user-profile-edit{
        margin-left: auto;
        background-color: #5A4E63;
        color: #fff;
        padding: 6px 30px;
        border-radius: 25px;
        font-size: 0.85rem;
        font-family: $SemiBoldFont;
    }

    .user-info-col {
        flex:1;
    }
    
    .user-info-body {
        display: flex;
        align-items: flex-start;
        padding: 30px 40px;
    }
    
    .lbl-control {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        label {
            color: #B7B7B7;
            font-size: 0.85rem;
            min-width: 200px;
            margin-bottom: 20px;
        }
        .fld-value {
            color: #504658;
            font-size: 0.9rem;
            margin-bottom: 5px;
            display: inline-block;
            word-break: break-word;

        }
    }
   
    .val-control {
        width: calc(100% - 240px);
        margin-bottom: 15px;
    }
    .error-message{
        color: #ff0000;
        font-size: 14px;
        margin: 5px 0;
        display: block;
        padding: 0 12px;
        font-family:$SemiBoldFont;

    }
    .fld-value-input {
        border: 1px solid #eeeeef;
        border-radius: 20px;
        min-height: 38px;
        width: 100%;
        background-color: #FBFBFB;
        padding: 0 15px;
        color: #5A4E63;
        &:focus{
            outline: 0;
        }
        &.themecolor{
            width: 65px
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
    .pass-val-control {
        max-width: 300px;
        width: 100%;
    }
    .fld-pass-input {
        border: 1px solid #5A4E63;
        border-radius: 8px;
        min-height: 38px;
        width: 100%;
        background-color: #FBFBFB;
        padding: 0 15px;
        color: #5A4E63;
        margin-bottom: 15px;
        &:focus{
            outline: 0;
        }
    }

    .security-card{
        background-color: #fff;
        border-radius: 9px;
        box-shadow: 7px 4px 28px rgba(0,0,0,0.06);
        padding: 50px 40px;
        .to-to-from{
            display: flex;
            margin-bottom: 25px;
            .react-date-picker__inputGroup {
                min-width: calc(20px + 4.754em) !important;
            }
        }
        .go{
            margin-left: 20px;
            padding: 0 25px;
        }
        .date-start{
            font-size: 0.85rem;
            color: #B7B7B7;
            margin-right: 15px;
            &.theme-color{
                width: 78px;
            }
        }
        .startend-date-wrap {
            display: flex;
            margin: 0;
            align-items: center;
            .date-wrapper-icon {
                background-image: url(../../assets/images/start-end-date.svg);
                background-size: 18px;
                background-position: center;
                background-repeat: no-repeat;
                height: 40px;
                width: 40px;
                margin-left: 20px;
            }
            .react-date-picker__wrapper {
                -moz-appearance: none;
                -webkit-appearance: none;
                border: 0;
                background-color: transparent;
                border: 1px solid #f3f3f3;
                border-radius: 25px;
                padding: 0 20px 0 50px;
                height: 36px;
                min-height: 36px;
                background-image: url(../../assets/images/datepicker_c5.svg);
                background-size: 18px;
                background-position: center left 18px;
                background-repeat: no-repeat;
                width: 100%;
                color: #504658;
                font-size: $OtherText;
                font-family: "NunitoSans";
                margin: 0;
                .react-date-picker__inputGroup {
                    input {
                        color: #504658;
                        &:focus {
                            outline: 0;
                        }
                    }
                    input.react-date-picker__inputGroup__input:focus-visible{
                        outline: none !important;
                    }
                }
                .react-date-picker__button {
                    display: none;
                }
            }
            &.end-wrap{
                margin-left: 30px;
            }
        }
        .totalCount{
            .st_card {
                background-color: #fff;
                display: inline-flex;
                align-items: center;
                padding: 20px;
                -moz-box-shadow: -1px 7px 60px rgba(0,0,0,.08);
                -webkit-box-shadow: -1px 7px 60px rgba(0,0,0,.08);
                box-shadow: -1px 7px 60px rgba(0,0,0,.08);
                border-radius: $ReportWidgetRadius;
                margin: 0 8px $ReportWidgetMargin;
                flex:1;
                min-width: 200px
            }
            .st_card-icon-wrap {
                display: inline-flex;       
                height: 60px;
                width: 60px;
                min-width: 60px;
                border-radius: 50%;
                margin-right: 20px;
            }
            .st_card-icon{
                height: 100%;
                width: 100%;
                background-size:20px;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 50%;
                &.template_ic{
                    background-color: #ce2e6c21;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #f084ae;           
                }
            }
            .st_card-text-wrap {
                display: inline-flex;
                flex-direction: column;
            }
            .st_card-text{
                color:#B7B7B7;
                font-size: $OtherText;
                margin-bottom: 2px;
            }
            .st_count{
                color:#5A4E63;
                font-family: $SemiBoldFont;
                font-size: 1.4rem;
            }
            .st_card-content-wrap{
                display: inline-flex;
                flex-direction: column;
                margin: 0 20px;
            }
        }
    }


    .pass-fld-wrap {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;
    }
    
    .pass-fld-wrap label {
        color: #B7B7B7;
        font-size: 0.85rem;
        min-width: 200px;
        margin-bottom: 20px;
    }
    
    .pass-val {
        color: #5A4E63;
        letter-spacing: 3px;
        font-size: 1.1rem;
    }
    .change-pass-wrap{
        display: flex;
        align-items: flex-start;
    }
    
   .change-pas {
       margin-left: auto;
        background-color: #5A4E63;
        color: #fff;
        padding: 6px 30px;
        border-radius: 25px;
        font-size: 0.8rem;
        font-family: $Font;
        white-space: nowrap;
    }
    

    .ul-logo-wrap {
        margin-right: 20px;
        position: relative;
        display: inline-flex;

        .ul-logo-upload-position {
            position: absolute;
            right: 0px;
            bottom: 0px;
            display: inline-flex;
        }

        .ul-logo-upload-wrapper{
            position: relative;
            overflow: hidden;
            display: inline-flex;
          }
          
          .ul-logo-upload-btn {
            border:0;
            background-color: #CE2E6C;
            padding: 0;           
            background-image: url(../../assets/images/edit-icon-white.svg);
            background-size: 13px;
            background-position: center;
            background-repeat: no-repeat;
            height: 22px;
            width: 22px;
            min-width: 22px;
            display: inline-flex;
            border-radius: 50%;
            cursor: pointer;
          }
          
          .ul-logo-upload-input{
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }

        .ul-default-logo {
            height: 60px;
            width: 60px;
            min-width: 60px;
            border-radius: 50%;
            object-fit: contain;
        }
    }


    .profile-save-wrap{
            display: flex;
            justify-content: center;
        .profile-save{
            padding: 0 30px;
        }
        .profile-cancel{
            color: #B7B7B7;
            margin-left: 10px;
            padding: 4px 15px;
            background: transparent;
        }

    }

    


    @media (max-width:1000px) {
        .user-info-body{
            flex-direction: column;
        }
        .user-info-col{
            width: 100%;
        }
        
    }

    
    @media (max-width:670px) {

        .change-pass-wrap{
            flex-direction: column;
        }
        .change-pas{
            margin-left: unset;

        }

    }

    @media (max-width:580px) {

        .user-info-header{
            flex-direction: column;
            align-items: flex-start;
        }

        .user-info-img-wrap{
            flex-direction: column;
            align-items: flex-start;
        }
    
        .lbl-control{
            flex-direction: column;
            label,.val-control{
                width: 100%;
            }
        }

        .pass-fld-wrap{
            flex-direction: column;
        }

        
    }



}