@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";

/**
 *  Users.js Page Css Start
 */
.Page-Users{

    padding: 40px 7%;
    .current_account {
        background: transparent;
        border: 0;
        padding: 0;
        margin-right: 15px;
        color: #C5C5C5;
        transition: color 0.3s ease;
        font-family: "NunitoSans";
        font-size: 0.8rem;
        display: inline-flex;
        align-items: center;
    }
    .react-bootstrap-table .table{
        table-layout: fixed;
        .action-heading-name{
            text-align: center;
            margin-right: 30px;
        }
        tbody tr td:hover:after{
            display: none;
        }
      
        .ezi-datatable-row{
            .dt-bootstrap-edit-delte-wrap{
                //visibility: hidden;
                margin-right: 30px;
            }
            &:hover{
                .dt-bootstrap-edit-delte-wrap{
                    //visibility: visible;
                }
            }                     
        }

    } 

    .user-status_c {
        text-transform: capitalize;
        display: inline-flex;
        align-items: center;
        color: #1C1C1C;
        font-family: $SemiBoldFont;
        font-size: 0.88rem;
        white-space: nowrap;
        margin-right: 20px;
        cursor: pointer;
        &:before {
            content: '';
            height: 8px;
            width: 8px;
            min-width: 8px;
            display: inline-flex;
            align-items: center;
            border-radius: 50%;
            margin-right: 6px;
        }
        &.active:before {            
            background: #B5EE5F;
        }
        &.offline:before {
            background: #FF1616;
        }
    }


}
/**
 *  Users.js Page Css End
 */



 /**
 *  UsersView.js Page Css Start
 */

 @mixin  user-header {
    
    .user-info-header {
        background: #fff;
        border-radius: 10px;
        padding: 20px 30px;
        -moz-box-shadow: 7px 4px 28px rgba(0,0,0,0.06);
        -webkit-box-shadow: 7px 4px 28px rgba(0,0,0,0.06);
        box-shadow: 7px 4px 28px rgba(0,0,0,0.06);
        display: flex;
        align-items: center;
    }
    .user-header-col{
        flex: 1;
    }
    .user-info-text{
        display: flex;
        flex-direction: column;
        .title_c{
            color:#CE2E6C;            
            font-size: 1.15rem;
            margin-bottom: 3px;
            font-family: $Font;
        }
        .subtitle_c{
            color:#BCBCBC;
            font-size: 0.8rem;
        }
    }
    .user-info-role-text{
        display: flex;
        flex-direction: column;
        .user-role-title{
            color:#504658;            
            font-size: 1rem;
            margin-bottom: 3px;
            font-family: $SemiBoldFont;
        }
        .user-role-subtitle{
            color:#BCBCBC;
            font-size: 0.8rem;
            font-family:$Font;
        }
    }
    .user-info-role{
        display: flex;
        align-items: center;
        .user-info-role_ic{
            background-image: url(../../assets/images/user-role-icon.svg);
            background-size: 45px;
            background-position: center;
            background-repeat: no-repeat;
            height: 60px;
            width: 60px;
            min-width: 60px;
            display: inline-block;
            margin-right: 10px;
        }
    }
    .user-info-action{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        .user-info-action-edit{
            background-color:#5A4E63;
            color: #fff;
            border: 0;
            min-height: 30px;
            border-radius: 25px;
            padding: 0 30px;
            margin: 10px 0;
            font-family: $Font;
            font-size: 0.8rem;
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
        }
        .user-info-action-save{
            background-color:#CE2E6C;
            color: #fff;
            border: 0;
            min-height: 30px;
            border-radius: 25px;
            padding: 0 30px;
            // margin: 10px 0;
            font-family: $Font;
            font-size: 0.8rem;
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
        }
        .user-info-action-delete{
            background:transparent;
            color: #B7B7B7;
            border: 0;
            font-size: 0.8rem;
            font-family: $Font;
        }
    }


    .user-info-profile-status{
        display: flex;
        align-items: center;
        .profile-status-progress-wrap {
            margin-right: 15px;
        }
        .profile-status-progress {
            height: 60px;
            width: 60px;
            min-width: 60px;
            margin: 0 auto;
            
        }
    }
    .CircularProgressbar.profile-status-progress{
        .CircularProgressbar-trail{
            stroke: #F2F5F8;
        }
        .CircularProgressbar-path{
            stroke: #504658;
            stroke-linecap: square;
        }
        .CircularProgressbar-text{
           fill: #504658;
           font-family: $SemiBoldFont;
           font-size: 1.2rem;
        }
    }

 }
 
.Page-UsersView{
    padding: 40px 7%;
    @include user-header;
    .breadcrumb_ezi{
        border: 0;
        margin-bottom: 10px;
    }
    main.user-info-content {
        background: #fff;
        border-radius: 10px;
        box-shadow: 7px 4px 28px rgba(0, 0, 0, 0.06);
        display: flex;
        align-items: flex-start;
        margin-top: 50px;
        min-height: 400px;
        overflow: hidden;
        .user-view-side-tab{
            background-color: #FBFBFB;
            padding: 30px 0;
            border-right: 1px solid #E9E9E9;
            max-width: 280px;
            width: 100%;
        }
        .user-side-tab-nav{
            display: flex;
            flex-direction: column;
            min-height: 400px;
            .nav-item{
                margin: 5px 0;
            }
            .nav-link{
                color: #C5C5C5;
                background-color: transparent;
                border-radius: 0;
                padding: 10px 30px;
                font-size: 0.87rem;
                font-family: $Font;
                position: relative;
                &:hover{
                    background-color: #F2F5F8;
                    &:before{
                        content: '';
                        position: absolute;
                        left: 0;
                        top:0;
                        height: 100%;
                        width: 3px;
                        background-color: #CE2E6C;
                    }
                }
                &.active{
                    background-color: #F2F5F8;
                    color:#504658;
                    &:before{
                        content: '';
                        position: absolute;
                        left: 0;
                        top:0;
                        height: 100%;
                        width: 3px;
                        background-color: #CE2E6C;
                    }
                }
            }
        }
        .user-view-tab-content{
            padding: 0;
            width: calc(100% - 280px);
            .info-tab-header {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #E9E9E9;
                width: 100%;
                padding: 20px 40px;
            }
            .info-tab-header-title{
                color: #504658;
                font-size: 1.2rem;
                margin-bottom: 2px;
                font-family: $SemiBoldFont;
            }
            .info-tab-header-subtitle{
                color: #C5C5C5;
                font-size: 0.77rem;
            }
            .form-view-data{
                width: 100%;
                padding: 50px 40px 20px;
                .form-view-field-wrap{
                    width: 50%;
                    margin-bottom: 30px;
                    display: inline-flex;
                }
                .form-view-label{
                    color: #B7B7B7;
                    font-size: 0.87rem;
                    max-width: 130px;
                    width: 100%;
                    display: inline-block;
                    margin-right: 10px;
                }
                .form-view-value{
                    color: #504658;
                    font-size: 0.87rem;
                    display: inline-block;
                    width: calc(100% - 180px);
                    white-space: normal;
                    word-break: break-all;
                }
            }
        }
    }


    @media ( max-width: 991px ) {

        main.user-info-content .user-view-tab-content .form-view-data .form-view-field-wrap{
            width: 100% ;
        }
        
    }   
   

    @media ( max-width: 950px ) {

        .user-info-header{
            align-items: flex-start;
            flex-wrap: wrap;
        }
        .user-header-col{
            flex-basis: 50%;
            margin-bottom: 20px;
        }
        .user-info-action{
            justify-content: flex-start;
        }
        
    }

    @media ( max-width: 767px ) {

        main.user-info-content{
            flex-direction: column;
            .user-view-side-tab{
                max-width: 100%;
            }
            .user-side-tab-nav{
                min-height: initial;
            }
            .user-view-tab-content{
                width: 100%;
                .form-view-data .form-view-field-wrap{
                    display: flex;
                    flex-direction: column;
                 }
            }
            

        }

        
        
    }



    @media ( max-width: 600px ) {

        .user-header-col{
            flex-basis: 100%;
            margin-bottom: 40px;
        }
        
    }





}
/**
 *  UsersView.js Page Css End
 */



/**
*  UsersEdit.js Page Css Start
*/
 .Page-UsersEdit{
    padding: 40px 7%;
    @include user-header;

    .user-edit-form-wrap{
        margin: 50px 0;
    }

    .user-edit-form-header{
        font-family: $SemiBoldFont;
        color: #504658;
        font-size: 1.3rem;
        font-weight: normal;
        margin-bottom: 30px;
        margin-left: 40px;
    }

    .user-edit-form-body{
        -moz-box-shadow: 7px 4px 28px rgba(0,0,0,0.06);
        -webkit-box-shadow: 7px 4px 28px rgba(0,0,0,0.06);
        box-shadow: 7px 4px 28px rgba(0,0,0,0.06);
        background-color: #fff;
        border-radius: 9px;
    }

    .form-view-data{
        width: 100%;
        padding: 30px 40px 20px;
        .form-view-field-wrap{
            width: 50%;
            margin-bottom: 30px;
            display: inline-flex;
        }
        .form-view-label{
            color: #B7B7B7;
            font-size: 0.87rem;
            max-width: 170px;
            width: 100%;
            display: inline-block;
            margin: 8px 10px 8px 0;
        }
        .form-error-value-wrap{
            width: calc(100% - 220px);
            .error-message {
                color: #ff0000;
                font-size: 14px;
                margin: 5px 0;
                display: block;
                padding: 0 12px;
                font-family:$SemiBoldFont;
            }
            .form-control:focus {
                color: #495057;
                background-color: #fff;
                border-color: #5a4e63 !important;
                outline: 0;
                box-shadow: none !important;
            }
            .react-tel-input .form-control {
                border: 1px solid #5a4e63 !important;
                border-radius: 8px !important;
                width: 100% !important;
                height: 37px !important;
            }
            .react-tel-input .flag-dropdown {
                border: 1px solid #5a4e63 !important;
                border-radius: 8px 0 0 8px !important;
            }
            .react-tel-input .flag-dropdown.open .selected-flag {
                background: none !important; 
            }
            .react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
               background-color: none !important;
            }
        }
        .form-view-input-value{
            color: #504658;
            font-size: 0.87rem;
            display: inline-block;
            border:1px solid transparent;
            background: transparent;
            border-radius: 8px;
            padding: 8px 14px;
            width: 100% ;
            transition: all 0.5s ease;
            border:1px solid #5A4E63;
            background-color: #FBFBFB; 
            &:focus{
                outline: 0; 
            }
        }
        .form-view-select-value{
            -webkit-appearance: none;
            appearance: none;
            color: #504658;
            font-size: 0.87rem;
            display: inline-block;
            border:1px solid transparent;
            background: transparent;
            border-radius: 8px;
            padding: 8px 14px;
            width: 100%;
            background-size: 13px;
            background-position: center right 12px;
            background-repeat: no-repeat;
            transition: all 0.5s ease;
            border:1px solid #5A4E63;
            background-color: #FBFBFB;                
            background-image: url(../../assets/images/dropdown-arrow-5A.svg);
            &:focus{
                outline: 0;
            
            }
        }
        .ezi-select-plugin{
            border: 1px solid #5A4E63;
            background-color: #FBFBFB;
            border-radius: 8px;
            width: 100%;
            .css-c8odyh-control{
                border-radius: 8px;
            }
            .css-1l3nnzh-control{
                border-radius: 8px;
                &:hover,&:focus{
                    border-radius: 8px;
                }
            }
        }
    }
    .edit-form-inner-header {
        padding: 25px 40px;
        border-bottom: 1px solid #F2F5F8;
        color: #504658;
        font-size: 1rem;
        display: flex;
        align-items: center;
    }


    
    @media ( max-width: 950px ) {

        .user-info-header{
            align-items: flex-start;
            flex-wrap: wrap;
        }
        .user-header-col{
            flex-basis: 50%;
            margin-bottom: 20px;
        }
        .user-info-action{
            justify-content: flex-start;
        }
        .form-view-data .form-view-field-wrap{
            width: 100%;
        }
        
    }

    @media ( max-width: 600px ) {

        .user-header-col{
            flex-basis: 100%;
            margin-bottom: 40px;
        }
        .form-view-data {
            .form-view-field-wrap{
                display: flex;
                flex-direction: column;
            }
            .form-view-label{
                margin-bottom: 15px;
            }
            .form-view-input-value{
                width: 100%;
            }
            .form-error-value-wrap{
                width: 100%;
            }
        }
        
    }



 }
/**
*  UsersEdit.js Page Css End
*/