@import "../../assets/scss/variable.scss";
.Page-TemplateDashboard{
    padding: 40px 7%;
    background-color: #F2F5F8;

    .no-templates {
        color: #b7b7b7;
        display: block;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 0.85rem;
    }

   @import '../../assets/scss/surveyTemplateDashboard';

}