@import "./variable";
.header {
    display: flex;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 0 6px rgba(0,0,0,0.1);
    position: relative;
    z-index: 999;
}

.logo-img {
    height: 40px;
    max-width: 100%;
    width: auto;
}
.humberger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #504658;
    height: 60px;
    width: 60px;
    flex-direction: column;
}
.humberger-inner{
cursor: pointer;
}
.logo-wrap {
    display: inline-flex;
    align-items: center;
}

.logo {
    display: inline-flex;
    margin: 0 20px;
    align-items: center;
}
.humberger-inner .header-humburger-icon {
    height: auto;
    width: 28px;
}

.menu-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.notification-info {
    display: inline-flex;
    align-items: center;
    margin-right: 50px;
    height: 100%;
}

.user-info {
    display: inline-flex;
    align-items: center;
}

.avtar {
    height: 38px;
    width: 38px;
    border-radius: 50%;
}

.user-detail {
    flex-direction: column;
    display: inline-flex;
    align-items: flex-start;
    margin-right: 10px;
}

.user-detail label {
    font-family: $SemiBoldFont;
    color: #1C1C1C;
    font-size: 16px;
    margin-bottom: 0;
    text-transform: capitalize;
}

.user-detail span {
    text-align: right;
    color: #B7B7B7;
    font-size: 12px;
    font-family: $Font;
}
.notification {
    margin: 0 20px;
    position: relative;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.notification:after{
	content: '';
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translateX(-50%);
	height: 2px;
	width:100%;
	background-color: #CE2E6C;

}
.noti-icon{
	background: url(../../assets/images/notification.png);
	background-size:22px;
	height: 40px;
	width: 40px;
	background-position: center;
	background-repeat: no-repeat;
	display: inline-block;
    position: relative;    
    border-radius:25px;

}
.noti-icon:before{
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    height: 5px;
    width: 5px;
    min-width: 5px;
    background: #CE2E6C;
    border-radius: 50%;
}


// .page-content{
// 	margin: 0 10%;
//     display: flex;
//     padding: 20px;
//     flex-direction: column;
// }
// .breadcrumb_c {
//     margin-bottom: 30px;
// }
// .breadcrumb_c h1{
// 	font-size: 20px;
// 	color:#504658;
// }

// .template {
//    background-color: #504658;
//     border-radius: 5px;
//     padding: 40px 40px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
// }

// .create-text {
//        color: #fff;
//     font-size: 19px;
//     max-width: 150px;
//     line-height: 30px;
// }
// .add-more {
//         height: 70px;
//     width: 70px;
//     background: #CE2E6C;
//     border-radius: 50%;
//     display: inline-flex;
//     justify-content: center;
//     align-items: center;
//     min-width: 70px;
// }
// .add-icon{
// 	background: url(../../assets/images/plus.png);
// 	background-size:36px;
// 	height: 40px;
// 	width: 40px;
// 	background-position: center;
// 	background-repeat: no-repeat;
// 	display: inline-block;
// }
// .survey-wrap {
//     display: flex;
//         padding: 10% 0px;
// }

// .survey-box {
//     background-color: #fff;
//     margin: 0 20px;
//     border-radius: 5px;
//     padding: 20px 30px;
//     box-shadow: 0 0 10px rgba(0,0,0,.1);
// }

// .survey-text .count {
//     color: #1C1C1C;
//     font-size: 40px;
//     font-weight: 600;
// }
// .survey-text .title {
//    color: #B7B7B7;
//     font-size: 13px;
//     margin-top: 5px;
// }
// .survey-text {
//     display: flex;
//     flex-direction: column;
// }
// .survey-box.total{
// 	flex: 1;
// 	    border-bottom: 5px solid #504658;
// 	    display: flex;
//     justify-content: space-between;
//     align-items: center;

// }
// .survey-box.published{
//  border-bottom: 5px solid #CE2E6C;
// }
// .survey-box.unpublished{
// 	 border-bottom: 5px solid #B7B7B7;
// }
// .loader {
//     height: 100px;
//     width: 100px;
//     border: 5px solid #f7f7f7;
//     border-radius: 50%;
//     min-width: 100px;
//     display: inline-flex;
//     justify-content: center;
//     align-items: center;
//     position: relative;
// }
// .loader:after {
//     content: '';
//     position: absolute;
//     top: -5px;
//     left: -5px;
//     height: 100%;
//     width: 100%;
//     border: 5px solid #CE2E6C;
//     border-radius: 50%;
//     border-left-color: transparent;
// }
// .loader span{
// 	    color: #CE2E6C;
//     font-size: 20px;
//     font-weight: 600;
// }


.languageDropdown{
    margin-right: 5px;
    &:focus{
        outline: 0 !important;
        box-shadow: none !important;
    }
    button {
        display: inline-flex;
        background-color: transparent !important;
        border: 0;
        align-items: center;
        &:after{
            content: '';
            border: 5px solid transparent;
            border-top-color: #1c1c1c;
            margin-left: 10px;
            margin-top: 5px;
        }
        &:focus{
            outline: 0 !important;
            box-shadow: none !important;
        }
        .flag-avtar-wrap{
            // height:38px;
            // width: 38px;
            // min-width: 38px;
            .country-flag-label{
                color: #1C1C1C;
                margin: 0;
                font-size: 1rem;
                font-family: $SemiBoldFont;
            }
            .flag-avtar{
                height: 100%;
                min-width: 100%;
                border-radius: 50%;
            }

        }
    }
    .show.dropdown{      
        .dropdown-menu{
            margin-top: 17px;
            border:0;
            box-shadow: 0 3px 27px rgba(0, 0, 0, 0.09);
            padding: 1.1rem 0;
            a{
                color:#1C1C1C;
                font-size: 14px;
                font-family: $SemiBoldFont;
                padding:.45rem 1.5rem;
                transition: color 0.3 ease;
                cursor: pointer;
                .user-indication {
                    color: #CE2E6C;
                }
                &:hover{
                    color:#CE2E6C;
                }
                &:active{
                    background-color: inherit;
                }
              
            }
            .flag-img-wrap {
                display: flex;
                align-items: center;
                .flag_ic{
                    background-size: 100%;
                    height: 18px;
                    width: 18px;
                    background-position: center;
                    background-repeat: no-repeat;
                    display: inline-block;
                    margin-right: 12px;
                    border-radius: 50%;
                }
                .country-flag-label{
                    margin: 0;
                    cursor: pointer;
                }
            }
            .india-flag{
                background: url(../../assets/images/flag/india-flag.png);              
            }
            .japan-flag{
                background: url(../../assets/images/flag/japan-flag.png);  
            }
            .uae-flag{
                background: url(../../assets/images/flag/uae-flag.png); 
            }
            &:after {
                content: '';
                border: 6px solid transparent;
                border-bottom-color: #fff;
                position: absolute;
                right: 24px;
                top: -12px;
            }
        }
    }
}

.userInfoDropdown .dropdown{
    &:focus{
        outline: 0 !important;
        box-shadow: none !important;
    }
    button {
        display: inline-flex;
        background-color: transparent !important;
        border: 0;
        align-items: center;
        &:after{
            content: '';
            border: 5px solid transparent;
            border-top-color: #1c1c1c;
            margin-left: 10px;
        }
        &:focus{
            outline: 0 !important;
            box-shadow: none !important;
        }
    }
    &.show{      
        .dropdown-menu{
            margin-top: 10px;
            border:0;
            box-shadow: 0 3px 27px rgba(0, 0, 0, 0.09);
            padding: 1.1rem 0;
            a{
                color:#1C1C1C;
                font-size: 14px;
                font-family: $SemiBoldFont;
                padding:.45rem 1.5rem;
                transition: color 0.3 ease;
                .user-indication {
                    color: #CE2E6C;
                }
                &:hover{
                    color:#CE2E6C;
                }
                &:active{
                    background-color: inherit;
                }
              
            }
            &:after {
                content: '';
                border: 6px solid transparent;
                border-bottom-color: #fff;
                position: absolute;
                right: 50px;
                top: -12px;
            }
        }
    }
} 



.responsive-menu-wrap{
    display: none;
}
.menu-button{
    box-shadow: none;
    padding: 0;
    background: transparent;
    border: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    &:focus{
        outline:0;
        box-shadow: none;
    }
    .menu-icon{
        background: url(../../assets/images/mobile_menu.svg);
        background-size:30px;
        height: 40px;
        width: 40px;
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
    }
}





@media (max-width:600px) {
    .responsive-menu-wrap{
        display: block;
    }
    .notification-info{
        display: none;
    }
    .CommonDropdown {
        max-width: 160px;
        width: 100%;
        .dropdown button{
            width:100%;
        }
    }
}