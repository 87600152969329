@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";
.Page-TemplateCreator {
    padding: 40px 5%;
    background-color: #f2f5f8;
    // .svd_container .svd-page .svd-page-actions-container .svd-page-actions {
    //     display: inline-block !important;
    // }
    .svd_container.sv_default_css label {
        font-family: "NunitoSans";
        font-weight: normal;
        font-size: 0.85rem;
    }

    #templateCreaterElement {
        overflow-x: hidden;
        .svd_container {
            background-color: transparent;
            height: initial !important;
            &:focus {
                outline: 0;
            }
        }
        .svd-vertical-container__cell-content {
            // position: initial;
            .svd-toolbox-holder{
                overflow-y: hidden;
            }
        }
    }

    .svd_survey_header {
        .svda-title-actions {
            display: none;
        }
    }

    @import "../../assets/scss/survey_builder";

    .svd-simulator-main {
        @import "../../assets/scss/survey_builder_preview";
    }

    #templatePreviewElement {
        @import "../../assets/scss/survey_builder_preview";
    }

    .languageDropdown {
        display: flex;
        background-color: #fff;
        justify-content: flex-end;
        margin: 0;
        padding: 30px 35px 0 20px;
        .lang-text {
            color: #1c1c1c;
            font-size: 0.85rem;
            margin: 6px 10px 6px 0;
            font-family: $SemiBoldFont;
        }
        button {
            background-color: transparent !important;
            border: 1px solid #e1e1e1;
            padding: 3px 30px 3px 15px;
            border-radius: 5px;
            color: #504658;
            min-width: 110px;
            background-image: url(../../assets/images/dropdown-arrow_c2.svg);
            background-size: 12px;
            background-position: center right 10px;
            background-repeat: no-repeat;
            .flag-avtar-wrap .country-flag-label {
                font-size: 0.85rem;
                font-family: $Font;
                text-transform: capitalize;
            }
            &:after {
                display: none;
            }
        }
        .btn-primary:not(:disabled):not(.disabled).active,
        .btn-primary:not(:disabled):not(.disabled):active,
        .show > .btn-primary.dropdown-toggle {
            box-shadow: none;
            border-color: #e1e1e1;
        }
        .dropdown-item:active {
            color: #504658;
            background-color: transparent;
        }
    }

    .survey-creator-header {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .survey-theme-name {
            color: #b8b5be;
            font-size: 0.89rem;
            margin: 0 0 8px;
            text-transform: capitalize;
        }
        .survey-template-name {
            color: #504658;
            font-size: 1.2rem;
            font-family: $SemiBoldFont;
            text-transform: capitalize;
            border: 0;
            background: transparent;
            pointer-events: none;
            border-bottom: 1px solid transparent;
            min-width: 100px;
            max-width: 700px;
            padding-right: 25px;
            margin-bottom: 0;
        }
        .survey-creator-header-left {
            display: inline-flex;
            flex-direction: column;
        }
        .survey-creator-header-right {
            margin-left: auto;
            display: inline-flex;
            flex-direction: column;
        }
        .survey-status-name {
            color: #504658;
            font-size: 0.85rem;
            font-family: $SemiBoldFont;
            margin-left: 5px;
            text-transform: capitalize;
            float: unset;
            font-weight: normal;
            text-shadow: none;
            opacity: initial;
            line-height: initial;
            &:before {
                content: "";
                height: 8px;
                width: 8px;
                min-width: 8px;
                display: inline-block;
                margin-right: 7px;
                border-radius: 50%;
            }
            &.active:before {
                background-color: #b5ee5f;
            }
            &.inactive:before {
                background-color: #fbcd49;
            }
            &.draft:before {
                background-color: #b7b7b7;
            }
            &.close:before {
                background-color: #ff1616;
            }
        }

        .template_back {
            border: 0;
            background: transparent;
            padding: 0;
            color: #b8b5be;
            font-size: 0.89rem;
            font-family: $Font;
            display: inline-flex;
            align-items: center;
            margin-bottom: 8px;
            &:focus {
                outline: 0;
            }
            &:before {
                content: "";
                background-image: url(../../assets/images/breadcrumb_arrow_prev.svg);
                background-size: 7px;
                background-position: center;
                background-repeat: no-repeat;
                height: 14px;
                width: 14px;
                display: inline-block;
                transform: rotate(180deg);
                margin-right: 5px;
            }
        }
    }

    .sjs-cb-container {
        &:hover {
            input:checked ~ .checkmark {
                background-color: #ce2e6c !important;
            }
        }
        .svd_editor_control:focus {
            outline: 0;
            + span.checkmark {
                outline: 0 !important;
            }
        }
        .checkmark {
            position: absolute;
            top: 12px;
            left: 0;
            height: 18px;
            width: 19px;
            border-radius: 3px;
            &.svd-main-background-color {
                border-color: #ce2e6c;
                background-color: #ce2e6c;
            }
            &.svd-light-background-color {
                border-color: #c5c5c5;
            }
            &:focus,
            &:active {
                outline: 0 !important;
                border: 0 !important;
            }
            &:after {
                position: absolute;
                left: 6px;
                top: 1px;
                width: 6px;
                height: 11px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
            }
        }
    }

    #surveyCreatorContainer .svd_container {
        background-color: transparent;
    }

    .editor-tabs {
        border-radius: 0;
        border: 0;
        background: #f2f5f8;
        padding: 0 !important;
        display: flex;
        margin-bottom: 30px !important;
        border-bottom: 1px solid #e9e9e9;
        .nav-item {
            margin: 0;
            border: 0;
            position: relative;
            padding: 2px 20px;
            display: flex;
            justify-content: center;
            background-color: #f9f9f9;
            padding-right: 50px;
            border-radius: 5px 0 0 0;
            &.active {
                background-color: #fff;
            }
            &:after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                border-style: solid;
                border-width: 0 30px 40px 0;
                border-color: transparent #f2f5f8 transparent transparent;
            }
            .nav-link {
                color: #b7b7b7;
                border: 0;
                font-family: $SemiBoldFont;
                font-size: 0.85rem;
                display: inline-flex;
                position: relative;
                background: transparent;
                line-height: unset;
                height: 36px;
                display: inline-flex;
                align-items: center;
                &:hover {
                    border: 0;
                    background: none;
                }
                &:focus {
                    outline: 0;
                    border: 0;
                    background: none;
                }
            }
            &.active {
                .nav-link {
                    color: #ce2e6c;
                    border: 0;
                    font-family: $SemiBoldFont;
                    font-size: 0.85rem;
                    &:after {
                        content: "";
                        // height: 2px;
                        // width: 100%;
                        // background-color: #CE2E6C;
                        // position: absolute;
                        // bottom: -8px;
                        // left: 0;
                    }
                }
            }
        }
    }

    .template_back {
        border: 0;
        background: transparent;
        padding: 0;
        color: #b8b5be;
        font-size: 0.89rem;
        font-family: $Font;
        display: inline-flex;
        align-items: center;
        &:focus {
            outline: 0;
        }
        &:before {
            content: "";
            background-image: url(../../assets/images/breadcrumb_arrow_prev.svg);
            background-size: 7px;
            background-position: center;
            background-repeat: no-repeat;
            height: 14px;
            width: 14px;
            display: inline-block;
            transform: rotate(180deg);
            margin-right: 5px;
        }
    }

    .tablist_ezi.survey-tab-container {
        .survey-tab-header-wrap {
            background-color: #fff;
            border-radius: 5px;
            -moz-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
            -webkit-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
            box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
            .tab-left-header {
                display: flex;
                width: 100%;
                .nav {
                    display: flex;
                    width: inherit;
                }
                .nav-item {
                    margin: 0;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    .nav-link {
                        display: inline-flex;
                        padding: 12px 24px;
                        color: #b7b7b7;
                        font-size: 0.89rem;
                        &.active {
                            color: #1c1c1c;
                            &:after {
                                background-color: #504658;
                            }
                        }
                    }
                    &.template-question-builder {
                        flex: unset;
                        padding: 0 45px;
                    }
                    &.template-preview {
                        flex: unset;
                        margin-left: auto;
                        margin-right: 8%;
                    }
                }
                .survey-preview-btn-wrap {
                    flex: unset;
                    .nav-link {
                        padding: 0;
                    }
                    .survey-preview-btn {
                        border: 0;
                        background-color: #ce2e6c;
                        color: #fff;
                        border-radius: 5px;
                        padding: 0 30px;
                        &:focus {
                            outline: 0;
                        }
                    }
                }
            }
        }

        .survey-tab-content {
            padding: 0;
            margin: 30px 0 20px;
        }
    }

    //tab css for participant , survey setting and launch survey
    .tablist_ezi.survey-inner-tab-container {
        background-color: #fff;
        border-radius: 5px;
        -moz-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.16);
        box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.16);
        .tab-left-header {
            .nav-item {
                margin: 0 30px 0 0;
                .nav-link {
                    padding: 20px 20px;
                    font-size: 0.89rem;
                }
            }
        }

        .survey-inner-tab-header-wrap {
            display: flex;
            border-bottom: 1px solid #e9e9e9;
            padding: 0 40px;
        }
        .tab-right-header {
            .nav-item {
                .nav-link {
                    background-color: transparent;
                    padding: 0;
                }
            }
            .inner_save_btn {
                margin-right: 10px;
                padding: 0 30px;
                &:focus {
                    outline: 0;
                }
            }
            .inner_reset_btn {
                border: 0;
                background-color: transparent;
                color: #b7b7b7;
                &:focus {
                    outline: 0;
                }
            }
        }

        .survey-inner-tab-content {
            padding: 40px 40px;
        }

        // participant source TAB css Start

        .participant-source-datatable .react-bootstrap-table {
            box-shadow: none;
            border: 1px solid #e9e9e9;
        }

        .participant-source-wrap,
        .participant-list-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            .participant-source-heading,
            .participant-list-heading {
                color: #1c1c1c;
                font-size: 1rem;
                font-family: $SemiBoldFont;
                margin: 0 40px 0 0;
                padding: 9px 0;
                max-width: 150px;
                width: 100%;
            }
        }
        .participant-list-wrap {
            margin-top: 20px;
        }
        .sampled-select-wrap {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;
            .survey-button-tab-select {
                flex: 1;
                margin: 20px 15px 0;
            }
        }
        .dropzone-wrap.survey-dropzone-wrap {
            .dropzone-area {
                margin: 14px 0 10px;
                &:after {
                    width: calc(100% - 10px);
                }
            }
        }

        .custom-demography-select-wrap {
            position: relative;
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .invitees-length-btn {
            border: 1px solid #b7b7b7;
            background: transparent;
            border-radius: 25px;
            color: #b7b7b7;
            font-family: $Font;
            font-size: 0.87rem;
            min-height: 36px;
            padding: 0 20px;
            margin: 10px 0;
            transition: all 0.3s ease;
            &:focus {
                outline: 0;
            }
            &:hover {
                background: #ce2e6c;
                border-color: #ce2e6c;
                color: #fff;
                .invitees-length-badge {
                    background-color: #f12675;
                    color: #fff;
                }
            }
        }
        .invitees-length-badge {
            color: #656565;
            height: 22px;
            line-height: 23px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 22px;
            background-color: #b7b7b7;
            border-radius: 25px;
            font-size: 0.7rem;
            font-family: "NunitoSansSemiBold";
            padding: 0 5px;
            margin-left: 10px;
            transition: all 0.3s ease;
        }
        .custom-demography-select {
            border: 0;
            color: #d9d9d9;
            font-family: $Font;
            font-size: 0.85rem;
            background-color: transparent;
            background-image: url(../../assets/images/dropdown-arrow_c2.svg);
            background-size: 12px;
            background-position: center right 15px;
            background-repeat: no-repeat;
            background-color: transparent;
            border: 1px solid #f3f3f3;
            border-radius: 25px;
            max-width: 200px;
            width: 100%;
            min-height: 36px;
            text-align: left;
            padding: 0 35px 0 25px;
            margin: 10px 0;

            &:focus {
                outline: 0;
            }
            &.selected {
                color: #ce2e6c;
                background-image: url(../../assets/images/show-entry_ic.svg);
            }
        }
        .custom-demography-select-dropdown {
            display: none;
            background-color: #fff;
            border-radius: 12px;
            padding: 0;
            max-width: 450px;
            height: 300px;
            max-height: 300px;
            width: 100%;
            flex-direction: column;
            // overflow: hidden;
            position: absolute;
            z-index: 9999;
            top: 100%;
            left: 0;
            margin-top: 8px;
            padding: 0;
            -moz-box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.09);
            -webkit-box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.09);
            &.active {
                display: inline-flex;
            }

            ul {
                position: relative;
                padding: 0;
                height: calc(100% - 70px);
                overflow-y: auto;
                &::-webkit-scrollbar {
                    width: 4px;
                }
                li {
                    list-style-type: none;
                    padding: 0 10px;
                    font-family: $Font;
                    color: #b7b7b7;
                    transition: color 0.3 ease;
                    position: relative;
                    font-size: 0.82rem;
                    // &:hover{
                    //     color:#CE2E6C;
                    //     cursor: pointer;
                    //     background-color: #FBFBFB;
                    //     &:after{
                    //         content: '';
                    //             width:3px;
                    //             height:100%;
                    //             position: absolute;
                    //             left:0;
                    //             top:0;
                    //             background-color:#CE2E6C;
                    //     }

                    // }
                    &:last-child {
                        padding-bottom: 20px;
                    }
                }
            }
        }

        .demography-checkbox-wrap {
            display: flex;
            padding: 6px 0;
            align-items: center;
            font-size: 0.78rem;
        }

        .participant-source-container,
        .participant-list-container {
            flex: 1;
        }

        .react-bootstrap-table {
            margin-top: 30px;
            .table tbody tr td:hover:after {
                display: none;
            }
        }
        .survey-button-tab-select,
        .survey-button-tab-input {
            -moz-appearance: none;
            -webkit-appearance: none;
            border: 0;
            background-color: transparent;
            border: 1px solid #f3f3f3;
            border-radius: 25px;
            padding: 0 30px;
            height: 36px;
            min-height: 36px;
            background-image: url(../../assets/images/dropdown-arrow_c2.svg);
            background-size: 12px;
            background-position: center right 15px;
            background-repeat: no-repeat;
            max-width: 200px;
            width: 100%;
            color: #504658;
            font-size: 0.85rem;
            font-family: $Font;
            margin: 20px 0;
            &:focus {
                outline: 0;
                // background-color: #F2F5F8;
                // border: 1px solid #D9D9D9;
            }
        }
        .survey-button-tab-input {
            background-image: none;
        }

        .survey-button-tab-header .nav-pills .nav-item {
            .nav-link {
                background-color: #f2f5f8;
                border-radius: 25px;
                padding: 6px 30px;
                color: #504658;
                font-size: 0.85rem;
                &.active {
                    background-color: #ce2e6c;
                    color: #fff;
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .survey-button-tab-content {
            padding: 0;
        }

        // Button TAB css End

        // demograpgy Filter css Start
        .demograpgy-filter-wrap {
            height: 100%;
            position: relative;
            &:before {
                border: 6px solid transparent;
                border-bottom-color: #fff;
                position: absolute;
                left: 170px;
                top: -12px;
                content: "";
                z-index: 99;
            }
            .filter-tab-header {
                background: #fbfbfb;
                max-width: 200px;
                min-width: 200px;
                width: 100%;
                .nav.nav-pills {
                    display: flex;
                    flex-wrap: nowrap;
                    flex-direction: column;
                    overflow-y: auto;
                    height: 100%;
                    padding-top: 10px;
                    &::-webkit-scrollbar {
                        width: 4px;
                    }
                }
                .nav-link {
                    color: #c5c5c5;
                    font-size: 0.78rem;
                    background-color: transparent;
                    text-transform: capitalize;
                    font-family: $Font;
                    padding: 5px 30px 5px 15px;
                }
                .nav-link.active {
                    color: #5a4e63;
                    background-image: url(../../assets/images/breadcrumb‭_arrow.svg);
                    background-size: 6px;
                    background-position: center right 15px;
                    background-repeat: no-repeat;
                }

                .nav-item:last-child {
                    .nav-link {
                        padding-bottom: 20px;
                    }
                }
            }
            .demography-filter-search-wrap {
                display: flex;
                align-items: center;
                margin: 20px 0;
            }

            .check_all_wrapper {
                display: inline-flex;
                overflow: hidden;
                position: relative;
                padding: 0 10px;

                .check_all_checkbox {
                    position: absolute;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    cursor: pointer;
                }

                .check_all_txt {
                    font-family: "NunitoSansSemiBold";
                    font-size: 0.89rem;
                    color: #b7b7b7;
                    transition: color 0.3s ease;
                    &.checkbox-active {
                        color: #ce2e6c;
                    }
                }
                &:hover .check_all_txt {
                    color: #ce2e6c;
                }
            }

            .filter-select-all {
                border: 0;
                background: transparent;
                color: #b7b7b7;
                font-size: 0.8rem;
                padding: 4px 10px;
                display: inline-flex;
                margin-right: 0px;
                &:hover {
                    color: #ce2e6c;
                }
                &:focus {
                    outline: 0;
                }
            }
            .demography-filter-search {
                border-radius: 25px;
                border: 1px solid #f6f6f6;
                padding: 0 10px;
                min-height: 30px;
                margin: 0 10px;
                font-size: 0.8rem;
                color: #5a4e63;
                &:focus {
                    background-color: #f2f5f8;
                    outline: 0;
                }
                @include placeholder {
                    color: #d9d9d9;
                }
            }
            .demography-filter-inner {
                height: calc(100% - 46px);
                display: flex;
            }
            .demography-filter-tab-content {
                width: 100%;
                padding: 0 0 0 10px;
                .tab-pane {
                    height: 100%;
                }
            }
            .demograpgy-filter-header {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                border-bottom: 1px solid #f6f6f6;
            }
            .demograpgy-filter-heading {
                margin: 0;
                color: #ce2e6c;
                padding-left: 15px;
                font-size: 1rem;
            }
            .filter-btn-wraps {
                margin-left: auto;
            }
            .filter-apply-btn {
                padding: 0 16px;
                min-height: 24px;
                font-size: 0.78rem;
                margin-right: 10px;
            }
            .filter-close-btn {
                padding: 0;
                border: 0;
                background-color: transparent;
                color: #cecece;
                font-size: 0.78rem;
                &:focus {
                    outline: 0;
                }
            }
        }
        // demograpgy Filter css End
    }

    // Right Edit Modal css start

    #templateCreaterElement {
        .svd_container #surveyquestioneditorwindow {
            top: 70px;
            svd-accordion {
                .svd-accordion-tab-header {
                    min-height: 40px;
                    line-height: 40px;
                    background-color: #f2f5f8;
                    border-radius: 5px;
                    padding: 0;
                    > span {
                        width: 100%;
                        display: inline-block;
                        color: #656565;
                        border-radius: 5px;
                        padding-left: 15px;
                        background-image: url(../../assets/images/dropdown-arrow_c2.svg);
                        background-size: 14px;
                        background-position: right 10px center;
                        background-repeat: no-repeat;
                    }
                    .icon-toolbox-arrow {
                        display: none;
                    }
                    .svd-main-color {
                        background-color: #504658;
                        width: 100%;
                        display: inline-block;
                        color: #fff;
                        border-radius: 5px;
                        padding-left: 15px;
                        background-image: url(../../assets/images/dropdown_icon.svg);
                        background-size: 14px;
                        background-position: right 10px center;
                        background-repeat: no-repeat;
                    }
                }
                .sjs-cb-wrapper {
                    margin: 0 0 7px;
                }
                .sjs-cb-container {
                    .svd-main-background-color {
                        background-color: #ce2e6c;

                        border: 0;
                    }
                    .checkmark {
                        border-radius: 3px;
                        transform: unset;
                        &:after {
                            left: 8px;
                            top: 4px;
                            width: 6px;
                            height: 11px;
                            border: solid white;
                            border-width: 0 3px 3px 0;
                            transform: rotate(45deg);
                        }
                        &:focus {
                            outline: 0;
                        }
                    }
                }
                .svd-accordion-tab-content {
                    .form-group label {
                        color: #504658;
                        font-family: $SemiBoldFont;
                        font-weight: normal;
                        font-size: 0.86rem;
                        margin-bottom: 10px;
                    }
                    .svd-popup-editor-tab-choices {
                        .btn-primary {
                            border-radius: 25px;
                            padding: 7px 17px;
                            color: #fff;
                            font-size: 0.76rem;
                            font-family: $Font;
                            font-weight: normal;
                            margin: 10px 0;
                            border: 0;
                        }
                        .btn-link {
                            border-radius: 25px;
                            padding: 7px 17px;
                            color: #656565;
                            font-size: 0.76rem;
                            font-family: $Font;
                            font-weight: normal;
                            background-color: #e9e9e9;
                            border: 0;
                            margin: 10px 5px;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                        .svd_items_table {
                            tbody {
                                tr {
                                    .svd-itemvalue-actions-container {
                                        .svd-drag-handle {
                                            background-image: url(../../assets/images/drag_field_ic.svg);
                                            background-size: 18px;
                                            background-position: center;
                                            background-repeat: no-repeat;
                                            text-indent: -9999px;
                                            height: 40px;
                                            width: 40px;
                                            min-width: 40px;
                                        }
                                        .svd-textitems-edit {
                                            background-color: #e9e9e9;
                                            color: #656565;
                                        }
                                    }
                                    .svd-textitems-column {
                                        .btn-danger {
                                            background-color: transparent;
                                            border: 1px solid #e9e9e9;
                                            border-radius: 5px;
                                            text-indent: -9999px;
                                            height: 40px;
                                            width: 40px;
                                            min-width: 40px;
                                            background-image: url(../../assets/images/table_delete_ic.svg);
                                            background-size: 13px;
                                            background-position: center;
                                            background-repeat: no-repeat;
                                            text-indent: -9999px;
                                        }
                                    }
                                }
                            }
                        }
                        .svd-items-control-footer {
                            .btn-danger {
                                margin: 10px 5px;
                                border-radius: 25px;
                                padding: 7px 17px;
                                color: #656565;
                                font-size: 0.76rem;
                                font-family: $Font;
                                font-weight: normal;
                                background-color: #e9e9e9;
                                border: 0;
                            }
                        }
                    }
                    .propertyeditor-condition {
                        padding: 10px 0;
                        .form-control {
                            margin: 0 0 10px 0;
                        }
                        .svd_custom_select {
                            margin: 0 15px 10px 0;
                        }
                        .form-control.btn-primary {
                            font-family: $Font;
                            border-radius: 25px;
                            padding: 7px 20px;
                            color: #fff;
                            font-size: 0.76rem;
                            font-weight: normal;
                            margin: 10px 0;
                            border: 0;
                            background-color: #ce2e6c;
                            min-height: initial;
                            height: initial;
                        }
                    }
                    .propertyeditor-validators {
                        .input-group {
                            .input-group-addon.first-addon {
                                width: 44px;
                                height: 40px;
                                min-width: 44px;
                                padding: 0;
                                background-color: transparent;
                                border: 1px solid #e9e9e9;
                                border-radius: 5px;
                                .glyphicon.glyphicon-plus::before {
                                    font-weight: normal;
                                    font-size: 23px;
                                    content: "+";
                                    color: #656565;
                                }
                                + .form-control.svd_custom_select {
                                    border: 1px solid #e9e9e9;
                                    border-radius: 5px;
                                    min-height: 40px;
                                    line-height: initial;
                                    width: calc(100% - 30px);
                                    margin: 0 15px;
                                }
                            }

                            .input-group-addon.btn-danger {
                                width: 44px;
                                height: 40px;
                                min-width: 44px;
                                padding: 0;
                                background-color: transparent;
                                border: 1px solid #e9e9e9;
                                border-radius: 5px;
                                background-image: url(../../assets/images/table_delete_ic.svg);
                                background-size: 13px;
                                background-position: center;
                                background-repeat: no-repeat;
                                text-indent: -9999px;
                            }
                        }
                        // .svd_custom_select{
                        //     margin: 0 15px;
                        // }
                    }
                }
            }
            .modal-dialog {
                margin: 0 0 0 auto;
                height: calc(100% - 70px);
                .modal-content {
                    box-shadow: none;
                    border-radius: 0;
                    height: 100%;
                    border: 0;
                    border-top: 1px solid #f2f5f8;
                    .modal-header {
                        justify-content: flex-start;
                        position: relative;
                        border-bottom: 1px solid #f2f5f8;
                        padding: 26px 30px;
                        .close {
                            position: absolute;
                            right: 30px;
                            font-size: 1.5rem;
                            color: #cecece;
                            opacity: 1;
                            font-weight: normal;
                            float: unset;
                            font-family: $Font;
                            margin: 0;
                            padding: 5px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        .modal-title {
                            color: #ce2e6c;
                            font-size: 1.1rem;
                            font-family: $Font;
                        }
                    }
                    .modal-body {
                        background-color: #fbfbfb;
                        padding: 30px;
                        ::-webkit-scrollbar {
                            width: 6px;
                        }
                        .form-control {
                            border: 1px solid #e9e9e9;
                            border-radius: 5px;
                            min-height: 40px;
                            line-height: initial;
                        }
                        textarea {
                            resize: none;
                        }
                        .svd_custom_select {
                            &:before {
                                background-color: #e9e9e9;
                                color: #656565;
                                border-radius: 0 5px 5px 0;
                                height: 100%;
                                background-image: url(../../assets/images/dropdown-arrow_c2.svg);
                                background-size: 12px;
                            }
                        }
                    }
                    .modal-footer {
                        padding: 15px 30px;
                        border-top: 1px solid #f2f5f8;
                        .btn-secondary {
                            background-color: #ce2e6c;
                            border-radius: 25px;
                            padding: 8px 32px;
                            font-size: 0.8rem;
                            font-weight: normal;
                            font-family: $Font;
                            width: initial !important;
                            margin: 0 5px 0 0;
                        }
                        .btn-primary {
                            background-color: transparent;
                            border-radius: 25px;
                            padding: 8px 14px;
                            color: #cecece;
                            font-family: $Font;
                            font-size: 0.8rem;
                            width: initial !important;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    // Right Edit Modal css End

    // page theme ma[pping css start
    .Page-ThemeMapping {
        .questions-header {
            display: flex;
            align-items: center;
            padding: 20px 30px;
            border-bottom: 1px solid #e9e9e9;
            margin-bottom: 20px;
        }
        .question-heading {
            color: #ce2e6c;
            font-family: $SemiBoldFont;
            font-size: 1rem;
            display: inline-flex;
        }
        .question-theme-select-wrap {
            margin-left: auto;
            display: flex;
            align-items: center;
        }
        .question-theme-select {
            margin-right: 10px;
        }
        .question-theme-select,
        .question-subtheme-select {
            -moz-appearance: none;
            -webkit-appearance: none;
            background: transparent;
            border-radius: 9px;
            padding: 0 35px 0 15px;
            min-height: 40px;
            height: 40px;
            border: 1px solid #c2c2c2;
            color: #5a4e63;
            font-family: $Font;
            font-size: 0.87rem;
            max-width: 160px;
            width: 100%;
            min-width: 160px;
            text-transform: capitalize;
            background-image: url(../../assets/images/dropdown-arrow_c2.svg);
            background-size: 12px;
            background-position: center right 15px;
            background-repeat: no-repeat;
            &:focus {
                outline: 0;
            }
        }
        .question-save-wrap {
            margin-left: auto;
            .question-save {
                padding: 0 30px;
                margin-right: 12px;
            }
            .question-reset {
                background-color: transparent;
                border: 0;
                color: #b7b7b7;
                font-family: $Font;
                font-size: 0.8rem;
            }
        }
        .question-wrapper-section {
            background-color: #fff;
            border-radius: 5px;
            -moz-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.16);
            -webkit-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.16);
            box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.16);
        }
        .question-wrapper {
            display: flex;
            align-items: center;
            padding: 10px 30px;
        }
        .question-list {
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 20px;
            color: #5a4e63;
            width: 100%;
            font-family: $Font;
            font-size: 1rem;
        }
    }
    // page theme mapping css End

    // Surevy tab inner tab css Start

    .survey-type-wrap,
    .statement-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .survey-type-heading,
        .statement-heading {
            color: #1c1c1c;
            font-size: 1rem;
            font-family: $SemiBoldFont;
            margin: 0 40px 0 0;
            padding: 9px 0;
            max-width: 150px;
            width: 100%;
        }
    }

    .survey-type-wrap {
        .startend-date-wrap {
            display: flex;
            margin: 20px 0;
            .end-date-wrap {
                margin: 0 20px;
            }
            .date-wrapper-icon {
                background-image: url(../../assets/images/start-end-date.svg);
                background-size: 18px;
                background-position: center;
                background-repeat: no-repeat;
                height: 40px;
                width: 40px;
                margin-left: 20px;
            }
            .react-date-picker__wrapper {
                -moz-appearance: none;
                -webkit-appearance: none;
                border: 0;
                background-color: transparent;
                border: 1px solid #f3f3f3;
                border-radius: 25px;
                padding: 0 20px 0 50px;
                height: 36px;
                min-height: 36px;
                background-image: url(../../assets/images/datepicker_c5.svg);
                background-size: 18px;
                background-position: center left 18px;
                background-repeat: no-repeat;
                width: 100%;
                color: #504658;
                font-size: 0.85rem;
                font-family: "NunitoSans";
                margin: 0;
                .react-date-picker__inputGroup {
                    input {
                        color: #504658;
                        &:focus {
                            outline: 0;
                        }
                    }
                }
                .react-date-picker__button {
                    display: none;
                }
            }
        }
    }

    .statement-wrap {
        .statement-select-wrap {
            display: flex;
            select {
                margin-right: 15px !important;
            }
        }
    }

    .channel-main-wrap {
        display: flex;
        align-items: flex-start;
        margin-right: 10%;
        .channel-checkbox-wrap {
            display: inline-flex;
            align-items: center;
            max-width: 230px;
            width: 100%;
            margin-bottom: 25px;
        }
        .channel-heading {
            color: #1c1c1c;
            font-size: 1rem;
            font-family: $SemiBoldFont;
            margin: 0 40px 0 0;
            max-width: 200px;
            min-width: 200px;
            width: 100%;
            padding-left: 20px;
        }
        .channel-name {
            margin: 0;
            color: #b7b7b7;
            font-size: 0.87rem;
        }
        .channel-content {
            display: flex;
            flex-wrap: wrap;
            // flex: 1;
            width: calc(100% - 200px);
        }
    }

    .main-channel-tab-wrap {
        border: 1px solid #e9e9e9;
        border-radius: 25px;
        margin: 30px 0;
        width: 100%;
        &.border-none {
            border: 0;
            .survey-channel-tab-header {
                border: 0;
            }
        }
        .survey-channel-tab-header {
            border-bottom: 1px solid #e9e9e9;
            padding: 0 10px;
            .tab-left-header {
                .nav-item {
                    margin: 0 20px;
                    .nav-link {
                        padding: 20px 10px;
                        font-size: 0.77rem;
                    }
                }
            }
        }
        .channel-select-mail {
            -moz-appearance: none;
            -webkit-appearance: none;
            border: 0;
            background-color: transparent;
            border: 1px solid #f3f3f3;
            border-radius: 25px;
            padding: 0 30px;
            height: 36px;
            min-height: 36px;
            background-image: url(../../assets/images/dropdown-arrow_c2.svg);
            background-size: 12px;
            background-position: center right 15px;
            background-repeat: no-repeat;
            width: 100%;
            color: #504658;
            font-size: 0.85rem;
            font-family: "NunitoSans";
            margin: 0;
            &:focus {
                outline: 0;
            }
        }
        .email-preview-wrap {
            padding: 0;
            border: 1px solid #e9e9e9;
            border-radius: 25px;
            margin: 20px 0;
            position: relative;
            overflow: hidden;
        }
        .email-preview-length {
            position: absolute;
            right: 20px;
            bottom: 10px;
            color: #b7b7b7;
            font-size: 0.65rem;
        }
        .email-preview-header {
            padding: 15px 30px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #e9e9e9;
        }
        .email-preview-heading {
            padding: 0;
            color: #504658;
            font-size: 0.85rem;
            font-family: $SemiBoldFont;
        }
        .email-default-placeholder-wrap {
            display: inline-flex;
            align-items: center;
            margin: 0 20px;
        }
        .email-default-placeholder {
            -moz-appearance: none;
            -webkit-appearance: none;
            background: #f2f5f8;
            border-radius: 9px;
            padding: 0 15px;
            min-height: 40px;
            height: 40px;
            border: 0;
            color: #c2c2c2;
            font-family: "NunitoSans";
            font-size: 0.87rem;
            max-width: 160px;
            width: 100%;
            min-width: 160px;
            background-image: url(../../assets/images/dropdown-arrow_c2.svg);
            background-size: 12px;
            background-position: center right 15px;
            background-repeat: no-repeat;
            &:focus {
                outline: 0;
            }
        }
        .email-preview-edit {
            padding: 0;
            background-image: url(../../assets/images/email-preview-edit.svg);
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            text-indent: -9999px;
            height: 20px;
            width: 20px;
            background-color: transparent;
            box-shadow: none;
            border: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            &:focus {
                outline: 0;
            }
        }
        .email-preview-copy {
            padding: 0 25px 0 44px;
            background-image: url(../../assets/images/email-copy-icon.svg);
            background-size: 16px;
            background-position: left 20px center;
            background-repeat: no-repeat;
            background-color: #f2f5f8;
            box-shadow: none;
            border: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            min-height: 30px;
            color: #504658;
            font-size: 0.85rem;
            &:focus {
                outline: 0;
            }
        }
        .email-preview-content {
            padding: 0;
            min-height: 250px;
            background-color: #f2f5f8;
            border-radius: 0 0 25px 25px;
            padding: 0 0 35px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 4px;
            }
        }
        .placeholder-btn-wrap {
            display: flex;
            padding: 20px 20px;
            background-color: #e9e9e9;
            overflow-x: auto;
            &::-webkit-scrollbar {
                height: 4px;
            }
            .placeholder-btn-inner {
                padding: 0 8px;
                &:last-child {
                    padding-right: 30px;
                }
            }
            .insert-placeholder-btn {
                background-color: #ce2e6c;
                color: #fff;
                border: 0;
                min-height: 36px;
                border-radius: 5px;
                padding: 0 20px;
                font-family: "NunitoSans";
                font-size: 0.8rem;
                display: inline-flex;
                align-items: center;
                white-space: nowrap;
                &:focus {
                    outline: 0;
                }
            }
        }

        .channel-form-field {
            display: flex;
            align-items: flex-start;
            margin-bottom: 4px;
            label {
                color: #504658;
                max-width: 120px;
                width: 100%;
                font-size: 0.85rem;
                font-family: $SemiBoldFont;
                padding: 8px 0;
            }
            .email-preview-input {
                color: #8d8d8d;
                font-size: 0.85rem;
                background: transparent;
                border: 0;
                width: 100%;
                resize: none;
                pointer-events: none;
                border-radius: 8px;
                padding: 8px 20px;
                &:focus {
                    outline: 0;
                }
            }
            .email-preview-textarea {
                color: #8d8d8d;
                font-size: 0.85rem;
                background: transparent;
                border: 0;
                width: 100%;
                resize: none;
                overflow: auto;
                height: auto;
                line-height: 24px;
                pointer-events: none;
                border-radius: 8px;
                padding: 8px 20px;
                &:focus {
                    outline: 0;
                }
            }
        }
        .channel-form-wrap {
            padding: 25px 30px 0;
            .email-preview-input-edit.email-preview-input,
            .email-preview-input-edit.email-preview-textarea {
                pointer-events: initial;
                border: 1px solid #dbdbdb;
                background-color: #e9e9e9;
            }
        }
        .email-code-formatter {
            padding: 20px 30px;
            color: #8d8d8d;
            font-size: 0.75rem;
            white-space: normal;
        }
    }

    // Surevy tab inner tab css End

    /* The ezi-checkbox start*/
    .ezi-checkbox {
        display: inline-flex;
        align-items: center;
        position: relative;
        height: 16px;
        width: 17px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin: 0 15px 0 0;
    }

    .ezi-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .ezi-checkbox-mark {
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 17px;
        border: 1px solid #c5c5c5;
        border-radius: 3px;
    }

    .ezi-checkbox:hover input ~ .ezi-checkbox-mark {
        background-color: #ccc;
    }

    .ezi-checkbox input:checked ~ .ezi-checkbox-mark {
        background-color: #ce2e6c;
        border-color: #ce2e6c;
    }

    .ezi-checkbox-mark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .ezi-checkbox input:checked ~ .ezi-checkbox-mark:after {
        display: block;
    }

    .ezi-checkbox .ezi-checkbox-mark:after {
        left: 5px;
        top: 0px;
        width: 6px;
        height: 11px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    /* The ezi-checkbox End*/

    /* * *
     preview css start
*/

    .preview-survey-header {
        background-color: #fff;
        border-radius: 5px;
        -moz-box-shadow: 4px 3px 24px rgba(0, 0, 0, 0.07);
        -webkit-box-shadow: 4px 3px 24px rgba(0, 0, 0, 0.07);
        box-shadow: 4px 3px 24px rgba(0, 0, 0, 0.07);
        margin-bottom: 30px;
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;

        .preview-survey- {
            &header-left {
                display: inline-flex;
                align-items: center;
            }
            &header-right {
                display: inline-flex;
                align-items: center;
            }
            &logo-wrap {
                margin-right: 15px;
                max-width: 80px;
                min-width: 80px;
                width: 100%;
                display: inline-flex;
            }
            &company-name {
                color: #b7b7b7;
                font-size: 0.85rem;
                margin-bottom: 2px;
            }
            &logo {
                height: auto;
                width: 100%;
                max-width: 80px;
                min-width: 80px;
            }
            &name {
                color: #1c1c1c;
                font-size: 1.12rem;
                font-family: $SemiBoldFont;
            }
        }

        .preview-text-wrap {
            display: flex;
            flex-direction: column;
        }
    }

    .preview-survey-feedback-wrap {
        background-color: #fff;
        border-radius: 5px;
        -moz-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
        box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
        padding: 50px 20px;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        .preview-survey- {
            &feedback-heading {
                color: #ce2e6c;
                font-size: 1.12rem;
                text-align: center;
                margin-bottom: 15px;
            }
            &feedback-para {
                color: #1c1c1c;
                font-size: 0.95rem;
                line-height: 30px;
                text-align: center;
            }
            &text-wrap {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                max-width: 550px;
            }
        }
    }

    .preview-survey-thank-wrap {
        background-color: #fff;
        border-radius: 5px;
        -moz-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
        box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
        padding: 50px 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 30px 0;
        .preview-survey-complete-ic {
            height: 56px;
            width: 56px;
            display: inline-block;
            background-image: url(../../assets/images/widget/survey-complete-check.svg);
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            margin-bottom: 20px;
        }
        .preview-survey-feedback-para {
            color: #ce2e6c;
            font-size: 1.3rem;
            text-align: center;
            max-width: 200px;
            line-height: 32px;
        }
    }

    .preview-survey-poweredby- {
        &wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
            margin: 60px 0 20px;
        }
        &heading {
            color: #504658;
            font-size: 0.85rem;
            font-family: $SemiBoldFont;
        }
        &logo {
            width: 100%;
            max-width: 200px;
            margin-bottom: 15px;
        }
        &text {
            color: #b8b8b8;
            font-size: 0.77rem;
            font-family: $LightFont;
            max-width: 220px;
            text-align: center;
            line-height: 24px;
        }
    }

    #templatePreviewElement {
        padding: 30px 15%;
    }

    .template-preview-wrap {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.16);
        min-height: 300px;
        overflow: hidden;
        position: relative;

        .sv_cw_pretty_checkbox_checkbox {
            color: #504658;
            margin-bottom: 10px;
            .pretty input:checked ~ .state.p-success label:after,
            .pretty.p-toggle .state.p-success label:after {
                background-color: #ce2e6c !important;
                background-image: url(../../assets/images/chechbox-check.png);
                background-size: 13px;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        .sv_cw_pretty_checkbox_radiogroup {
            margin-bottom: 10px;
            .pretty input:checked ~ .state.p-success label:after,
            .pretty.p-toggle .state.p-success label:after {
                background-color: #ce2e6c !important;
                transform: scale(0.7);
            }
        }
        .pretty .state label {
            text-indent: 1.8em;
            font-size: 0.89rem;
            &::first-letter {
                text-transform: uppercase;
            }
        }
        .pretty .state label:before {
            border-color: #e9e9e9;
        }
        .pretty .state label:before,
        .pretty .state label:after {
            border-radius: 3px;
            margin-top: 3px;
        }
        .pretty.p-default .state label:after {
            transform: scale(1);
        }

        #templatePreviewElement {
            .sv_body .sv_nav {
                padding: 0 20px;
                .sv_complete_btn {
                    background-color: #ce2e6c;
                    color: #fff;
                    border: 0;
                    min-height: 32px;
                    border-radius: 25px;
                    padding: 0 20px;
                    margin: 0;
                    font-family: $Font;
                    font-size: 0.8rem;
                    display: inline-flex;
                    align-items: center;
                    font-weight: normal;
                    letter-spacing: 0.2px;
                    &:focus {
                        outline: 0;
                    }
                }
                .sv_next_btn {
                    background-color: #5a4e63;
                    color: #fff;
                    border: 0;
                    min-height: 32px;
                    border-radius: 25px;
                    padding: 0 30px;
                    margin: 0;
                    font-family: $Font;
                    font-size: 0.8rem;
                    display: inline-flex;
                    align-items: center;
                    font-weight: normal;
                    letter-spacing: 0.2px;
                    &:focus {
                        outline: 0;
                    }
                }
                .sv_prev_btn {
                    background-color: transparent;
                    color: #5a4e63;
                    border: 0;
                    min-height: 32px;
                    border-radius: 25px;
                    padding: 0 20px;
                    margin: 0;
                    font-family: $Font;
                    font-size: 0.8rem;
                    display: inline-flex;
                    align-items: center;
                    border: 1px solid #5a4e63;
                    margin-right: 10px;
                    font-weight: normal;
                    letter-spacing: 0.2px;
                    &:hover {
                        background-color: #5a4e63;
                        color: #fff;
                    }
                    &:focus {
                        outline: 0;
                    }
                }
            }
            .sv_body.sv_body_empty {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                color: #b7b7b7;
                font-family: $Font;
                font-size: 1rem;
            }
            .sv_body.sv_completed_page h3 {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                color: #b7b7b7;
                font-family: $Font;
                font-size: 1.3rem;
            }
        }
    }

    // preview css end

    /*
 *  Survey Builder Scroll Css Start 
 */
    .svd_container .svd_content .svd_survey_designer .svd_editors {
        .svd_questions_editor {
            // height: 1120px;
            &::-webkit-scrollbar {
                width: 5px;
                height: 10px;
                background-color: #f5f5f5;
            }
        }
        .svd-designer-container--left-side {
            height: 1170px;
        }
    }

    #templateCreaterElement {
        .svd_properties .svd-vertical-container__cell-content {
            position: absolute;
        }
    }

    .svd-vertical-container__cell-content-holder {
        overflow-y: auto;
        overflow-x: hidden;
        .svd-vertical-container__cell-content--scrollable {
            &::-webkit-scrollbar {
                width: 5px;
                height: 10px;
                background-color: #f5f5f5;
            }
        }
    }
    .svd_editors .svd-vertical-container__cell-content-holder {
        overflow-y: hidden;
    }
    #templateCreaterElement .svd_container {
        height: 1270px !important;
        overflow: hidden;
    }
    .svd_container .svd_content .svd-designer-container--right-side .svd-icon-container--right-open {
        height: 40px;
        width: 40px;
        background: rgb(255, 255, 255);
        left: -60px;
        text-align: center;
        line-height: 40px;
        border-radius: 3px;
        background-image: url(../../assets/images/properties-right-panel1.svg);
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        use {
            display: none;
        }
    }
    .svd_container .svd_content .svd-designer-container--right-side .svd-icon-container--right-close {
        height: 40px;
        width: 40px;
        background: rgb(255, 255, 255);
        left: -40px;
        text-align: center;
        line-height: 40px;
        border-radius: 0 3px 3px 0;
        background-image: url(../../assets/images/properties-right-panel1.svg);
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        use {
            display: none;
        }
    }
    .svd-splitter {
        background-color: transparent;
    }
    .svd-designer-container--top {
        .svd-designer-h-container {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }
    }
    .svd_container .svd_content .svd_survey_designer .svd_editors .svd_toolbar {
        top: 0;
    }
    // Survey Builder scroll Css End
}

.Page-AddCategory {
    height: 100%;
    overflow-y: auto;
    padding: 40px 7%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .template-add-beadcrumb {
        border-bottom: 0;
    }
    .error-message {
        color: #ff0000;
        font-size: 14px;
        margin: 5px 0;
        display: block;
        padding-left: 30px;
        font-family: $SemiBoldFont;
    }

    form {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .survey-category-wrap {
        display: flex;
        flex-direction: column;
        max-width: 450px;
        width: 100%;
        .category_label {
            color: #504658;
            font-size: 1.8rem;
            font-family: $Font;
            text-align: center;
            margin: 0 0 40px;
        }
        .category-field-control {
            margin-bottom: 30px;
        }
        .create-category-input {
            width: 100%;
            background-color: #fff;
            border-radius: 25px;
            border: 0;
            padding: 14px 30px;
            box-shadow: -1px 7px 39px rgba(0, 0, 0, 0.11);
            font-size: 1rem;
            color: #504658;
            resize: none;
            &:focus {
                outline: 0;
            }
            @include placeholder {
                color: #b7b7b7;
            }
            &::-webkit-scrollbar {
                width: 4px;
                background-color: #f5f5f5;
            }
        }
        .category_add_btn_wrap {
            display: flex;
            justify-content: center;
        }
        .category_add_btn {
            background: #504658;
            color: #fff;
            border: 0;
            border-radius: 25px;
            padding: 8px 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            &:focus {
                outline: 0;
            }
        }
    }

    .category-field-control .ezi-select-plugin {
        background-color: #fff;
        border-radius: 25px;
        border: 0;
        width: 100%;
        padding: 7px 0px;
        box-shadow: -1px 7px 39px rgba(0, 0, 0, 0.11);
        font-size: 1rem;
        color: #504658;
        resize: none;
        &.css-14jk2my-container {
            pointer-events: initial;
            &:hover {
                cursor: not-allowed;
                pointer-events: initial;
                .css-1ht9tev-control {
                    pointer-events: none;
                    cursor: not-allowed;
                }
                .css-1hwfws3 {
                    pointer-events: none;
                    cursor: not-allowed;
                }
            }
        }
        .css-c8odyh-control,
        .css-1l3nnzh-control {
            border-radius: 25px;
            padding: 0 20px;
        }
        .css-26l3qy-menu {
            border-radius: 12px;
            padding: 10px 0;
        }
        .css-1ht9tev-control {
            padding: 0 20px;
        }
    }
}
