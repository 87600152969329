@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";

// Thumb updown css Start 
.thumb-wrapper{
    display: flex;
    justify-content: flex-start;
    padding: 20px 0;
    .thumb-radio { 
        position: absolute;
        opacity: 0;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
    }
    .thumb-inner{
        display: inline-flex;
        overflow: hidden;
        position: relative;
        &.thumb-up-wrap{
            margin-right: 20px;
        }
        input{
            &:checked + .thumb-up {
                background-image: url(../../assets/images/widget/thumbs/thumb_up.svg);
            }
            &:checked + .thumb-down {
                background-image: url(../../assets/images/widget/thumbs/thumb_down.svg);
            }
        }
        &:hover{
            input + .thumb-icon {
                opacity: 1;
            }
        }
    }
    .thumb-icon{
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    display: inline-block;
    height:50px;
    width:50px;
    min-width:50px;
        &.thumb-up{            
            background-image: url(../../assets/images/widget/thumbs/thumb_up_blur.svg);
        }
        &.thumb-down{
            background-image: url(../../assets/images/widget/thumbs/thumb_down_blur.svg);  
        }
    }      
}
// Thumb updown css End 



// Smily css Start 

.smily-wrapper{

    .smily-inner-wrapper {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 20px 0;
    }    
    .smily-inner {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 20px;
    }
    .smily-radio { 
        position: absolute;
        opacity: 0;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
    }
    .smily-inner{
        display: inline-flex;
        // overflow: hidden;
        position: relative;    
        input{
            &:checked + .smily-icon{
                -webkit-transform: scale(1.3);
                transform: scale(1.3);
                -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
                -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
                + .smily-name{
                    color:#CE2E6C;
                    text-shadow: 1px 1px 3px #F4D1DF;
                }
            }
           
        }
    }
    .smily-icon-wrapper {
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        &:hover{

            .smily-icon{
                -webkit-transform: scale(1.3);
                transform: scale(1.3);
                -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
                -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
                + .smily-name{
                    color:#CE2E6C;
                    text-shadow: 1px 1px 3px #F4D1DF;
                }
            }

            input + .smily-icon{
                opacity: 1;
            }
        }
    }
    .smily-icon{
        background-size: 120%;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        display: inline-block;
        height: 40px;
        width: 40px;
        min-width: 40px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        border-radius: 50%;
      
            &.very-poor{
                background-image: url(../../assets/images/widget/HI/emoji1.png);
            }
            &.poor{
                background-image: url(../../assets/images/widget/HI/emoji2.png);
            }
            &.average{
                background-image: url(../../assets/images/widget/HI/emoji3.png);
            }
            &.good{
                background-image: url(../../assets/images/widget/HI/emoji4.png);
            }
            &.excellent{
                background-image: url(../../assets/images/widget/HI/emoji5.png);
            }
        }      
    
        .smily-name {
            color: #504658;
            font-size: 0.8rem;
            font-family: $SemiBoldFont;
            margin: 15px 0 0;
        }



}

// Smily css End 



// widget personal form  start

.survey-personal-form-main{
    .survey-personal-form-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
         input {
            border-radius: 7px;
            border:1px solid #D0CDD2 !important;
            height: 42px;
            margin-bottom: 30px;
            padding: 0 13px;
            width: calc(50% - 15px);
            transition: background-color 0.3s ease;
            &:focus{
                outline:0;
                background-color: #F2F5F8 !important;
            }
            @include placeholder {
                color: #D9D9D9;
                font-size: 0.9rem;
            }
        }
    }
}

// widget personal form  end

// CSAT Widget Css Start

.csat-widget-wrapper{

    @mixin csatLabel {
        color: #707070;
        font-size: 0.85rem;
        font-family: $SemiBoldFont;        
    }
    .csat-label-left {
        @include csatLabel;  
        margin-right: 20px;    
    }
    .csat-label-right {
        @include csatLabel; 
        margin-left: 20px;     
    }

    .csat-widget-inner {
        display: flex;
        align-items: center;
        padding: 15px 0;
    }
    .csat-radio-wrapper {
        position: relative;
        margin-right: 20px;
        &:last-of-type{
            margin-right: 0;
        }
        input {
            &:checked + .csat-number{
                background-color: #CE2E6C;
                border-color: #CE2E6C;
                color:#fff;
            }
        }
        &:hover{
            .csat-number{
                background-color: #CE2E6C;
                border-color: #CE2E6C;
                color:#fff;
            }
        } 
    }
    .csat-radio {
        position: absolute;
        opacity: 0;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
    }
    .csat-number {
        display: inline-flex;
        height: 26px;
        width: 26px;
        min-width: 26px;
        border: 1px solid #b7b7b7;
        border-radius: 3px;
        align-items: center;
        justify-content: center;
        color: #707070;
        font-size: 0.8rem;
        font-family: $SemiBoldFont;  
        line-height: 26px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

}

// CSAT Widget Css End


// ========= NPS Widget Css Start =============

.nps-widget-wrapper{
    display: inline-flex;
    padding-bottom: 15px;
    @mixin npsLabel {
        color: #707070;
        font-size: 0.85rem;
        font-family: $SemiBoldFont;        
    }
    .nps-label-left {
        @include npsLabel;     
        position: absolute;
        left: 0;
        top:60px;
    }
    .nps-label-right {
        @include npsLabel; 
        position: absolute;
        right: 0;
        top:60px; 
    }

    .nps-widget-inner {
        display: flex;
        align-items: center;
        padding: 15px 0 15px;
        position: relative
    }
    .nps-radio-wrapper {
        position: relative;
        margin-right: 12px;        
      
        &:last-of-type{
            margin-right: 0;
        }

        $colors: #e43e3d, #ea484d, #ec654e, #ef874c, #f3a74c, #f8c43d,#e1c63b,#c1cc36,#9fcd35,#7fcd31,#5aaf2b;

        @for $i from 1 through length($colors) {
            &:nth-of-type(#{length($colors)}n+#{$i}) input:checked + .nps-number {
                background:darken(nth($colors, $i),15%);
            }
        }

        input {
            &:checked + .nps-number{
                &::after {
                    content: attr(value);
                    position: absolute;
                    top: -52px;
                    left: 50%;
                    width: 60px;
                    height: 60px;
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    background-image: url(../../assets/images/box-arrow.svg);
                    background-size: 64px;
                    background-position: 50%;
                    background-repeat: no-repeat;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 66px;
                    font-family: $SemiBoldFont;
                    font-size: 1rem;
                    color: #ce2e6c;
                    padding-bottom: 8px;
                }
             
            }
           
        }
        $colors: #e43e3d, #ea484d, #ec654e, #ef874c, #f3a74c, #f8c43d,#e1c63b,#c1cc36,#9fcd35,#7fcd31,#5aaf2b;

        @for $i from 1 through length($colors) {
            &:nth-of-type(#{length($colors)}n+#{$i}) .nps-number{
                background:  nth($colors, $i);
                &:hover,&:active{
                    background:darken(nth($colors, $i),15%);
                }
            }
        }
       
    }
    .nps-radio {
        position: absolute;
        opacity: 0;
        width: 100% !important;
        height: 100% !important;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
    }
    .nps-number {
        color: #FFF;
        font-size: 0.8rem;
        font-family: $SemiBoldFont;  
        line-height: 38px;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        width: 38px;
        min-width: 38px;
        border-radius: 50%;
        -moz-box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.4);
        -webkit-box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.4);
        box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.4);
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      
    }

}

// ========= NPS Widget Css End =============


@media (max-width:767px) {

    .survey-personal-form-main .survey-personal-form-wrap input{
        width: 100%;
    }

    .thumb-wrapper{
        padding: 10px 0 0;
    }
    // Smily css Start
    .smily-wrapper{
        .smily-inner-wrapper{
            margin: 0 0 0;
        }
        .smily-inner {
            padding: 0 4px;                
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
            // input{
            //     &:checked + .smily-icon{
            //         &.very-poor{
            //             background-image: url(../../assets/images/widget/smily/very-poor.svg);
            //             background-size: 34px;
            //         }
            //     }
            // }
        }      
        .smily-icon{
            background-size: 120%;
            height: 30px;
            width: 30px;
            min-width: 30px;
                // &.very-poor{     
                //     background-size: 34px;
                // }
            }
            .smily-name {
                font-size: 0.8rem;
                margin: 15px 0;
                font-family: $Font;
            }
    }
// Smily css End 

.thumb-wrapper .thumb-icon{
    height: 44px;
    width: 44px;
    min-width: 44px;
}


.csat-widget-wrapper{
    height:60px;
    .csat-widget-inner{
        position: relative;
        padding: 0;
    }

    .csat-label-left {
        white-space: nowrap;
        position: absolute;
        top: 36px;
        font-size: 0.75rem;
    }
    .csat-label-right {
        white-space: nowrap;
        position: absolute;
        left: 80px;
        top: 36px;
        font-size: 0.75rem;
    }

}



}