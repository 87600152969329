@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";
.Page-benchmark{
    padding-left:100px ;
    padding-right:100px ;
    .benchmark-subtitle{
        color: #B7B7B7;
        font-family: $Font;
        font-size: 0.9rem;
        margin: 10px 0;
        display: block;
    }
    .benchmark-dropdown{
        position: relative;
        margin: 10px 0;
    }
    .benchmark-dropdown-btn{
        border:0;
        color:#504658;
        font-family: $Font;
        font-size: 1.3rem;
        background-color: transparent;
        background-image: url(../../assets/images/themes-dropdown.svg);
        background-size: 16px;
        background-position: right;
        background-repeat: no-repeat;
        padding: 0 35px 0 0;
        
        &:focus{
            outline: 0;
        }
        &.selected{
            color : #CE2E6C;
            background-image: url(../../assets/images/show-entry_ic.svg);
        }

    }
    .react-bootstrap-table .table tbody tr td:hover:after{
        display: none;
    }
    .benchmark-status_c {
        text-transform: capitalize;
        display: inline-flex;
        align-items: center;
        color: #1C1C1C;
        font-family: $SemiBoldFont;
        font-size: 0.88rem;
        white-space: nowrap;
        margin-right: 20px;
        &:before {
            content: '';
            height: 8px;
            width: 8px;
            min-width: 8px;
            display: inline-flex;
            align-items: center;
            border-radius: 50%;
            margin-right: 6px;
        }
        &.active:before {            
            background: #B5EE5F;
        }
        &.offline:before {
            background: #FF1616;
        }
    }
    .benchmark-name_c{
        color: #1C1C1C;
        font-family: $SemiBoldFont;
        font-size: 0.88rem;
        white-space: nowrap;
        text-transform: capitalize;
        padding-left: 60px;
        &:hover{
            color:#CE2E6C;
            cursor: pointer;
        }
    }
    .react-bootstrap-table .table tbody tr td{
        white-space: normal;
        .table-bordered{
            padding-left: 160px;
        }
    }

    .benchmark-editDelete-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        .benchmark-delete {
            background: transparent;
            border: 0;
            padding: 0;
            color: #C5C5C5;
            transition: color 0.3s ease;
            font-family: $Font;
            font-size: 0.8rem;
            display: inline-flex;
            align-items: center;   
            &:focus{
                outline: 0;
            }     
            &:hover{
                color: #CE2E6C;
                &:before{
                    background-image: url(../../assets/images/table_delete_active_ic.svg); 
                }
            }
            &:before{
                content: '';
                display: inline-block;
                background-image: url(../../assets/images/table_delete_ic.svg);
                background-size: 9px;
                background-position: center;
                background-repeat: no-repeat;
                height: 24px;
                width: 24px;
                margin-right: 2px;
                transition: background-image 0.3 ease;
    
            }
        }
        .benchmark-view {
            background: transparent;
            border: 0;
            padding: 0;
            margin-right: 15px;
            color: #C5C5C5;
            transition: color 0.3s ease;
            font-family: $Font;
            font-size: 0.8rem;
            display: inline-flex;
            align-items: center; 
            &:focus{
                outline: 0;
            }
            &:hover{
                color: #CE2E6C;
                &:before{
                    background-image: url(../../assets/images/view-active.svg);
                }
            }
            &:before{
                content: '';
                display: inline-block;
                background-image: url(../../assets/images/view.svg);
                background-size: 14px;
                background-position: center;
                background-repeat: no-repeat;
                height: 24px;
                width: 24px;
                margin-right: 2px;
                transition: background-image 0.3 ease;
    
            }
        }
        .benchmark-upload{
            border: 0px;
            background: transparent;
            .bulk-benchamrk-icon {
                background: transparent;
                border: 0px;
                padding: 0;
                margin-left: 10px;
                color: #C5C5C5;
                transition: color 0.3s ease;
                font-family: $Font;
                font-size: 0.8rem;
                display: inline-flex;
                align-items: center; 
                &:focus{
                    outline: 0;
                }
                &:hover{
                    color: #CE2E6C;
                }
                &:before{
                    content: '';
                    display: inline-block;
                    background-size: 14px;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 24px;
                    width: 24px;
                    margin-right: 2px;
                    transition: background-image 0.3 ease;
        
                }
            }
        }

        .benchmark-edit {
            background: transparent;
            border: 0;
            padding: 0;
            margin-right: 15px;
            color: #C5C5C5;
            transition: color 0.3s ease;
            font-family: $Font;
            font-size: 0.8rem;
            display: inline-flex;
            align-items: center; 
            &:focus{
                outline: 0;
            }
            &:hover{
                color: #CE2E6C;
                &:before{
                    background-image: url(../../assets/images/table_edit_actives_ic.svg);
                }
            }
            &:before{
                content: '';
                display: inline-block;
                background-image: url(../../assets/images/table_edit_ic.svg);
                background-size: 14px;
                background-position: center;
                background-repeat: no-repeat;
                height: 24px;
                width: 24px;
                margin-right: 2px;
                transition: background-image 0.3 ease;
    
            }
        }
    }

    .react-bootstrap-table{
        thead tr th:nth-Child(2){
            padding-left: 225px;
        }
        thead tr th:nth-Child(1){
            padding-left: 100px;
        }
        .benchmark-datatable-row{
            .benchmark-des{
                font-size: 0.84rem;
            }
            .benchmark-editDelete-wrap{
                margin-right: 30px;
            }
            &:hover{
                .benchmark-editDelete-wrap{
                    visibility: visible;
                }
            }                     
        }
    }
    .add-benchmark-btn-wrap {
        display: flex;
        justify-content: right;
    }
    .ezi-sweet-tab{

        // .tab-content {
        //     padding: 0px;
        //     background-color: #fff;
        // }

    }
}

.add-benchmark-form{
    display: flex;
    flex-direction: column;
    .close-benchmark-btn {
        background: transparent;
        border: 0;
        padding: 4px 10px;
        margin: 0;
        margin-right: 10px;
        color: #1C1C1C;
        font-family: $SemiBoldFont;
        font-size: 0.8rem;    
        &:focus{
            outline: 0;
        }
    }
    .add-benchmark-btn {
        padding: 6px 30px;
        font-family: $SemiBoldFont;
        font-size: 0.8rem;  
    }
    .benchmark-modal-footer {
        display: inline-flex;
        align-items: center;
        margin-left: auto;
        margin-bottom: 12px;
    }
}

.benchmark-modal-title {
    color: #CE2E6C;
    font-family: $SemiBoldFont;
    font-size: 1.1rem !important;
}
.benchmark-field-wrapper {
    padding: 10px 0;
}
.benchmark-modal-wrapper{
    .benchmark-field-control {
        flex: 1;
        margin-bottom: 20px;
        border-radius: 3px;
        border: 1px solid #B7B7B7;
        margin: 0 10px 15px;
        min-height: 38px;
        padding: 0 10px;
        color: #707070;
        font-family: $Font;
        // font-size: 0.88rem; 
        resize: none; 
        &:focus{
            outline: 0;
        }
        @include placeholder {
            color: #D9D9D9;;
        }
    } 
    .benchmark-field {
        display: flex;
        flex:1;
        flex-direction: column;
    }
    
    // .benchmark-field-50 {
    //     display: flex;
    //     flex-wrap: wrap;
    //     align-items:flex-start;
    // }
    
    .benchmark-field label {
        flex: 1;
        padding: 0 10px;
        color: #B7B7B7;
        font-family: $Font;
        font-size: 0.9rem;
    }
    
    .benchmark-error_cu{
        color:#ff0000;
        font-family: $SemiBoldFont;
        font-size: 0.9rem;
        margin-bottom: 10px;
        padding-left: 10px;
    }

    .ezi-modal-header{
        border-bottom: 1px solid #E9E9E9;
        .ezi-modal-header-title {
            display: flex;
            width: 100%;
        }
        .ezi-modal-close{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;
            background: #F2F5F8;
            border-radius: 50%;
            margin-left: auto;
            background-image: url(../../assets/images/modal_close_ic.svg);
            background-size: 11px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }
    .modal-body {
        padding: 0 1rem;
    }  
}
// .benchmark-field-control .css-g1d714-ValueContainer
// {
//     padding-right: 200px;
// }

.benchmark-header
{
margin-top: 0px;
display: flex;
flex-direction: row;
justify-content: space-between;
}
.main-bench-wrap{
    margin: 20px;
}
.page-heading
{
    margin-right: 40px;
}
.head-bor{
    border-bottom: none !important;
}
.head-marg{
    margin-top: 40px;
}