.ezi-editor-wrapper {
    border: 1px solid #dbdbdb;
    background-color: #e9e9e9;
    border-radius: 8px;
    margin-top: 10px;
    width: 100%;
}

.ezi-editor-toolbar {
    border-radius: 8px 8px 0 0;
    border: 0;
}

.ezi-editor-inner {
    padding: 0 20px;
    min-height: 250px;
    overflow-x: hidden;
}
