@font-face {
    font-family: 'NunitoSansBold';
    font-weight: bold;
    font-style: normal;
    src: url('NunitoSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'NunitoSansSemiBold';
    font-weight: 600;
    font-style: normal;
    src: url('NunitoSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'NunitoSans';
    font-weight: normal;
    font-style: normal;
    src: url('NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'NunitoSansLight';
    font-weight: 300;
    font-style: normal;
    src:url('NunitoSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'NunitoSansItalic';
    font-weight: 400;
    font-style: italic;
    src: url('NunitoSans-Italic.ttf') format('truetype');
}

