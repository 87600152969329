

.sv_cw_pretty_checkbox_checkbox{
    color: #504658;
    margin-bottom: 20px;
    .pretty{
        width: 100%;
        margin: 0;
        padding: 0 10px;
    }
    .pretty input:checked ~ .state.p-success label:after, .pretty.p-toggle .state.p-success label:after {
        background-color: #CE2E6C !important;
        background-image: url(../images/chechbox-check.png);
        background-size: 12px;
        background-position: center;
        background-repeat: no-repeat
    }

    .pretty .state label:before, .pretty .state label:after{
        width: 18px;
        height: 17px;
    }
    

}
.sv_cw_pretty_checkbox_radiogroup{
    margin-bottom: 20px;
    .pretty{
        width: 100%;
        margin: 0;
        padding: 0 10px;
    }
    .pretty input:checked ~ .state.p-success label:after, .pretty.p-toggle .state.p-success label:after {
        background-color: #CE2E6C !important;
        transform: scale(0.7);
    }
    .pretty.p-round .state label:before{
        border-radius: 50%;
        width: 16px;
        min-width: 16px;
        height: 16px;
    }
    .pretty.p-round .state label:after{
        border-radius: 50% !important;
    }
}      
.pretty .state label{
    text-indent: 1.1em;
    font-size: 0.88rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #1c1c1c;
    font-family: $Font;
    &::first-letter{
        text-transform: uppercase;
    }
}
.pretty .state label:before {
    border-color: #b7b7b7;
}
.pretty .state label:before, .pretty .state label:after{
    border-radius: 3px;
    margin-top: 0;
}
.pretty.p-default .state label:after {
    transform: scale(1);
}



table.sv_q_matrix {
    border: 1px solid #e9e9e9;
    border-radius: 18px;
    border-collapse: separate;
    padding: 0 15px 15px;    
    position: relative;                                    
    thead {
        border-bottom: 1px solid #e9e9e9;
        th {
            padding: 12px 10px 30px;
            span{
                font-size: 0.82rem;
                font-family: $SemiBoldFont;
                color:#504658;
                letter-spacing: 0.2px;
            }
        }
        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            background: #e9e9e9;
            height: 1px;
            margin-top: -17px;
        }
    }
    tbody tr td {
        padding: 0px 10px;
        span{
            font-size: 0.82rem;
            font-family: $SemiBoldFont;
            color:#504658;
            letter-spacing: 0.2px;
        }
        label.sv_q_m_label {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
        }
    }
}


.sv_body .sv_nav{
    padding: 0 20px;
    margin-top: 50px;
    .sv_complete_btn{
        background-color: #CE2E6C;
        color: #fff;
        border: 0;
        min-height: 32px;
        border-radius: 25px;
        padding: 0 20px;
        margin: 0;
        font-family: $Font;
        font-size: 0.8rem;
        display: inline-flex;
        align-items: center;
        font-weight: normal;
        letter-spacing: 0.2px;
        &:focus{
            outline:0;
        }
    }
    .sv_prev_btn{
        background-color: #CE2E6C;
        color: #fff;
        border: 0;
        min-height: 32px;
        border-radius: 25px;
        padding: 0 30px;
        margin: 0;
        font-family: $Font;
        font-size: 0.8rem;
        display: inline-flex;
        align-items: center;
        font-weight: normal;
        letter-spacing: 0.2px;
        &:focus{
            outline:0;
        }
    }
    .sv_next_btn{
        background-color: #5A4E63;
        color: #fff;
        border: 0;
        min-height: 32px;
        border-radius: 25px;
        padding: 0 30px;
        margin: 0;
        font-family: $Font;
        font-size: 0.8rem;
        display: inline-flex;
        align-items: center;
        font-weight: normal;
        letter-spacing: 0.2px;
        &:focus{
            outline:0;
        }
    }
    .sv_preview_btn{
        background-color: #CE2E6C;
        color: #fff;
        border: 0;
        min-height: 32px;
        border-radius: 25px;
        padding: 0 30px;
        margin: 0;
        font-family: $Font;
        font-size: 0.8rem;
        display: inline-flex;
        align-items: center;
        font-weight: normal;
        letter-spacing: 0.2px;
        &:focus{
            outline:0;
        }
    }
    .sv_prev_btn{
        background-color: transparent;
        color: #5A4E63;
        border: 0;
        min-height: 32px;
        border-radius: 25px;
        padding: 0 20px;
        margin: 0;
        font-family: $Font;
        font-size: 0.8rem;
        display: inline-flex;
        align-items: center;
        border:1px solid #5A4E63;
        margin-right: 10px;
        font-weight: normal;
        letter-spacing: 0.2px;
        &:hover{
            background-color: #5A4E63;
            color:#fff;
        }
        &:focus{
            outline:0;
        }
    }
}
.sv_body .sv_progress{
    display: block;
    max-width: 300px;
    margin: 0 auto;
    background-color: #F9E4EC;
    height: 10px;
    z-index: 0;
    position: relative;
    border-radius: 30px;
    margin-top: 4%;
    .sv_progress_bar {
        height: 100%;
        position: relative;
        border-radius: 25px;
        background: #CE2E6C;
        + span{
            margin: 15px 0;
            color: #8D8D8D;
            font-family: $SemiBoldFont;
            font-size: 0.85rem;
            display: block;
            text-align: center;
        }
    }
}
.sv_body.sv_body_empty {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color:#B7B7B7;
    font-family: $Font;
    font-size: 1rem;
}
.sv_body.sv_completed_page h3{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color:#B7B7B7;
    font-family: $Font;
    font-size: 1.3rem;
}

.sv_main.sv_default_css{
    .sv_container .sv_p_root {

        .sv_page_title{
            color: #CE2E6C;
            font-size: 1.2rem;
            // text-transform: capitalize;
            + div[data-bind *= description]  {
                color: #b7b7b7;
                font-size: 0.8rem;
            }
        }

        
        .sv_row{
            padding: 20px 20px 20px;
            background: transparent;
            // margin-bottom: 25px;
            position: relative;
            .sv_q.sv_qstn{
                border:0;
                overflow: initial;
                .sv_q_text_root{
                    border-radius: 5px;
                    height: 40px;
                    width: 100%;
                    border: 1px solid #e9e9e9;
                    max-width: 400px;
                    padding: 0 15px;
                    font-size: 0.95rem;
                    color:#5A4E63;
                    &:focus{
                        background-color: #F2F5F8;
                        outline:0;
                    }
                }
                textarea{
                    border-radius: 15px;
                    max-width: 400px;
                    width: 100%;
                    border: 1px solid #e9e9e9;
                    resize: none;
                    background-color: #FDFDFD;
                    padding: 15px;
                    font-size: 0.95rem;
                    color:#5A4E63;
                    &:focus{
                        outline:0;
                    }
                }
                .select2-container--default .select2-selection--multiple{
                    border: 1px solid #e9e9e9;
                    min-height: 46px;
                    max-width: 400px;
                }
                fieldset.sv_qcbc{
                    .svda-add-new-item{
                        color:#ccc;
                        transition: all 0.3s ease;
                        svg{
                            fill: #ccc;
                        }
                        &:hover{
                            color:#CE2E6C;
                            svg{
                                fill: #CE2E6C;
                            }
                        }
                    }
                    .sv_q_select_column{
                        min-width: initial;
                    }
                    .sv_q_checkbox{
                        padding: 10px 20px;
                        border-radius: 5px;
                        display: flex;
                        .sv_q_checkbox_label{
                            margin:0;
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            .sv_q_checkbox_control_item{
                                margin-right: 7px;
                            }
                        }
                    }
                    .sv_q_radiogroup{
                        padding: 10px 20px;
                        border-radius: 5px;
                        display: flex;
                        .sv_q_radiogroup_label{
                            margin:0;
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            .circle{
                                margin-right: 5px;
                            }
                            .sv_q_radiogroup_control_item{
                                margin-right: 7px;
                            }
                        }
                    }
                    .br-theme-css-stars .br-widget a{
                        width: 30px;
                        height: 30px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        &:after {
                            color: #d2d2d2;
                            font-size: 35px;
                        }
                    }
                }
                .sv_select_wrapper{
                    select{
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        width: 100%;
                        border: 1px solid #E9E9E9;
                        padding: 10px 20px;
                        border-radius: 7px;
                        max-width: 400px;
                        background-image: url(../images/dropdown-arrow_c2.svg);
                        background-size: 14px;
                        background-position: center right 14px;
                        background-repeat: no-repeat;
                        font-size: 1rem;
                        color:#5A4E63;
                        &:focus{
                            outline: 0;
                        }
                    }
                    .svda-select-items-title{
                        top: -30px;
                        right: 40px;
                        .svd-main-color{
                            color:#CE2E6C;
                        }
                    }
                    .svda-select-items-content{
                        .svda-select-items-collection{
                            .item_draggable{
                                padding:  4px 10px;
                            }
                        }
                        .item_editable{
                            padding:  4px 10px;
                            font-family: $Font;
                            font-size: 0.8rem;
                            color:#656565;
                        }
                    }
                }
                .sv_q_matrix_dropdown{
                    margin-top: 20px;
                    thead{
                        background-color: #CE2E6C;
                        color: #fff;
                    
                        tr{                                                
                            th {
                                text-align: center;
                                font-size: 0.85rem;
                                font-family: $SemiBoldFont;
                                padding: 10px 0;
                                border: 1px solid #e9e9e9; 
                            }
                        }
                    }
                    tbody {
                        tr,td{
                            padding: 10px; 
                            border: 1px solid #e9e9e9; 
                            text-align: left; 
                        }
                        td{
                            &:first-child{
                                font-size: 0.85rem;
                                color:#504658;
                                font-family: $SemiBoldFont;
                            }
                        }
                        td .sv_qcbc{
                            .sv_q_radiogroup {
                                border: 0;
                                padding: 0px 10px;
                                margin-bottom: 0px;
                                border-radius: 5px;
                                display: inline-block;
                                .sv_q_radiogroup_label{
                                    font-weight: normal;
                                    font-family: $Font;
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }
                }
            }
            .sv_q_title{
                color:#1C1C1C;
                font-size: 1rem;
                // text-transform: capitalize;
                margin: 20px 0 40px;
                font-family: $SemiBoldFont;
            }
            .sv_q_num {
                color: #CE2E6C;
                font-size: 0.9rem;
            }

            .sv_q_imgsel.sv_q_imagepicker_inline.checked .sv_q_imgsel_label > div{
                border-color: #CE2E6C;
                &:after{
                    content: '';
                    background-image: url(../images/widget/image-check.svg);
                    background-size: 18px;
                    background-position: center;
                    background-repeat: no-repeat;
                    display: inline-flex;
                    height: 24px;
                    width: 24px;
                    position: absolute;
                    top: -10px;
                    right: -12px;
                }
            }

            .sv_imgsel .sv_q_imgsel .sv_q_imgsel_label > div {
                background: transparent;
                border-radius: 7px;
                overflow: hidden;
                padding: 8px;
                border-color: #e9e9e9;
                position: relative;
                overflow: visible;
                .sv_q_imgsel_image {
                    border-radius: 7px !important;
                    object-fit: cover !important;
                    width: 100px;
                    height: 80px;
                }
            }
            .sv_imgsel .sv_q_imgsel:checked .sv_q_imgsel_label > div{
                background-color: transparent;
            }
            
            .br-theme-css-stars .br-widget a{
                height: 40px;
                width: 40px;
                margin-right: 15px;
                &:last-child{
                    margin-right: 0 ;
                }
                &:after {
                    content: '';
                    background-image: url(../images/widget/blank-star.svg);
                    background-size: 30px;
                    background-position: center;
                    background-repeat: no-repeat;
                    display: inline-flex;
                    height: 40px;
                    width: 40px;
                }
                &.br-active:after{
                    background-image: url(../images/widget/pink-star.svg);
                }
                &.br-selected:after{
                    background-image: url(../images/widget/pink-star.svg);
                }
            }

            .sv_q_rating{
                margin: 0 -10px;          
                .sv_q_rating_item{
                    padding: 0 10px;
                    display: inline-block;
                    margin-bottom: 15px;   
                    cursor:pointer;                 
                    &.active .sv_q_rating_item_text{
                        background: #CE2E6C;
                        color:#fff;
                        border-color: #CE2E6C ;
                    }
                }
                .sv_q_rating_item_text{
                        border: 1px solid #b7b7b7;
                        padding: 7px 10px;
                        border-radius: 3px;
                        color: #707070;
                        font-size: 0.85rem;
                        font-family: $SemiBoldFont;
                        display: inline-flex;
                        align-items: center;
                }
                .svda-add-new-rating-item{
                    margin-top: 4px;
                    background-color: #e1e1e1;
                    display: inline-flex;
                    padding: 10px 10px;
                    border-radius: 3px;
                    cursor: pointer;
                }
            } 

            .sv_q_file{
                .sv_q_file_choose_button{
                    position: relative;
                    min-width: 164px;
                    max-width: 164px;
                    border: 0;
                    background: transparent;
                    height: 38px;
                    text-indent: 8px;
                    cursor: pointer;
                    &:before {
                        content: '';
                        background-image: url(../images/widget/upload-file.svg);
                        background-size: 163px;
                        background-position: top left;
                        background-repeat: no-repeat;
                        height: 100%;
                        min-width: 164px;
                        max-width: 164px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 9999;
                    }
                    &:focus{
                        outline: 0;
                    }
                }
                .sv_q_file_remove_button{
                    background-color: #504658;
                    color: #fff;
                    border: 0;
                    min-height: 30px;
                    border-radius: 5px;
                    padding: 0 16px;
                    margin: 0;
                    font-family: "NunitoSans";
                    font-size: 0.8rem;
                    display: inline-flex;
                    align-items: center;
                
                }

                .sv_q_file_preview {
                    background: transparent;
                    border-radius: 7px;
                    padding: 8px;
                    border-color: #e9e9e9;
                    position: relative;
                    overflow: visible;
                    img {
                        border-radius: 7px;
                    }
                    a {
                        color: #504658;
                        font-size: 0.73rem;
                        margin: 0 0 10px;
                        display: inline-block;
                        max-width: 220px;
                    }
                    .sv_q_file_remove{
                        color: #C5C5C5;
                        font-size: 0.85rem;
                        display: flex;
                        margin: 10px 0 2px;
                        align-items: center;
                        justify-content: center;
                        &:before {
                            content: '';
                            display: inline-block;
                            background-image: url(../images/table_delete_ic.svg);
                            background-size: 9px;
                            background-position: center;
                            background-repeat: no-repeat;
                            height: 24px;
                            width: 24px;
                            margin-right: 2px;
                            transition: background-image 0.3 ease;
                        }
                        &:hover{
                            color:#CE2E6C;
                            &:before {
                                background-image: url(../images/table_delete_active_ic.svg);
                            }                                    
                        }
                    }
                }

            }

            .slider.slider-horizontal{
                width: calc(100% - 60px);
                max-width: 600px;
                margin: 30px 30px 0;
                  .slider-track {
                    height: 10px;
                    width: 100%;
                    margin-top: -5px;
                    top: 50%;
                    left: 0;
                    border: 1px solid #707070;
                    background: transparent;
                    border-radius: 25px;
                }
                .slider-selection{
                    background: #CE2E6C;
                }
                .slider-handle{
                    background-image: url(../images/widget/slider-scale-circle.svg);
                    background-size: 30px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: transparent;
                    box-shadow: none;
                    height: 30px;
                    width: 30px;
                    margin-top: -3px;
                    &:before {
                        content: '';
                        height: 10px;
                        width: 10px;
                        background: #fff;
                        position: absolute;
                        border: 1px solid #CE2E6C;
                        transform: rotate(45deg) translateX(-50%);
                        bottom: 32px;
                        border-top-color: transparent;
                        border-left-color: transparent;
                        z-index: 1;
                        left: 50%;
                    }
                    &:after {
                        content: attr(aria-valuenow);
                        height: 30px;
                        width: 42px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        bottom: 40px;
                        border: 1px solid #CE2E6C;
                        border-radius: 7px;
                        left: 50%;
                        transform: translateX(-50%);
                        color: #1C1C1C;
                        font-size: 0.78rem;
                        font-family: $SemiBoldFont;
                    }
                }
            }

            .widget-datepicker{
                width: 100%;
                border: 1px solid #E9E9E9;
                padding: 10px 20px;
                border-radius: 7px;
                max-width: 400px;
                background-image: url(../images/widget/calendar.svg);
                background-size: 18px;
                background-position: center right 14px;
                background-repeat: no-repeat;
                &:focus {
                    outline: 0;
                    box-shadow: none;
                }
            }
            
            .sjs-sortablejs-root{
                max-width: 500px;
            }
            .sjs-sortablejs-result{
                border: 1px solid #E9E9E9 !important;
                border-radius: 5px;
                padding: 20px;
                color: #c5c5c5;
                font-size: 0.8rem;
                font-weight: normal;
                min-height: 100px !important;
                margin-bottom: 30px;
                > div {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    &:before {
                        content: '';
                        height: 44px;
                        background: transparent;
                        border: 1px solid #F2F5F8;
                        border-radius: 6px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        width: 60px;
                        min-width: 60px;
                        background-image: url(../images/field_tab_icon-active.svg);
                        background-size: 20px;
                        background-position: center;
                        background-repeat: no-repeat;
                        margin-right: 10px;
                    }
                }
                .sjs-sortablejs-item{
                    background: transparent !important;
                    color: #CE2E6C !important;
                    border-radius: 6px;
                    width: 100%;
                    border: 1px solid #CE2E6C;
                    padding: 10px 20px !important;
                    font-size: 1rem;
                }
            }
            .sjs-sortablejs-source{
                border: 1px solid #E9E9E9 !important;
                border-radius: 5px;
                padding: 20px;
                min-height: 100px !important;
                > div {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    &:before {
                        content: '';
                        height: 44px;
                        background: transparent;
                        border: 1px solid #F2F5F8;
                        border-radius: 6px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        width: 60px;
                        min-width: 60px;
                        background-image: url(../images/field_tab_icon.svg);
                        background-size: 20px;
                        background-position: center;
                        background-repeat: no-repeat;
                        margin-right: 10px;
                    }
                }
                .sjs-sortablejs-item{
                    background: transparent !important;
                    color: #8D8D8D !important;
                    border-radius: 6px;
                    width: 100%;
                    border: 1px solid #F2F5F8;
                    padding: 10px 20px !important;
                }
            }
            .sv-boolean--indeterminate{
                .sv-boolean__slider{
                    margin-left: unset;
                }
            }

            .sv-boolean__switch{
                border: 1px solid #DBDBDB;
                background-color: transparent;
                height: 36px;
                border-radius: 25px;
                width: 70px;
                padding: 4px 9px 4px 5px;
                .sv-boolean__slider{
                    background-color: #DBDBDB;
                    height: 26px;
                    width: 26px;
                }
            }
            .sv-boolean--checked.checked{
                .sv-boolean__switch{
                    background-color: #CE2E6C;
                    border-color:#CE2E6C; 
                }
                .sv-boolean__slider{
                    background-color: #fff;
                }
            }

            table.sv_q_matrix {
                border: 1px solid #e9e9e9;
                border-radius: 18px;
                border-collapse: separate;
                padding: 0 15px 15px;    
                position: relative;                                    
                thead {
                    border-bottom: 1px solid #e9e9e9;
                    th {
                        padding: 12px 10px 30px;
                        span{
                            font-size: 0.82rem;
                            font-family: $SemiBoldFont;
                            color:#504658;
                            letter-spacing: 0.2px;
                        }
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 100%;
                        background: #e9e9e9;
                        height: 1px;
                        margin-top: -17px;
                    }
                }
                tbody tr td {
                    padding: 0px 10px;
                    span{
                        font-size: 0.82rem;
                        font-family: $SemiBoldFont;
                        color:#504658;
                        letter-spacing: 0.2px;
                    }
                    label.sv_q_m_label {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 0;
                    }
                }
            }
            + .sv_p_footer{
                padding: 0 20px;
                .sv_edit_btn{
                    background-color: #5A4E63;
                    color: #fff;
                    border: 0;
                    min-height: 28px;
                    border-radius: 25px;
                    padding: 0 24px;
                    margin: 0;
                    font-family: $Font;
                    font-size: 0.8rem;
                    display: inline-flex;
                    align-items: center;
                    font-weight: normal;
                    letter-spacing: 0.2px;
                    &:focus{
                        outline:0;
                    }
                }
            }

        }
    }
}
