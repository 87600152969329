@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";
.Page-Companies{
    padding: 40px 7%;
    background-color: #F2F5F8;
    .allocate-template-btn{
        background-color: #CE2E6C;
        color: #fff !important;
        border: 0;
        min-height: 30px;
        border-radius: 25px;
        padding: 0 20px;
        margin: 0;
        font-family: $Font;
        font-size: 0.8rem;
        display: inline-flex;
        align-items: center;
        &:before{
            content: '';
            background-image: url(../../assets/images/adds.svg);
            background-size: 11px;
            background-position: left top 2px;
            background-repeat: no-repeat;
            height: 18px;
            width: 15px;
            margin-right: 2px;
            
        }
    }
    .react-bootstrap-table {
        min-height: 300px;
        .table tbody tr td:hover:after{
            display: none;
        }
    }
    .react-bootstrap-table .table tbody tr td:first-child {
        text-transform: capitalize;
    }
    .datatable-action-text {
        display: block;
        text-align: center;
    }
    .datatable-action-row{       
        td:first-child{
           text-transform: capitalize;
        }       
        .datatable-editDelete-wrap{
            visibility: hidden;
            margin-right: 30px;
        }
        &:hover{
            .datatable-editDelete-wrap{
                visibility: visible;
            }
        }  
        td:last-child{
            text-align: center;
        }                   
    }


    .datatable-editDelete-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        .datatable-delete_c {
            background: transparent;
            border: 0;
            padding: 0;
            color: #C5C5C5;
            transition: color 0.3s ease;
            font-family: $Font;
            font-size: 0.8rem;
            display: inline-flex;
            align-items: center;   
            &:focus{
                outline: 0;
            }     
            &:hover{
                color: #CE2E6C;
                &:before{
                    background-image: url(../../assets/images/table_delete_active_ic.svg); 
                }
            }
            &:before{
                content: '';
                display: inline-block;
                background-image: url(../../assets/images/table_delete_ic.svg);
                background-size: 9px;
                background-position: center;
                background-repeat: no-repeat;
                height: 24px;
                width: 24px;
                margin-right: 2px;
                transition: background-image 0.3 ease;
    
            }
        }
        .datatable-edit_c {
            background: transparent;
            border: 0;
            padding: 0;
            margin-right: 15px;
            color: #C5C5C5;
            transition: color 0.3s ease;
            font-family: $Font;
            font-size: 0.8rem;
            display: inline-flex;
            align-items: center; 
            &:focus{
                outline: 0;
            }
            &:hover{
                color: #CE2E6C;
                &:before{
                    background-image: url(../../assets/images/table_edit_actives_ic.svg);
                }
            }
            &:before{
                content: '';
                display: inline-block;
                background-image: url(../../assets/images/table_edit_ic.svg);
                background-size: 14px;
                background-position: center;
                background-repeat: no-repeat;
                height: 24px;
                width: 24px;
                margin-right: 2px;
                transition: background-image 0.3 ease;
    
            }
        }
    }

    .allocate-template{
        &:before{
            content: '';
            display: inline-flex;
            align-items: center;
            height: 18px;
            width: 18px;
            background-image: url(../../assets/images/adds.svg);
            background-size: 12px;
            background-position: top 2px left;
            background-repeat: no-repeat;
            margin-left: 2px;
        }
    }



   

    .template-action-wrap{
        text-align: center;
        @mixin icon-default{
            background: transparent;
            border: 0;
            padding: 0;
            color: #C5C5C5;
            transition: color 0.3s ease;
            font-family: $Font;
            font-size: 0.85rem;
            display: inline-flex;
            align-items: center; 
            &:focus{
                outline: 0;
            }
            &:before{
                content: '';
                display: inline-block;
                background-size: 17px;
                background-position: center;
                background-repeat: no-repeat;
                height: 24px;
                width: 24px;
                margin-right: 2px;
                transition: background-image 0.3 ease;
    
            }
            &:hover{
                color: #CE2E6C;
            }
          }
          .delete_template {                
            @include icon-default ;  
            &:hover{
                &:before{
                    background-image: url(../../assets/images/table_delete_active_ic.svg); 
                }
            }
            &:before{
                background-image: url(../../assets/images/table_delete_ic.svg);     
                background-size: 11px;   
            }
        }
        .company_login {                
            @include icon-default ;  
            &:before{
                background-size: 18px; 
            }
            &:hover{
                &:before{
                    background-image: url(../../assets/images/login-pink.svg); 
                }
            }
            &:before{
                background-image: url(../../assets/images/login-grey.svg); 
                
            }
        }
        .edit_template {
            @include icon-default ;       
            &:hover{
                &:before{
                    background-image: url(../../assets/images/table_edit_actives_ic.svg); 
                }
            }
            &:before{
                background-image: url(../../assets/images/table_edit_ic.svg);        
            }
        }
        .allocate_template{
            @include icon-default ;  
            &:before{
                background-size: 23px; 
            }
            &:hover{
                &:before{
                    background-image: url(../../assets/images/add-material-pink.svg); 
                }
            }
            &:before{
                background-image: url(../../assets/images/add-material-grey.svg);        
            }
        }
        .assign_anonymity{
            @include icon-default ;  
            &:before{
                background-size: 19px; 
            }
            &:hover{
                &:before{
                    background-image: url(../../assets/images/anonymous_pink.svg); 
                }
            }
            &:before{
                background-image: url(../../assets/images/anonymous_grey.svg);        
            }
        }
        .allocate_benchmark{
            @include icon-default ;  
            &:before{
                background-size: 19px; 
            }
            &:hover{
                &:before{
                    background-image: url(../../assets/images/benchmark-active.svg); 
                }
            }
            &:before{
                 background-image: url(../../assets/images/benchmark_inactive.svg);
            }
        }

    }

    .users-table-content{
        .react-bootstrap-table .table tbody tr td{
            overflow: unset;
        }
        .template-action-wrap{
            .action-drop{
                .dropdown-toggle.btn-primary{
                    &:focus{
                        box-shadow: unset !important;
                    }
                }
                .dropdown-menu{
                    .dropdown-item.active, .dropdown-item:active{
                        background-color: #bebebf;
                    }
                }
                a.dropdown-item {
                    display: flex;
                    align-items: center;
                    .action-menu {
                        display: flex;
                        align-items: center;
                      }
                  }
            }
        }
    }
}