@import "../../assets/scss/variable.scss";

.sidebar-wrapper {
  padding: 2px 1.5rem 1.5rem;
  opacity: .7;
}
    .sidebar {
        display: flex;
        flex-direction: column;
        width: 290px;
        position: fixed;
        top: 0;
        left: -290px;
        height: 100vh;
        z-index: 999999;
        // background: #504658;
        background-image: linear-gradient(to right,#2b2530,#3a3140);
        color: #fff;
        transition: all 0.3s;
        overflow-y: auto;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    }
.sidebar::-webkit-scrollbar-thumb {
    background-color: #483F4F;
    border-radius: 10px;
}
.sidebar::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
}
.sidebar::-webkit-scrollbar-thumb {
    background-color: #483F4F;
    border-radius: 10px;
}
.sidebar::-webkit-scrollbar-track {
    background-color: transparent;
}
.sidebar.active {
    left: 0;
}



.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    left: 0;
    top:0;
}
.overlay.active {
    display: block;
    opacity: 1;
}

.sidebar-navigation {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid #404040;
}

.humburger-button{
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    &:focus{
        outline: 0;
        
    }
}

.humburger-logo {
    height: auto;
    width: 27px;
}
.navigation-text {
    margin-left: 34px;
    // font-size: 20px;
}
.sidebar-list {
    margin: 0;
    padding: 10px 0 0 0;
}

.sidebar-list .sidebar-item {
    list-style: none;
    padding: 0 0 0 0;
    margin: 15px 0 15px 0px;
    border-radius: 3px;
    height: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    &.submenu{
        a{
            &:after{
                content:'';
                height: 35px;
                width: 35px;
                display: inline-flex;
                align-items: center;
                background-size:14px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url(../../assets/images/sidebar/submenu_icon.svg);
                position: absolute;
                right:10px;
                top:4px;
                transition: transform 0.3s ease;
            }
        }
        &.active a:after{
            transform: rotate(180deg);
        }
    } 
    .link-active{
        opacity: 1;
    }
}
li.sub-sidebar-item {
    list-style: none;
    padding: 0px 0 0 0;
    margin: 15px 0 10px 22px;
    border-radius: 25px 0 0 25px;
    height: 25px;
    min-height: 25px;
    display: flex;
    align-items: center;
    position: relative;
    
    .side-icon {
        &:before {
            content: "";
            height: 35px;
            width: 35px;
            display: inline-flex;
            align-items: center;
            background-size: 15px;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 10px;
        }
    }
    .link-active{
        opacity: 1;
    }
  }
  .sub-sidebar-item a{
    text-decoration: none;
    color: #fff;
    font-family: "NunitoSans";
    font-size: 15px;
    display: flex;
    align-items: center;
    opacity: 0.5;
    padding-left: 5px;
    width: 100%;
    text-transform: capitalize;
    &:hover{
        opacity: 1;
        color: #fff;
        text-decoration: none;
    }
  }
  .sidebar-item:hover {
    // background: #5a4e63;
    color: #fff;
    background: rgba(255,255,255,0.1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.sidebar-item a {
    text-decoration: none;
    color: #fff;
    font-family: "NunitoSans";
    font-size: 15px;
    display: flex;
    align-items: center;
    opacity: 0.5;
    // padding-left: 20px;
    width: 100%;
    text-transform: capitalize;
}
.sidebar-list .sidebar-item:hover a{
    opacity: 1;
    color: #fff;
    text-decoration: none;
}
.sidebar-list .side-icon{
    &:before {
        content: '';
        height: 35px;
        width: 35px;
        display: inline-flex;
        align-items: center;
        background-size:18px;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 10px;
    }
    &.dashboard:before{background-image: url(../../assets/images/sidebar/dashboard.svg);}
    &.surveys:before{background-image: url(../../assets/images/sidebar/survey_w.svg);}
    &.reports:before{background-image: url(../../assets/images/sidebar/reports_w.svg);}
    &.customer-exp:before{background-image: url(../../assets/images/sidebar/users.svg);}
    &.emplyee-exp:before{background-image: url(../../assets/images/sidebar/users.svg);}
    &.collection:before{background-image: url(../../assets/images/sidebar/collection_w.svg);}
    &.themes:before{background-image: url(../../assets/images/sidebar/themes_w.svg);} 
    &.questions:before{background-image: url(../../assets/images/sidebar/questions_w.svg);}
    &.company-profile:before{background-image: url(../../assets/images/sidebar/company-profile_w.svg);}
    &.user-roles:before{background-image: url(../../assets/images/sidebar/user-roles_w.svg);}
    &.identifier:before{background-image: url(../../assets/images/sidebar/identifier_w.svg);}
    
    &.people:before{background-image: url(../../assets/images/sidebar/survey_w.svg);}    
    &.templates:before{background-image: url(../../assets/images/sidebar/template_w.svg);}
    &.user:before{background-image: url(../../assets/images/sidebar/user-roles_w.svg);}
    &.responses:before{background-image: url(../../assets/images/sidebar/response_w.svg);} 
    &.directory:before{background-image: url(../../assets/images/sidebar/survey_w.svg);}
    &.settings:before{background-image: url(../../assets/images/sidebar/survey_w.svg);}
    &.support:before{background-image: url(../../assets/images/sidebar/support_w.svg);}
    &.communication-templates:before{background-image: url(../../assets/images/sidebar/email-sms-templates.svg);}
    &.question-bank-template:before{background-image: url(../../assets/images/sidebar/question_bank_icon.svg);}

    
}

.copyright-text{
    margin-top: auto;
    color: #7A6C85;
    font-size: 14px;
    padding: 20px 30px;
    line-height: 24px;
} 

.sidebar-submenu-item{
    height:0px;
    -webkit-transition: height .3s ease;
    transition: height .3s ease;
    overflow: hidden;
    background-color: #5A4E63;
    margin: -10px 0;
    padding-left: 30px;
    &.collection-active{
        height:200px;
        -webkit-transition: height .3s ease;
        transition: height .3s ease;
    }
    &.setting-active{
        height:140px;
        -webkit-transition: height .3s ease;
        transition: height .3s ease;
    }
    &.report-active{
        height:140px;
        -webkit-transition: height .3s ease;
        transition: height .3s ease;
    }
}

