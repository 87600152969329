@import "../../assets/scss/variable";
.Page-BulkUpload {
    padding: 40px 2% 40px 7%;
    .bulkUpload-header {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dde0e2;
        padding-bottom: 15px;
        flex-wrap: wrap;
    }
    .bulk-heading {
        display: flex;
        flex-direction: column;
        h1 {
            color: #504658;
            font-size: 1.3rem;
            font-weight: normal;
            font-family: $Font;
        }
        p {
            color: #c5c5c5;
            font-family: $Font;
            font-size: 0.8rem;
        }
    }
    .download-template {
        margin-left: auto;
        padding: 4px 15px;
        border: 0;
        background: $ThemeColor;
        color: #fff;
        border-radius: 25px;
        font-size: 0.7rem;
        font-family: $Font;
        transition: background 0.3 ease;
        &:focus {
            outline: 0;
        }
        &:hover {
            background: darken(#504658, 8%);
        }
    }
    .progressbar {
        background: #fff;
        padding: 25px 0 50px 0;
		display: block;
    }
    .upload-benchmark-list {
		padding: 20px;
		box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
		// border-radius: 5px;
        background: #fdfdfd;
		.btn-heading-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.back {
				order: 2;
				margin-bottom: 10px;
				border: 0;
				background: transparent;
				color: #b7b7b7;
			}
			> h3 {
				margin-bottom: 10px;
			}
		}
		.description-list {
			padding: 0px;
			.progress {
				height: 2rem;
			}

			.progress-bar {
				padding: 6px;
			}
		}
		h3 {
			color: #1c1c1c;
			font-size: 1rem;
			font-family: "NunitoSansSemiBold", Helvetica, Arial, sans-serif;
			margin: 0;
		}
		.download-error-particiant {
			display: flex;
			align-items: center;
			width: 171px;
			margin-left: auto;
			cursor: pointer;
			&:hover {
				text-decoration: none;
			}
		}
		.success-message {
			color: green;
			font-size: $OtherText;
		}
		.error-message {
			color: #dd0808;
			font-size: $OtherText;
			overflow-wrap: anywhere;
		}
		&.completed {
			.uploded-done {
				color: #808080;
			}
			.completedback {
				margin-bottom: 10px;
				border: 0;
				background: transparent;
				color: #b7b7b7;
			}
			.success-div {
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
			}
		}
	}
}
