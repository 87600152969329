@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";
.Page-AddCompany {
    padding: 40px 7%;
    background-color: #F2F5F8;

    .column-header{
        .column-header-btn{    
            .clear_all{
                background : transparent;
                color: #BFBFBF;
            }
               
        }
    }
    .status-div-web{
        .domain-adress-wrap{
            // border: 0;
            &input{
                width: unset;
            }
            .check-addrs{
                margin-left: 10px;
                accent-color: $ThemeColor;

            }
        }
    }
    .domain-adress-wrap {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        border-radius: 6px;

    }
    
    .domain-adress-wrap input {
        width: unset;
        border: unset;
        padding: 10px;
    }
    
    .domain-adress-wrap span.domain {
        padding-right: 20px;
    }

    .tab-header-wrap{
        border-bottom: 1px solid #E9E9E9;
    }
    .tab-left-header .nav-item {
        margin-bottom: 10px;
        a{
            padding-bottom: 15px;
        }
    }
  
    .tab-left-header .nav-pills .nav-link.active:after, .tab-left-header .nav-pills .show > .nav-link:after{
        bottom: -11px;
    }
    .add-single-card {
        background-color: #fff;
        border-radius: 10px;
        padding: 40px 40px;
        margin-bottom: 30px;
        margin-top: 50px;
        box-shadow: 0 3px 2px rgba(0,0,0,.1);
    }
    .single-card-header{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }
    .card-user-info{

        .name{
            font-family: $Font;
            color:#8D8D8D;
            font-size: 1.4rem;
            margin: 6px 0 10px;
        }
        .role{
            color: #B7B7B7;
            font-family: $Font;
            font-size: 0.8rem;
            margin: 6px 0;
        }
    }
    .single-card-btn{
        margin-left: auto;
        display: inline-flex;
        align-items: center;
        .single-clear {
            padding: 5px 10px;
            border: 0;
            color: #BFBFBF;
            background-color: transparent;
            margin-right: 20px;
            font-family: $Font;
            font-size: 0.9rem;
            transition: color 0.3s ease;
            &:hover{
                color: #CE2E6C;
            }
            &:focus{
                outline:0;
            }
        }
        .single-save{
            padding: 6px 30px;
            border: 0;
            color: #fff;
            background-color: #CE2E6C;
            font-family: $Font;
            font-size: 0.9rem;
            border-radius: 25px;
            &:focus{
                outline:0;
            }
        }
    }
    
        .tab-content{
            padding: 40px 0;
        }
        .add-company-field-wrapper{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        
        .saveCompanyAditional{
            label{
                width: 100%;
            }
            .col1-scoreIndex{
                .react-date-picker__inputGroup{
                    input{
                        border: 0;
                    }
                }
            }
        } 
        label{
            display: flex;
            flex-direction: column;
            color: #C5C5C5;
            font-family: $Font;
            margin-bottom: 5px;
            padding:0;
            // border:1px solid #ccc;
            font-size: 0.85rem;
            width: 50%;
            border-radius: 6px;
            margin-bottom: 20px;
            width: calc(50% - 10px);
            border-radius: 6px;
            margin-bottom: 30px;
            // &:focus-within {
            //     background: #FBFBFB;
            //     border:1px solid #E9E9E9;
            //     border-radius: 6px;
            // }
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }

            /* Firefox */
            input[type=number] {
            -moz-appearance: textfield;
            }
           
            input,select,textarea{
                // border: 0;
                border: 1px solid #ccc;
                padding: 10px 20px;
                background-color: transparent;
                color:#1C1C1C;
                font-family: $Font;
                font-size: 1rem;
                border-radius: 6px;
                &:focus{
                    outline:0;
                }
                @include placeholder {
                    color: #ccc;
                    font-size: 0.9rem;
                }
                &.themecolor{
                    border: 0;
                    padding: 0;
                    margin: 5px;
                    width: 37px;
                    height: 37px;
                    }
                
            }
            
            .timezone, .country{
                border: 1px solid #ccc;
                padding: 4px;
                border-radius: 6px;
            }
            select{
                -webkit-appearance: none;
                cursor: default;
                background: url(../../assets/images/select_arrow.svg);
                background-size: 10px;
                background-position: right 15px center;
                background-repeat: no-repeat;
                padding-right: 30px;
            }
            .error_cu{
                color:#ff0000;
                font-family: $SemiBoldFont;
                font-size: 0.9rem;
                margin-bottom: 10px;
                padding: 5px 0px 0px 5px;
            }
            .status-div{
                border: 1px solid #ccc;
                border-radius: 6px;
                padding: 3px 0px;
            }
            
        }
        label.select-year.themeColor {
            border: 0;
          }
        label.save-company-lable {
            border: none;
        }

        .save-btn{
            background-color: #CE2E6C;
            color: #fff;
            border: 0;
            height: 50px;
            padding: 0 56px;
            border-radius: 25px;
            font-family: $Font;
            font-size: 1.1rem;
            text-transform: uppercase;
            cursor:pointer;
            &:focus{
                outline: 0;
            }
        }
    

    .radio-wrap{
        display: flex;
        margin: 10px 0;
        .radio-inner-wrap {
            margin-right: 20px;
        }
    }




    /* The container */
.ezi-radio {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    font-family: $Font;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .ezi-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    padding: 0;
    left: 3px;
    top: 2px;
    z-index: 999;
    width:100%;
    height:100%;
  }
  
  /* Create a custom radio button */
  .ezi-checkmark {
      position: absolute;
      top: 2px;
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      border:1px solid #b4b4b4;
  }
  
  /* On mouse-over, add a grey background color */
  .ezi-radio:hover input ~ .ezi-checkmark {
    background-color: #eee;
  }
  
  /* When the radio button is checked, add a blue background */
  .ezi-radio input:checked ~ .ezi-checkmark {
       border: 1px solid #CE2E6C;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .ezi-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .ezi-radio input:checked ~ .ezi-checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .ezi-radio .ezi-checkmark:after {
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #CE2E6C;
  }

  .datepicker-wrap{
    padding: 15px 0;
    .react-date-picker{
        display: flex;
        .react-date-picker__wrapper{
            width:100%;
            input{
                padding: 0;
            }
        }
    }
  }

    .react-date-picker__wrapper{
        border:0;
    }
  .react-date-picker__button{
    &:focus{
        outline:0;
    }
  }
    .react-date-picker__clear-button{
        display: none;
    }


    .single-select{
        .css-yk16xz-control {
            border: 0;
        }
        .css-1okebmr-indicatorSeparator {
            background-color: transparent;
        }
        .css-1hwfws3{
            padding: 10px 0;
        }
        .css-1wa3eu0-placeholder{
                color: #e9e9e9;            
        }
    } 

    .add-company-form-wrap{
        background-color: #fff;
        border-radius: 10px;
        padding: 40px 40px;
        margin-bottom: 30px;
        margin-top: 50px;
        box-shadow: 0 3px 2px rgba(0,0,0,.1);

    }
    .company-info-header{            
        display: flex;
        justify-content: space-between;
    }
    .conpany-name-id-wrap{
        display: flex;
        flex-direction: column;
        .title{
            color:#1c1c1c;
            font-size: 1.2rem;
            margin-bottom: 7px;
            text-transform: capitalize;
        }
        .subtitle{
            color:#B7B7B7;
            font-size: 0.85rem;
        }
    }

    .company-add-logo-wrap {
        margin-right: 20px;
        position: relative;
        display: inline-flex;

        .company-logo-upload-position {
            position: absolute;
            right: 0px;
            bottom: 0px;
            display: inline-flex;
        }

        .company-logo-upload-wrapper{
            position: relative;
            overflow: hidden;
            display: inline-flex;
          }
          
          .company-logo-upload-btn {
            border:0;
            background-color: #CE2E6C;
            padding: 0;           
            background-image: url(../../assets/images/edit-icon-white.svg);
            background-size: 13px;
            background-position: center;
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            min-width: 24px;
            display: inline-flex;
            border-radius: 50%;
            cursor: pointer;
          }
          
          .company-logo-upload-input{
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }

        .company-default-logo {
            height: 70px;
            width: 70px;
            min-width: 70px;
            border-radius: 50%;
            object-fit: contain;
            
        }
    }

label.save-company-checkbox{
    flex-direction: unset;
    align-items: center;
    .label-checkbox{
        margin: 0px 8px;
    }
}
.datepicker-section{
    display: flex;
    .save-company-checkbox.check-box {
        padding: 8px 15px;
        display: flex;
    }
     
}
.contact{
    height: max-content;
}
.subscription-end{
    display: flex;
}
.subscription-start{
    display: flex;
}
label.datepicker-label {
    border: 0;
    margin: 0;
    &:focus-within {
        background: transparent !important;
        border: unset;
        border-radius: unset;
    }
  }
  label.select-year {
    height: max-content;
    border: 1px solid #ccc;
  }
.label-datepicker.disabled-section{
    .react-date-picker__inputGroup__leadingZero{
        color: #cdd1d6 !important;
        font-size: 1rem;
        margin-left: 8px;
    }
}

.email-check{
    border: unset;
    margin-top: 25px;
    &:focus-within{
        background: transparent !important;
    border: unset;
    border-radius: unset;
    }
}
.email-check{
    .ezi-checkbox.checkbox-label{
        &:focus-within{
            background: transparent !important;
        border: unset;
        border-radius: unset;
        }
    }
}
.label-datepicker.start-date, .label-datepicker.end-date{
    border: unset;
    .react-date-picker{
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 7px 20px;
    }
    .react-date-picker__inputGroup__leadingZero{
        color: #000;
        font-size: 1rem;
        margin-left: 8px;
    }
    .react-date-picker__inputGroup__input{
        padding: 0px 10px;
    }
}
.datepicker-wrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    .react-date-picker--disabled{
        background-color: white;
    }
    label.label-datepicker {
        display: unset;
        border: none;
    }
    .label-datepicker{
        width: calc(50% - 25%) !important;
        input{
            padding: 10px 0px;
        }
        .react-date-picker__wrapper{
            align-items: center;
            .react-date-picker__inputGroup {
                padding: 0px 10px !important;
            } 
           
        }
        .react-date-picker__inputGroup__divider {
            color: black;
        }
       
        
    }
    .save-company-checkbox{
        input[type="checkbox"] {
            padding: 10px !important;
            height: 22px !important;
            width: 16px !important;
            left: 5px !important;
            position: relative !important;
            top: 6px !important;
            margin: 0px 10px 0px 10px !important;
        }
    }
    .react-calendar {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        margin-top: 10px;
        padding: 0 10px;
        // box-shadow: 0px 3px 20px rgb(0 0 0 / 9%);
    }
    .react-calendar__navigation {
        height: 44px;
        margin-bottom: 1em;
    }
    .react-calendar__navigation button[disabled] {
        background-color: #f0f0f0;
    }
    
    abbr[title] {
        
        text-decoration: none !important;
        color: #504658 !important;
        font-size: 0.75rem;
        font-weight: normal;
    }
    .react-calendar__tile--active {
        background: #d12c6d !important;
        border-radius: 5px;  
        &:hover{
            background: rgba(206, 46, 108, 0.2) !important;
            color: #212529
        }  
        span.expiry {
            padding-right: 15px;
            padding-left: 5px;
        } 
    }
    .react-date-picker__inputGroup__leadingZero{
        border: 0;
        padding: 10px 8px;
        background-color: transparent;
        color: #1C1C1C;
        font-family: "NunitoSans";
        font-size: 1rem;
        border-radius: 6px;
    }
    
}


.save-company-checkbox {
  
.ezi-checkbox {
    display: inline-flex;
    align-items: center;
    position: relative;
    height: 16px;
    width: 17px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0 15px 0 0;
}

.ezi-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.ezi-checkbox-mark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 16px;
    width: 17px;
    border: 1px solid #c5c5c5;
    border-radius: 3px;
}

.ezi-checkbox:hover input ~ .ezi-checkbox-mark {
    background-color: #ccc;
}

.ezi-checkbox input:checked ~ .ezi-checkbox-mark {
    background-color: #c5326d;
    border-color: #c5326d;
}

.ezi-checkbox-mark:after {
    content: "";
    position: absolute;
    display: none;
}

.ezi-checkbox input:checked ~ .ezi-checkbox-mark:after {
    display: block;
}

.ezi-checkbox .ezi-checkbox-mark:after {
    left: 5px;
    top: 0px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
}

    .Page-TypeAnalysis .stock-card-wrapper {
        display: flex !important;
        flex-wrap: wrap !important;
    }
    .Page-TypeAnalysis .stock-card{
        width: 45% !important;
    }

    .domian-adress-wrapper{
        display: flex;
        width: 100%;
    }
    label.ezi-checkbox.checkbox-label {
        border: none;
    }
    .w-50{
        width: 50%;
    }
    .input-email-label{
        border: none;
        .input-email{
            border: 1px solid #ccc;
        }
    }

    span.expiry {
        font-size: 14px;
        margin-right: 10px;
        margin-top: 10px;
        color: #C5C5C5;
    }
    span.expiry.disabled-section {
        color: #cccccc !important;
    }
    input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__year:disabled {
        color: #cccccc;
    }
    input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month:disabled {
        color: #cccccc;
    }
    input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__day.react-date-picker__inputGroup__input--hasLeadingZero:disabled {
        color: #cccccc;
    }
    .disabled-section{
        .datepicker-wrapper .label-datepicker .react-date-picker__inputGroup__divider {
            color: #cccccc;
        }
        span.react-date-picker__inputGroup__leadingZero {
            color: #cccccc;
        }
    }
    .security-card{
        // background-color: #fff;
        // border-radius: 9px;
        // box-shadow: 7px 4px 28px rgba(0,0,0,0.06);
        // padding: 50px 40px;
        .to-to-from{
            display: flex;
            margin-bottom: 25px;
            .react-date-picker__inputGroup {
                min-width: calc(20px + 4.754em) !important;
            }
        }
        .go{
            margin-left: 20px;
            padding: 0 25px;
        }
        .date-start{
            font-size: 0.85rem;
            color: #B7B7B7;
            margin-right: 15px;
            margin-bottom: 0;
            border: 0;
        }
        .startend-date-wrap {
            display: flex;
            margin: 0;
            align-items: center;
            
            .date-wrapper-icon {
                background-image: url(../../assets/images/start-end-date.svg);
                background-size: 18px;
                background-position: center;
                background-repeat: no-repeat;
                height: 40px;
                width: 40px;
                margin-left: 20px;
            }
            .react-date-picker__wrapper {
                -moz-appearance: none;
                -webkit-appearance: none;
                border: 0;
                background-color: transparent;
                border: 1px solid #f3f3f3;
                border-radius: 25px;
                padding: 0 20px 0 50px;
                height: 36px;
                min-height: 36px;
                background-image: url(../../assets/images/datepicker_c5.svg);
                background-size: 18px;
                background-position: center left 18px;
                background-repeat: no-repeat;
                width: 100%;
                color: #504658;
                font-size: $OtherText;
                font-family: "NunitoSans";
                margin: 0;
                .react-date-picker__inputGroup {
                    input {
                        color: #504658;
                        &:focus {
                            outline: 0;
                        }
                    }
                    input.react-date-picker__inputGroup__input:focus-visible{
                        outline: none !important;
                    }
                }
                .react-date-picker__button {
                    display: none;
                }
            }
            &.end-wrap{
                margin-left: 30px;
            }
        }
        .totalCount{
            .st_card {
                background-color: #fff;
                display: inline-flex;
                align-items: center;
                padding: 20px;
                -moz-box-shadow: -1px 7px 60px rgba(0,0,0,.08);
                -webkit-box-shadow: -1px 7px 60px rgba(0,0,0,.08);
                box-shadow: -1px 7px 60px rgba(0,0,0,.08);
                border-radius: $ReportWidgetRadius;
                margin: 0 8px $ReportWidgetMargin;
                flex:1;
                min-width: 200px
            }
            .st_card-icon-wrap {
                display: inline-flex;       
                height: 60px;
                width: 60px;
                min-width: 60px;
                border-radius: 50%;
                margin-right: 20px;
            }
            .st_card-icon{
                height: 100%;
                width: 100%;
                background-size:20px;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 50%;
                &.template_ic{
                    background-color: #ce2e6c21;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #f084ae;           
                }
            }
            .st_card-text-wrap {
                display: inline-flex;
                flex-direction: column;
            }
            .st_card-text{
                color:#B7B7B7;
                font-size: $OtherText;
                margin-bottom: 2px;
            }
            .st_count{
                color:#5A4E63;
                font-family: $SemiBoldFont;
                font-size: 1.4rem;
            }
            .st_card-content-wrap{
                display: inline-flex;
                flex-direction: column;
                margin: 0 20px;
            }
        }
    }
    .img{
        align-items: flex-start;
        img{
            border: 1px solid #ccc;
            padding: 2px;
            border-radius: 4px;
        }
        input{
            padding: 7px 20px;
            font-size: $OtherText;
        }
        .a-link{
            font-family: $SemiBoldFont;
            margin-left: 13px;
            cursor: pointer;
            color: $ThemeColor;
        }
    }
}
@media (max-width: 600px){
    .Page-AddCompany .subscription-start, .Page-AddCompany .subscription-end{
        display: block;
    }
}

.sub-benchmark-div-wrraper{
    display: flex;
    .statement-input{
        width: 400px;
        input{
            padding: 5px !important;
        }:disabled {
            background-color: #ededed;
        }
    }
    .input-div{
        display: flex;
        flex-direction: row;
        margin-left: 0px;
        input{
            text-align: center;
            width: 100px;
        }:disabled {
            background-color: #ededed;
        }
        .scores-input{
            margin-right: 15px;
            margin-left: 15px;

        }
        .pos-score
        {
            margin-left: 20px;
        }
        input{
            padding: 5px !important;
        }:disabled {
            background-color: #ededed;
        }
    }
}
.addBenchmarkButton{
    color:white;
    font-size: small;
    border-radius: 20px;
    border: none;
    background-color:#ce2e6c;
    cursor: pointer;
    padding: 7px 25px;
   margin-right: 15px;
   margin-left: 15px;
}
.removBenchmarkButton{
    color: white;
    background-color:#ce2e6c;
    font-size: small;
    border-radius: 20px;
    padding: 7px 25px;
    border: none;
    cursor: pointer;
}
.list-header{
    color:#af9c9c;
    background-color: white;
    font-family: "NunitoSansSemiBold", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    white-space: nowrap;
    text-transform: capitalize;
    vertical-align: middle;
    font-weight: normal;
    display: flex;
    padding-bottom: 25px;
    margin-top: -10px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ccc;

    .Pos-head{
        margin-left: 355px;
    }
    .neu-head{
        margin-left: 90px;
    }
    .neg-head{
        margin-left: 90px;
    }
    .action-head{
        margin-left: 65px;
    }
}

.margin-wrap
{
    margin-top: 2px !important;
    box-shadow: none !important;
    border-radius: 3px !important;
    background-color: #fff;
    padding: 40px 40px;
    margin-bottom: 30px;
    
} 
.bor-ner{
    border-bottom: none !important;
}