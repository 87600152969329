@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";
.Page-CompaniesTemplateDashboard{
    padding: 40px 7%;
    background-color: #F2F5F8;


    @import "../../assets/scss/ezicheckbox";

    .template-category-subtitle{
        color: #B7B7B7;
        font-family: $Font;
        font-size: 0.9rem;
        margin: 10px 0;
        display: block;
    }
    .template-category-dropdown{
        position: relative;
        margin: 10px 0;
    }
    .template-category-dropdown-btn{
        border:0;
        color:#504658;
        font-family: $Font;
        font-size: 1.3rem;
        background-color: transparent;
        background-image: url(../../assets/images/themes-dropdown.svg);
        background-size: 16px;
        background-position: right;
        background-repeat: no-repeat;
        padding: 0 35px 0 0;
        
        &:focus{
            outline: 0;
        }
        &.selected{
            color : #CE2E6C;
            background-image: url(../../assets/images/show-entry_ic.svg);
        }

    }
    .save-template.ezi-pink-btn{
        background-color: #ce2e6c;
    }
    .cat-search-count-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .cat-temp-count-wrap {
        display: inline-block;
        margin-bottom: 10px;
        color: #1c1c1c;
        text-indent: 40px;
        font-size: 0.9rem;
    }
    
    .cat-temp-count {
        margin-left: 10px;
        height: 24px;
        line-height: 24px;
        min-width: 28px;
        background: #CFCDD2;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        font-size: 0.8rem;
        padding: 0 10px;
        color: #504658;
        text-indent: initial;
    }

    .template-category-inner-dropdown{
        display: none;
        background-color: #fff;
        border-radius: 20px;
        padding: 0;
        max-width: 275px;
        width: 100%;
        min-height: 280px;
        flex-direction: column;
        overflow: hidden;
        position: absolute;
        z-index: 9999;
        top: 100%;
        left: 0;
        margin-top: 8px;
        -moz-box-shadow: 4px 7px 23px rgba(0,0,0,0.11); 
        -webkit-box-shadow: 4px 7px 23px rgba(0,0,0,0.11); 
        box-shadow:4px 7px 23px rgba(0,0,0,0.11);
        &.active{
            display: inline-flex;        
        }
        .template-category-dropdown-header {
            position: relative;
            border-bottom: 1px solid #E9E9E9;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-between;
            label{
                margin: 0;
                font-family: $SemiBoldFont;
                color:#E9E9E9;
                font-size: 0.8rem;
                min-height: 40px;
                line-height: 40px;
                padding-left: 30px;
                white-space: nowrap;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .template-category-dropdown-search{
            border:0;
            font-family: $Font;
            color:#1C1C1C;
            font-size: 0.8rem;
            background-image: url(../../assets/images/theme-drop-search.svg);
            background-size: 12px;
            background-position: top 13px right 30px;
            background-repeat: no-repeat;
            padding: 0 55px 0 0;
            width: 100%;
            max-width: 100px;
            margin-left: auto;
            min-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: max-width 0.3s ease;           

            @include placeholder {
                color: #E9E9E9;
                font-family: $ItalicFont;
            }
            &:focus{
                outline:0;
                background-color: #FBFBFB;
                max-width: 100%;
                padding-left: 30px;
                + label{
                    display: none;
                }
            }
        }
        ul {
            padding: 12px 0 15px;
            max-height: 270px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 4px;
            }
            li {
                list-style-type: none;
                padding: 6px 30px;
                font-family: $Font;
                color:#B7B7B7;
                transition: color 0.3 ease;
                position: relative;
                font-size: 0.82rem;
                text-transform: capitalize;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &:hover{
                    color:#CE2E6C;
                    cursor: pointer;
                    background-color: #FBFBFB;
                    &:after{
                        content: '';
                            width:3px;
                            height:100%;
                            position: absolute;
                            left:0;
                            top:0;
                            background-color:#CE2E6C;
                    }
                    
                }

            }
        }
    }




       .category_progress_ring_wrap {
           .publish-templates-text{
               color: #CE2E6C;
               font-size: 0.7rem;
               display: block;
               margin-top: 15px;
               &:before{
                   content: '';
                   display: inline-block;
                   height: 6px;
                   width: 6px;
                   background-color: #CE2E6C;
                   border-radius: 50%;
                   margin-right: 10px;
               }
           }
       }
       .category_progress_ring {
           height: 84px;
           width: 84px;
           min-width: 84px;
           margin: 0 auto;
           
       }
       .CircularProgressbar.category_progress_circle{
           .CircularProgressbar-trail{
               stroke: #F2F5F8;
           }
           .CircularProgressbar-path{
               stroke: #CE2E6C;
               stroke-linecap: square;
           }
           .CircularProgressbar-text{
              fill: #CE2E6C;
              font-family: $SemiBoldFont;
              font-size: 1rem;
           }
       }
       .category-dashboard-card-wrap {
           display: flex;
           flex-wrap: wrap;
           margin: 0 -15px 20px;
       }
       .category-dashboard-table-header {
            display: flex;
            justify-content: space-between;
            padding: 0 0;
            margin-bottom: 15px;
            .category-dashboard-table-header-left {
                span{
                    color: #1C1C1C;
                    font-family: $SemiBoldFont;
                    font-size: 1rem;
                    text-transform: capitalize;
                }
            }
            // .category-dashboard-table-header-right {

            // }
        
        }
       .category-dashboard-card {
           background: #fff;
           border-radius: 5px;
           display: inline-flex;
           min-height: 184px;
           align-items: center;
           margin: 0 15px;
           padding: 0 40px;
           margin-bottom: 30px;
           -moz-box-shadow: -1px 7px 60px rgba(0,0,0,0.08);
           -webkit-box-shadow: -1px 7px 60px rgba(0,0,0,0.08);
           box-shadow: -1px 7px 60px rgba(0,0,0,0.08);     
           -webkit-transition: transform 0.3s ease;
           transition: transform 0.3s ease;
            .category-dashboard-card_text_wrap{
                position: relative;
            }
           .category-dashboard-card-heading {
               color: #1C1C1C;
               font-family: $SemiBoldFont;
               font-size: 2.6em;
               text-overflow: ellipsis;
               white-space: nowrap;
               overflow: hidden;
               margin: 0 0 5px;
           }
           .category-dashboard-card-subheading {
               color: #B7B7B7;
               font-size: 0.88rem;
               font-family: $Font;
           }
           &.total_template {
              flex:1;
               display: flex;
               justify-content: space-between;
               border-bottom: 4px solid #504658;
               .category-dashboard-card_text_wrap{
                   margin-right: 30px;
                   max-width: 220px;
               }
              
           }
         
           &.active_template{
               cursor: pointer;
               max-width: 200px;
               width: 100%;
               position: relative;
               overflow: hidden;
               &:before {
                    content: '';
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 98%;
                    background: #B5EE5F;    
                    transition: all 0.5s ease;                
                 }
                 &:hover{
                    &:before{
                        top: 0;
                    }
                    .category-dashboard-card-heading, .category-dashboard-card-subheading {
                        color: #fff;
                    }
                 }
                 &.active{
                    background: #B5EE5F; 
                    .category-dashboard-card-heading, .category-dashboard-card-subheading {
                        color: #fff;
                    }
                 }
           }
           &.unpublish_template{
               cursor: pointer;
               max-width: 200px;
               width: 100%;
                position: relative;
                overflow: hidden;
               &:before {
                    content: '';
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 98%;
                    background: #b7b7b7;    
                    transition: all 0.5s ease;                
                 }
                 &:hover{
                    &:before{
                        top: 0;
                    }
                    .category-dashboard-card-heading , .category-dashboard-card-subheading  {
                        color: #fff;
                    }
                 }   
                 &.active{
                    background: #b7b7b7;   
                    .category-dashboard-card-heading , .category-dashboard-card-subheading  {
                        color: #fff;
                    }
                 }            
           }
           &.create-survey-template {
                background-color: #504658;
                cursor: pointer;
                max-width: 200px;
                width: 100%;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .create-survey-template-ic{
                    height:60px;
                    width:60px;
                    min-width:60px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-image: url(../../assets/images/template/plus.png);
                    background-size: 32px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: #CE2E6C;
                    border-radius: 50%;                    
                    margin-bottom: 15px;
                }
                .create-survey-template-name{
                    color: #fff;
                    max-width: 100%;
                    text-align: center;
                    font-size: 0.85rem;
                    margin: 0;
                    overflow: hidden;
                }
            }
          
       }
   
       @media (max-width : 550px){
           .survey-dashboard-card{
               width: 100% !important;
               max-width: 100% !important;
           }
       }




     
      
     
      
      .category-dashboard-table {
        margin:  0;
        border-radius: 5px;
        width: 100%;
        display: table;
        background-color: #FFFFFF;
        -moz-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
        box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
        padding-bottom: 20px;         


        .category-dashboard-table-row  {
            display: table-row;            
            &:last-child{
                .category-dashboard-table-cell{
                    border-bottom: 0;
                }
            }
          }
          .category-dashboard-table-row.category-table-heading {
            font-family: $SemiBoldFont;
            .category-dashboard-table-cell{
                padding: 20px 15px;
                color: #B7B7B7;
                font-size: 0.8rem;
                font-family: $SemiBoldFont;
                &:first-child{
                    text-align:left;
                    padding-left: 40px;
                }            
            }
          }
          .category-dashboard-table-cell {
            padding: 30px 12px;
            display: table-cell;
            text-align: center;            
            border-bottom: 1px solid #e9e9e9;             
            vertical-align: middle;
            color: #1C1C1C;
            font-size: 0.88rem;
            font-family: $SemiBoldFont;
            &:first-child{
                text-align:left;
                padding-left: 40px;
            }
            &:nth-child(2){
                text-align:left;
            }
            .ezi-checkbox{
                margin: 0;
                height: 20px;
                width: 18px;
            }
            .allocate-save{
                padding: 0 26px;
                background-color: #ce2e6c;
            }
          }

        //   .delete_data{
        //       border:0;
        //       background-color: transparentize($color: #000000, $amount: 0);
        //   }
          .delete_data {
            background: transparent;
            border: 0;
            padding: 0;
            color: #C5C5C5;
            transition: color 0.3s ease;
            font-family: $Font;
            font-size: 0.85rem;
            display: inline-flex;
            align-items: center;   
            &:focus{
                outline: 0;
            }     
            &:hover{
                color: #CE2E6C;
                &:before{
                    background-image: url(../../assets/images/table_delete_active_ic.svg); 
                }
            }
            &:before{
                content: '';
                display: inline-block;
                background-image: url(../../assets/images/table_delete_ic.svg);
                background-size: 10px;
                background-position: center;
                background-repeat: no-repeat;
                height: 24px;
                width: 24px;
                margin-right: 2px;
                transition: background-image 0.3 ease;
    
            }
        }

          .category-table-template-wrap {
            display: flex;
            align-items: center;
            cursor: pointer;
        }        
        .category-template-logo {
            height: 50px;
            width: 50px;
            min-width: 50px;
            margin-right: 25px;
            border-radius: 4px;
        }
        .category-table-template-name {
            color: #1C1C1C;
            font-family: $SemiBoldFont;
            font-size: 1.05rem;
            margin-bottom: 5px;
            text-transform: capitalize;
        }
        .category-table-create{
            font-size: 0.75rem;
            color: #B7B7B7;
        }
        
        .category-table-template-text-wrap {
            display: flex;
            flex-direction: column;
        }
        .table-count {
            height: 24px;
            line-height: 24px;
            min-width: 28px;
            background: #CFCDD2;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 14px;
            font-size: 0.8rem;
            padding: 0 10px;
            color: #504658;
        }
      }   

      .category-pagination-formatter{
          background-color: #fff;
      }
      
    
      @media screen and (max-width: 580px) {
        .category-dashboard-table {
            display: block;
          }
        .category-dashboard-table-row  {
          padding: 14px 0 7px;
          display: block;
        }
        .category-dashboard-table-row.category-table-heading {
          padding: 0;
          height: 6px;
          background: #CE2E6C;
        }
        .category-dashboard-table-row.category-table-heading .category-dashboard-table-cell {
          display: none;
        }
        .category-dashboard-table-row .category-dashboard-table-cell {
          margin-bottom: 10px;
        }
        .category-dashboard-table-row .category-dashboard-table-cell:before {
          margin-bottom: 3px;
          content: attr(data-title);
          min-width: 98px;
          font-size: 10px;
          line-height: 10px;
          font-family: $SemiBoldFont;
          text-transform: uppercase;
          color: #969696;
          display: block;
        }
        .category-dashboard-table-cell {
            padding: 30px 16px;
            display: block;
          }
        
      }
      
      @media screen and (max-width: 580px) {
        .category-dashboard-table  .category-dashboard-table-cell {
          padding: 6px 16px;
          display: block;
          text-align: left;
            &:first-child {
                padding: 6px 16px;
            }
        }
      }
    
    
      



}