@import "../assets/scss/variable";
@import "../assets/scss/placeholder";
.Page-PartnerAdminDashboard{
    padding: 40px 7%;
    background-color: #F2F5F8;

    .no-card-data {
        color: #b7b7b7;
        display: block;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 0.85rem;
    }

    .admin-dashboard-header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        border-bottom: 1px solid #E9E9E9;
        padding-bottom: 25px;
        
        .user-welcome-wrap {
            display: inline-block;
        }
        
        .user-welcome-text {
            display: block;
            color: #504658;
            font-size: 1.5rem;
            margin-bottom: 5px;
        }
        
        .admin-dashboard-beadcrumb {
            display: block;
            font-size: 0.85rem;
            color: #8D8D8D;
        }

        .add-new-partner:before {
            content: '+';
            margin-right: 6px;
            font-family: $SemiBoldFont;
            font-size: 1rem;
        }

    }

  


    .common-template-card-wrap {
        margin: 40px -15px 0;
        display: flex;
        flex-wrap: wrap;
    }

    .common-template-card {
        background-color: #fff;
        display: inline-flex;
        align-items: center;
        padding: 20px;
        -moz-box-shadow: -1px 7px 60px rgba(0,0,0,.08);
        -webkit-box-shadow: -1px 7px 60px rgba(0,0,0,.08);
        box-shadow: -1px 7px 60px rgba(0,0,0,.08);
        border-radius: 9px;
        margin: 0 15px 15px;
        flex:1;
        cursor: pointer;
        &.add-new-company{
            background-color: #CE2E6C;
            .common-template-card-icon-wrap{
                background-color: #B51E58;
                .circle-plus{
                    background-image: url(../assets/images/circle-plus-white.svg);
                    background-size: 30px;
                }
            }
            .common-template-card-text{
                color: #FF7AAD;
            }
            .new-company-text{
                color: #fff;
                font-size: 1.25rem;
            }
        }
    }
    .common-template-card-icon-wrap {
        display: inline-flex;       
        height: 60px;
        width: 60px;
        min-width: 60px;
        border-radius: 50%;
        margin-right: 20px;
    }
    .common-template-card-icon{
        height: 100%;
        width: 100%;
        background-size:20px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        &.template_ic{
            background-image: url(../assets/images/template/template.png); 
            background-color: #F0DECB;           
        }
        &.total_temaplte_ic{
            background-image: url(../assets/images/template/publish.png);
            background-color: #F4D1DF;
        }
        &.publish_ic{
            background-image: url(../assets/images/template/publish_blue.svg);
            background-color: #E2EBF1;
            background-size: 20px;
        }
        &.question_ic{
            background-image: url(../assets/images/template/question.png);
            background-color: #E2EBF1;
            background-size: 22px;
        }
    }
    .common-template-card-text-wrap {
        display: inline-flex;
        flex-direction: column;
    }
    .common-template-card-text{
        color:#B7B7B7;
        font-size: 0.85rem;
    }
    .common-template-count{
        color:#5A4E63;
        font-family: $SemiBoldFont;
        font-size: 1.5rem;
        &.template-active{
            color:#CE2E6C;
        }
        &.template-inactive{
            color:#165888;
        }
    }
    .common-template-card-content-wrap{
        display: inline-flex;
        flex-direction: column;
        margin: 0 20px;
    }
  
    .common-vertical-card-wrap {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
        max-width: 220px;
        height:100%;
        max-height:540px;
    }

    .common-vertical-card {
        background-color: #fff;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        min-height: 200px;
        border-radius: 9px;
        margin-bottom: 30px;
        -moz-box-shadow: -1px 7px 60px rgba(0,0,0,.08);
        -webkit-box-shadow: -1px 7px 60px rgba(0,0,0,.08);
        box-shadow: -1px 7px 60px rgba(0,0,0,.08);
        cursor: pointer;
        &:last-child{
            margin-bottom: 0;
        }

        &.usersroles{
            .common-vertical-card-icon-wrap{
                background-color:#E9E9E9;
            }
        }

        &.need-help{
            background-color: #504658;
            .common-vertical-card-icon-wrap{
                background-color: #72637E;
            }            
        }

    }
    .common-vertical-card-icon-wrap {
        display: inline-flex;
        height: 70px;
        width: 70px;
        min-width: 70px;
        border-radius: 50%;
    }
    .common-vertical-card-icon{
        height: 100%;
        width: 100%;
        background-size: 25px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        &.usersroles_ic{
            background-image: url(../assets/images/users-roles-50.svg); 
            background-size: 48px;
        }
        &.need-help_ic{
            background-image: url(../assets/images/need-help.svg); 
            background-size: 18px;
            background-color: #72637E;
        }
        &.tips-making_ic{
            background-image: url(../assets/images/tips-making.svg); 
            background-size: 18px;
            background-color: #FEF8E7;
        }
    }
    
    .users-roles-text{
        color: #504658;
        font-size: 1rem;
        margin-top: 14px;
    }
    .need-help-text{
        color:#fff;
        font-size: 1rem;
        margin-top: 14px;
    }
    .tips-making-text{
        color:#504658;
        font-size: 1rem;
        margin-top: 14px;
        text-align: center;
    }

    .tips-making-wrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        // .common-vertical-card-icon-wrap{
        //     margin-right: 20px;
        // }
    }

    .partner-vertical-card-main-wrap{
        display: flex;
        margin-top: 20px;
    }

    .recent-template-card, .recent-companies-card {
        background-color: #fff;
        box-shadow: -1px 7px 60px rgba(0,0,0,.08);
        width: calc(50% - 140px);
        border-radius: 9px;
        height:100%;
        max-height:430px;
        min-height:430px;
        position: relative;
    }
    .recent-template-card{
        margin: 0 30px;
    }
    .recent-template-card-header,.recent-companies-card-header {
        padding: 12px 20px;
        color: #5A4E63;
        border-bottom: 1px solid #e9e9e9;
        text-transform: capitalize;
    }

    .recent-template-card-body{
        overflow-y: auto;
        margin: 20px 20px;
        max-height: calc(430px - 89px);
        height: 100%;
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #b7b7b7;
        }
        &::-webkit-scrollbar-track {
            background-color: #E9E9E9;
        }
        ul{
            margin: 0;
            padding: 0;
            li{
                list-style: none;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                padding-right: 12px;
            }
        }
        .recent-template-index{
            height: 26px;
            width: 26px;
            min-width: 26px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #1C1C1C;
            background-color: #F2F5F8;
            margin-right:15px;
            font-size: 0.8rem;
        }
        .recent-template-name {
            color: #1C1C1C;
            font-family: $SemiBoldFont;
            font-size: 0.87rem;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
        }
        .recent-template-status {
            border-radius: 25px;
            padding: 4px 20px 4px 15px;
            margin: 0 15px;
            font-size: 0.8rem;
            font-family: $Font;
            display: inline-flex;
            align-items: center;
            min-width: 114px;
            padding-right: 0;
            margin-right: 0;
            margin-left: auto;
            color:#B7B7B7;
            &:before{
                content: '';
                height: 8px;
                width: 8px;
                display: inline-block;
                border-radius: 50%;
                margin-right: 10px;
            }
            &.published{
                // color:#504658;
                &:before{
                    background: #B5EE5F;
                }
            }
            &.unpublished{
                // color:#FF6161;
                &:before{
                    background: #FF6161;
                }
            }
            &.draft{
                // color:#B7B7B7;
                &:before{
                    background: #C5C5C5;
                }
            }
        }
        .recent-template-report {
            background: transparent;
            border: 1px solid #E9E9E9;
            border-radius: 25px;
            padding: 4px 24px;
            color: #C5C5C5;
            font-size: 0.85rem;
        }
    }


    .recent-companies-card-body {
        overflow-y: auto;
        margin: 20px 20px;
        max-height: calc(430px - 89px);
        height: 100%;
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #b7b7b7;
        }
        &::-webkit-scrollbar-track {
            background-color: #E9E9E9;
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
            }
        }
        .recent-companies-logo {
            height: 40px;
            width: 40px;
            min-width: 40px;
            border-radius: 50%;
            margin-right: 12px;
        }
        .recent-companies-name {
            color: #1C1C1C;
            font-family: $SemiBoldFont;
            font-size: 0.87rem;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
        }
        .recent-companies-id {
            color: #B8B8B8;
            font-size: 0.77rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            margin-left: auto;
            min-width: 100px;
            padding-left: 10px;
        }
    }
    

    @media (max-width:1024px){

    }


}


.tab-left-header{
    .nav-item{
        margin-bottom: 15px;
        margin-right: 40px;
        &:last-child{
            margin-right: 0;
        }
        a{
            color: #B7B7B7;
            padding-bottom: 20px;
            position: relative;
            transition: color 0.3s ease;
            font-family: $SemiBoldFont;
            &:hover{
                color:#CE2E6C;
            }
            &:focus{
                outline: 0;
            }
            
        }
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: transparent;
        border-color:transparent;
        color:#CE2E6C;
        &:after{
            content: '';
            position: absolute;
            left: 0;
            bottom:0;
            height:2px;
            width: 100%;
            background-color:#CE2E6C;

        }
    }
}

.tab-header-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.tab-content {
    padding: 40px 0;
}

.tab-right-header {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;

}
