@import "./assets/scss/variable.scss";
@import "./assets/scss/placeholder.scss";


*, *::before, *::after {
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
  }
  html,body,#root,.App{
    height: 100%;
  }
  
  body{
  margin:0;
  background-color: #F2F5F8;
  font-family: 'NunitoSans', sans-serif;
  
  }
  a:hover{
    color: inherit;
    text-decoration: none;
  }
  
  ::-webkit-scrollbar-track
  {
   
    background-color: #eee;
    
  }
  
  ::-webkit-scrollbar
  {
    width: 10px;
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar-thumb
  {
    background-color: #ddd;
    border-radius: 10px;
  }
  
  button:focus,a:focus{
    outline: 0 !important;
  }

  
.app_loading {
    font-size: 25px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.app_loading span {
  font-size: 50px;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.app_loading span:nth-child(2) {
  animation-delay: .2s;
}

.app_loading span:nth-child(3) {
  animation-delay: .4s;
}

@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

.ezi-loading-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;
}
.ezi-spinner {
    width: 75px;
    height: 75px;
    margin: 0;
    background: transparent;
    border-top: 4px solid #CE2E6C;
    border-right: 4px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s ezi-spin linear infinite;
    animation: 1s ezi-spin linear infinite;
  }
  
  @-webkit-keyframes ezi-spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes ezi-spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  
  /**
   * Loader Css  Start  
   */

   .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
  }
  .ezi-loader-check{
    background-image: url(./assets/images/ezi-loader-check.svg);
    background-size: 25px;
    background-position: center;
    background-repeat: no-repeat;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 11px;
    left: 14px;
  }
    #ezi-loader{
        width: 70px;
        height: 70px;
        border-radius: 100%;
        position: relative;
        margin: 0 auto;
      }
      #ezi-loader:before, #ezi-loader:after{
        content: "";
        position: absolute;
        top: -7px;
        left: -7px;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: 7px solid transparent;
        border-top-color: #D12C6D;
      }
      
      #ezi-loader:before{
        z-index: 100;
        animation : ezi-spin .6s infinite linear;
      }
      
      #ezi-loader:after{
        border: 7px solid #C5C5C5;
      }
      .ezi-loader-text{
        color: #D12C6D;
        font-size: 0.86rem;
        font-family: 'NunitoSansSemiBold';
        margin: 4px 0;
      }
      
      @keyframes ezi-spin{  
          0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
          }
      
          100% {
              -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
          }
      }
      
/* Loader Css  End  */
  

.ezi-btn{
    box-shadow: none;
    border: none;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family:$Font;
}
.ezi-btn:hover,
.ezi-btn:focus {
   outline: 0;
     box-shadow: none;
}

.btn-ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.btn-ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #ffffff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}
.btn-ripple:active:after {
  transform: scale(0, 0);
  opacity: .2;
  transition: 0s;
}

.react-confirm-alert{
    .react-confirm-alert-body > h1 {
        text-align: center;
        font-size: 23px;
    }
    .react-confirm-alert-body{
        text-align: center;
    }
    .react-confirm-alert-button-group {
        justify-content: center !important;
        align-items: center !important;
    }
    .alert-input{
        padding: 7px 10px;
        border: 1px solid #ccc;
        border-radius: 3px;
        width: 260px;
    }
}
.access_lock {
    opacity: 0.5;
}
.ezi-grey-btn{
    background-color: #E9E9E9;
    color:#656565 !important;
}
.ezi-pink-btn{
    background-color: #CE2E6C;
    color: #fff !important;
    border: 0;
    min-height: 32px;
    border-radius: 25px;
    padding: 0 20px;
    margin: 0;
    font-family: $Font;
    font-size: 0.8rem;
    display: inline-flex;
    align-items: center;
    &:focus{
        outline: 0;
    }
     .add_ic {
        display: inline-flex;
        align-items: center;
        height: 18px;
        width: 18px;
        background-image: url(./assets/images/adds.svg);
        background-size: 12px;
        background-position: top 3px right;
        background-repeat: no-repeat;
        margin-left: 2px;
    }
}

.ezi-filter-btn{
    border:1px solid #5A4E63;
    background-color:transparent;
    color: #5A4E63;
    min-height: 32px;
    border-radius: 25px;
    padding: 0 30px 0 20px;
    margin: 10px 0;
    font-family: $SemiBoldFont;
    font-size: 0.8rem;
    display: inline-flex;
    align-items: center;
    &:focus{
        outline: 0;
    }
    &:before{
        content: '';
        background-image: url(./assets/images/filter-icon.svg);
        background-size: 14px;
        background-position: center;
        background-repeat: no-repeat;
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
}
.page-not-found-component{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Toastify__toast{
    font-family: $Font;
    border-radius: 60px;
    color:#504658;
    align-items: center;    
    background-color: #fff;
    &:before{
        content: '';
        height: 40px;
        width: 40px;
        background-size: 35px;
        background-position: top 3px right;
        background-repeat: no-repeat;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 20px 0 10px;
        
    }
    .Toastify__close-button{
        &:focus{
            outline: 0;
        }
        
    }
    &.Toastify__toast--success:before{     
            background-image: url(./assets/images/toast/success_toast_ic.svg);           
    }
    &.Toastify__toast--error:before{     
        background-image: url(./assets/images/toast/error_toast_ic.svg);           
    }
    &.Toastify__toast--warning:before{     
        background-image: url(./assets/images/toast/warning_toast_ic.svg);           
    }
    &.Toastify__toast--info:before{     
        background-image: url(./assets/images/toast/info_toast_ic.svg);           
    }
}

.popover.ezi-popover-box{
    border:0;
    -moz-box-shadow: 6px 4px 14px rgba(0,0,0,0.11);
    -webkit-box-shadow: 6px 4px 14px rgba(0,0,0,0.11);
    box-shadow: 6px 4px 14px rgba(0,0,0,0.11);
    border-radius: 10px;
    .arrow{
        &:before{
            border:0;
        }
    }
    .popover-body{
        padding: 25px 15px ;
        color: #3B3B3B;
        font-family: $LightFont;
        font-size: 0.85rem;
        line-height: 24px;
        min-width: 200px;
    }
}


.ezi-popover-btn{
    height: 34px;
    width: 34px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background-color: transparent;
    background-image: url(./assets/images/ezipopover.svg);  
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    padding: 0;
      
    &:focus{
        outline:0;
    }
}
.popover-close-btn{
    height: 20px;
    width: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background-color: transparent;
    background-image: url(./assets/images/modal_close_ic.svg);  
    background-size: 9px;
    background-position: center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    padding: 0;
    position: absolute;
    top: 5px;
    right: 8px;
    &:focus{
        outline:0;
    }
}


.main-wrapper{
    height: 100%;
    background-color: #F2F5F8;
}
.inner-wrapper {
    height: calc(100% - 70px);
    overflow-y: auto;
    .footer-bottom{
        width: 100%;
        text-align: center;
        padding-top: 10px;
        color: #a7a6a6;
        p{
            font-size: $OtherText;
        }
    }
}


.breadcrumb_ezi{
    border-bottom:1px solid #DDE0E2;
    padding-bottom: 12px;
    margin-bottom: 20px;
    .breadcrumb{
    background-color: transparent;
    margin-bottom: 0.5rem;
    margin: 0 -15px;
    .breadcrumb-item{
        
        display: inline-flex;
        align-items: center;
        a{
            color: #C5C5C5;
            font-family: $SemiBoldFont;
            font-size: 0.85rem;
            text-transform: capitalize;
            &:hover{
                text-decoration: none;
                
            }
        }

        &:after{
            content:'';
            background-image: url(./assets/images/breadcrumb_arrow_prev.svg);
            background-size: 6px;
            background-position: center;
            background-repeat: no-repeat;
            height: 20px;
            width: 20px;
            display: inline-block;
            margin-left: 7px;                
        }
                +     .breadcrumb-item{
                &:after{
                    content:'';
                    background-image: url(./assets/images/breadcrumb_arrow_prev.svg);
                    background-size: 6px;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 20px;
                    width: 20px;
                    display: inline-block;
                    margin-left: 7px;                
                }
                &:before{
                    display:none;
                }
                &:last-child{
                   a{
                    color:#504658;
                    font-family: $SemiBoldFont;
                    font-size: 0.85rem;
                   }
                   &:after{
                    background-image: url("./assets/images/breadcrumb‭_arrow.svg");           
                    }
                }
            }
        
     }
    }
    

    .column-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .page-heading{
            color:#504658;
            font-family: $Font;
            font-size: 1.6rem;
            font-weight: normal;
            margin-bottom: 1.3rem;
            text-transform: capitalize;
        }
        .column-header-btn{
                margin-left: auto;
                button{
                    padding: 7px 25px;
                    margin-bottom: 1.3rem;
                    border:0;
                    color:#FFF;
                    font-family: $Font;
                    font-size: 0.8rem;
                    border-radius: 25px;
                    display: inline-flex;
                    align-items: center;
                    &:focus{
                        outline:0;
                    }
                 
                }
                .add-new{
                        background: #CE2E6C;
                        margin-right: 10px;
                        .add_ic{
                            display: inline-flex;
                            align-items: center;
                            height:18px;
                            width: 18px;
                            background-image: url(./assets/images/adds.svg);
                            background-size: 12px;
                            background-position: top 2px right;
                            background-repeat: no-repeat;
                            margin-left: 2px;
                        }
                }
                .next{
                        background: #5A4E63;
                        .next_ic{
                            display: inline-flex;
                            align-items: center;
                            height:18px;
                            width: 18px;
                            background-image: url(./assets/images/map_next_btn.svg);
                            background-size: 6px;
                            background-position: top 3px right;
                            background-repeat: no-repeat;
                        }
                }
                .back{
                    background-color: transparent;
                    color:#C5C5C5;
                    margin-right: 15px;
                }
        }
     
    }

}

// upload btn css start
.common-img-upload-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;

    .common-upload-btn-formatter {
        border: 0;
        color: #504658;
        background-color: #F2F5F8;
        padding: 0 32px;
        border-radius: 25px;
        font-size: 1rem;
        display: inline-flex;
        align-items: center;
        min-height: 38px;
        &:before{
            content: '';
            background-image: url(./assets/images/upload-file-icon.svg);
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            height: 20px;
            width: 20px;
            margin-right: 16px;
        }
    }

    .common-upload-input {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }


}
// upload btn css End


// Datatable Css start

.react-bootstrap-table {
    background-color: #fff;
    border-radius: 9px;
    padding: 0 0 20px;
    -moz-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
    box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
    overflow-x: auto;
    // &:active{
    //     cursor: grabbing;
    //     cursor: -webkit-grabbing;
    // }
    &::-webkit-scrollbar{
        height:8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
    }
    .table {
        table-layout: auto;
        border:0;
        thead{
            border-bottom: 1px solid #E9E9E9;
            tr th{
                &:first-child{
                    padding-left: 40px;
                }
                color:#B7B7B7;
                font-family: $SemiBoldFont;
                font-size: 0.8rem;
                padding: 22px 10px;
                white-space: nowrap;
                text-transform: capitalize;
            //     overflow: hidden;
            //    text-overflow: ellipsis;
               vertical-align: middle;
               font-weight: normal;
               &:focus{
                   outline: 0;
               }
            }
        }
        tbody{
            
            tr {

               &:hover{
                    td{
                        background-color: #F9F9F9;
                        &:first-child{
                            position: relative;
                            &:after {
                                content: '';
                                height: 100%;
                                width: 4px;
                                background: #CE2E6C;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                        }
                    }
                   
                    .react-bs-table-no-data{
                        background-color: transparent;
                        &:after {
                            display: none;
                         }
                    }
                    
               }
               
                &:first-child{
                    padding-top: 20px;
                }
                td{
                    &:first-child{
                        padding-left: 40px;
                    }
                    color:#1C1C1C;
                    font-family: $Font;
                    font-size: 0.85rem;
                    padding: 18px 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // white-space: nowrap;
                    vertical-align: middle;
                    position: relative;
                    white-space: nowrap;
                    &:hover:after{
                        content: '';
                        display: inline-block;
                        background-image: url(./assets/images/datatable_edit_ic.svg);
                        background-size: 10px;
                        background-position: top right;
                        background-repeat: no-repeat;
                        height: 20px;
                        width: 20px;
                        position: absolute;
                        top: 8px;
                        right: 10px;
                        // right: 4px;
                        // top: 15px;
                    }
                    &:last-child:hover:after{
                        display: none;
                    }
                }
                td.react-bootstrap-table-editing-cell{
                    // .form-control.editor.edit-text -- uncontrol input
                    .form-control{
                        border-radius: 8px;
                        border:1px solid #E9E9E9;
                        background-color: #FBFBFB;
                        box-shadow: none;
                        &:focus{
                            box-shadow: none;
                            outline:0;
                        }
                    }
                }

                .react-bs-table-no-data {
                    padding: 30px 20px !important;
                    height: 150px;
                    line-height: 150px;
                    color: #A5A5A5 !important;
                }
            }
           
        }
    }
    .table thead th{
        border:0;
    }
    .table td, .table th{
        border:0;
    }
    
    .dt-bootstrap-search-wrap{
        width: 150px;
    }
    .dt-bootstrap-search-input{
        border: 0;
        background: transparent;
        background-image: url(./assets/images/people-search.svg);
        background-size: 13px;
        background-position: top 11px right;
        background-repeat: no-repeat;
        transition: all 0.5s ease;
        min-height: 36px;
        padding-right: 20px;
        margin-right: 20px;
        width: 80px;
        &:focus{
            outline: 0;
            width: 150px;
            border-bottom: 1px solid #c5c5c5;
        }
        @include placeholder{
            color:#8D8D8D;
            font-size: 0.9rem;
        }
    }

    .dt-bootstrap-edit-delte-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
    }
        .dt-bootstrap-edit_ic {
            background: transparent;
            border: 0;
            padding: 0;
            margin-right: 15px;
            color: #C5C5C5;
            transition: color 0.3s ease;
            font-family: $Font;
            font-size: 0.8rem;
            display: inline-flex;
            align-items: center; 
            &:focus{
                outline: 0;
            }
            &:hover{
                color: #CE2E6C;
                &:before{
                    background-image: url(./assets/images/table_edit_actives_ic.svg);
                }
            }
            &:before{
                content: '';
                display: inline-block;
                background-image: url(./assets/images/table_edit_ic.svg);
                background-size: 14px;
                background-position: center;
                background-repeat: no-repeat;
                height: 24px;
                width: 24px;
                margin-right: 2px;
                transition: background-image 0.3 ease;
    
            }
        }
    

    .dt-bootstrap-delete_ic {
        background: transparent;
        border: 0;
        padding: 0;
        color: #C5C5C5;
        transition: color 0.3s ease;
        font-family: $Font;
        font-size: 0.8rem;
        display: inline-flex;
        align-items: center;   
        &:focus{
            outline: 0;
        }     
        &:hover{
            color: #CE2E6C;
            &:before{
                background-image: url(./assets/images/table_delete_active_ic.svg); 
            }
        }
        &:before{
            content: '';
            display: inline-block;
            background-image: url(./assets/images/table_delete_ic.svg);
            background-size: 9px;
            background-position: center;
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            margin-right: 2px;
            transition: background-image 0.3 ease;

        }
    }

    .table_delete_ic {
        border: 0;
        background-color: transparent;
        color: #C5C5C5;
        padding: 5px 10px;
        font-family: $Font;
        font-size: 0.87rem;
        transition: color 0.3 ease;
        display: inline-flex;
        align-items: center;
        &:hover{
            color: #CE2E6C;
            &:before{
                background-image: url(./assets/images/table_delete_active_ic.svg); 
            }
        }
        &:focus{
            outline:0;
        }
        &:before{
            content: '';
            display: inline-block;
            background-image: url(./assets/images/table_delete_ic.svg);
            background-size: 10px;
            background-position: center;
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            margin-right: 2px;
            transition: background-image 0.3 ease;

        }
    }
}

.react-bootstrap-table-pagination {
    margin: 30px 0;
    .pagination{
        display: flex;
        align-items: center;
    }
    .page-item{
        .page-link{
            background-color: transparent !important;
            border-color: transparent !important;
            color:#CBCBCB;
            font-family: $Font;
            font-size: 1rem;
            padding: 5px 4px;
            &:focus{
                outline:0;
                box-shadow: none;
            }
            
        }
        &.active{
            .page-link{
                color:#8D8D8D;
                font-family: $Font;
                font-size: 1rem;
            }
        }
        &[title="next page"]{
            .page-link{
                background-image: url(./assets/images/datatable_pagination_ic.svg);
                background-size: 10px;
                background-position: center;
                background-repeat: no-repeat;
                height:30px;
                width:30px;
                text-indent: -9999px;
            }
        }
        &[title="previous page"]{
            .page-link{
                background-image: url(./assets/images/datatable_pagination_ic.svg);
                background-size: 10px;
                background-position: center;
                background-repeat: no-repeat;
                height:30px;
                width:30px;
                text-indent: -9999px;
                transform: rotate(180deg);
            }
        }
    }
  
}

.dt-table-search-wrap{
    display: flex;
    .dt-table-search {
        border:1px solid #D9D9D9;
        background: #fff;
        padding: 10px 20px;
        margin-bottom: 15px;
        border-radius: 25px;
        width: 100%;
        max-width: 300px;
        margin-left: auto;
        font-family: $Font;
        font-size: 1rem;
        color: #504658;
        transition: background 0.5s ease-in-out;
        @include placeholder {
            color: #D9D9D9;;
        }
        &:focus{
            outline:0;
        }
        
    }
}


// Datatable Css End



// react-select start

.react-select-wrapper{
    max-width: 400px;
    width: 100%;
    .css-2b097c-container .css-yk16xz-control {
        background-color: transparent;
        border: 1px solid #f3f3f3;
        border-radius: 25px;
        padding: 0 30px;
        min-height: 36px;
        background-image: url(./assets/images/select_arrow_ic.svg);
        background-size: 12px;
        background-position: center right 15px;
        background-repeat: no-repeat;
        // max-width: 200px;
        width: 100%;
        color: #504658;
        font-size: 0.85rem;
        font-family: "NunitoSans";
        margin: 20px 0;
        &:focus{
            outline:0;
            box-sizing: none;
        }
        .css-1hwfws3{
            padding: 0;
            .css-1wa3eu0-placeholder{
                color: #D9D9D9;
                margin: 0;
            }
        }
        .css-1hb7zxy-IndicatorsContainer {
            display: none;
        }
    }
} 
// react-select End




/**
   * Loader Css  Start  
   */

   .search-loader-position{
        position: absolute;
        top: 40px;
        height: calc(100% - 40px);
        width: 100%;
    }

    .serach-loader-wrapper_c {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .search-loader-inner_c{
        width: 30px;
        height: 30px;
        border-radius: 100%;
        position: relative;
        margin: 0 auto 1rem;
    }
    .search-loader-inner_c:before, .search-loader-inner_c:after{
        content: "";
        position: absolute;
        top: -3px;
        left: -3px;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: 3px solid transparent;
        border-top-color: #D12C6D;
    }
    
    .search-loader-inner_c:before{
        z-index: 100;
        animation : ezi-spin .6s infinite linear;
    }
    
    .search-loader-inner_c:after{
        border: 3px solid #C5C5C5;
    }
    
    @keyframes ezi-spin{  
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    
        100% {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }

// Loader Css  End  

 /**
 * Custom pagination css
 */

.pagination-wrap {
    display: flex;
    justify-content: center;
    padding: 25px 0;
    .pagination {
        margin: 0;
        padding: 0;
        display: inline-flex;
        align-items: center;
    }
    .page-item {
        list-style-type: none;
        &:last-child{
            .question-page-link{
                margin-right: 0;
            }

        }
        &.active{
            .question-page-link{
                color: #504658;
            }
        }
    }
    .page-link{
        height: 22px;
        width: 22px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #c2c2c2;
        font-family: $Font;
        font-size: 0.9rem;
        cursor: pointer;
        border-radius: 2px;
        margin-right: 2px;

    }
}
 /**
 * Custom pagination End
 */


/**
 * search comp0nent css start 
 */

 .sweet-search-table-header {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-bottom: 10px;
    .sweet-search-table-left span {
        font-family: $SemiBoldFont;
        font-size: 0.9rem;
        color: #1c1c1c;
    }
}

 .ezi-sweet-search-wrap{
    .sweet-search-input{
        border: 0;
        background: transparent;
        background-image: url(./assets/images/people-search.svg);
        background-size: 13px;
        background-position: top 11px right;
        background-repeat: no-repeat;
        width: 90px;
        transition: all 0.5s ease;
        min-height: 36px;
        padding-right: 20px;
        margin-right: 20px;
        &:focus{
            outline: 0;
            width: 200px;                    
            border-bottom: 1px solid #c5c5c5;
        }
        @include placeholder{
            color:#8D8D8D;
            font-size: 0.9rem;
            font-family: $ItalicFont;
        }
    }
    .sweet-search-spinner {
        width: 24px;
        height: 24px;
        border: 3px solid #CE2E6C;
        border-right-color: #b7b7b7;
    }

 }

/**
 * search comp0nent css End 
 */



/**
 * No Result svg wrap Start 
 */

 .no-result-svg-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    img{
        max-width: 370px;
    }
    .common-no-result-text{
        color: #CE2E6C;
        font-size: 1.4rem;
        margin: 15px 0;
    }
    .unauthorised-result-text{
        color: #CE2E6C;
        font-size: 1.1rem;
        margin: 15px 0;
        max-width: 250px;
        text-align: center;
    }
 }

/**
 * No Result svg wrap End 
 */


/**
 * react-confirm-alert Start 
 */

#react-confirm-alert {

    .react-confirm-alert {
        max-width: 410px;
        width: 100%;
        .react-confirm-alert-body {
            border: 0;
            background: #fff;
            border-radius: 24px;
            padding: 40px 25px;
            box-shadow: -3px 0 32px rgba(0, 0, 0, 0.1);
            font-family: $Font;
            font-weight: normal;
            font-size: 0.9rem;
            color:#1c1c1c;
            > h1 {
                color: #CE2E6C;
                font-family:$Font;
                font-weight: normal;
                font-size: 1.4rem;
                margin-bottom: 20px;
            }
            .react-confirm-alert-button-group{
                margin-top: 30px;
                button{
                    border: 0;
                    border-radius: 25px;
                    min-height: 32px;
                    height: 32px;
                    padding: 0 20px;
                    font-size: 0.85rem;
                    letter-spacing: 0.5px;
                    min-width: 80px;
                    &:first-child{                    
                        color: #fff;
                        background-color: #CE2E6C;
                    }
                    &:last-child{                    
                        background: transparent;
                        color: #B7B7B7;
                        margin-left: 10px;
                        padding: 0 10px;
                        min-width: unset;
                    }
                }
            } 

        }
    }
}

/**
 * react-confirm-alert End 
 */



 
/**
 * pagination plugin  css start
 */

 .pagination-plugin-wrap {
    display: flex;
    justify-content: center;
    .pagination {
        display: flex;
        margin-bottom: 25px;
        margin-top: 10px;
        li {
            list-style: none;
            padding: 0px 3px;
            a {
                color: #d1d1d1;
                font-size: 0.95rem;
            }
            &.active a{
                color: #8D8D8D;
            }
        }
    }
}


/**
 * pagination plugin  css End
 */


.ezi-right-modal-backdrop.modal-backdrop.show{
    background-color: #fff;
    opacity: 0.2;
}
 .ezi-right-animated-modal.modal.fade{
    .modal-dialog {
        -moz-box-shadow: -3px 0 32px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: -3px 0 32px rgba(0, 0, 0, 0.1);
        box-shadow: -3px 0 32px rgba(0, 0, 0, 0.1);
         transition: transform .3s ease-out;
         transform: translateX(100%);
         margin: 0 0 0 auto;
         height: 100%;
         .modal-content{
             border-radius: 0;
             border:0;
             height: 100%;
         }
     }
     &.show .modal-dialog {
         transform: none;
 
     }
  }

/*
* React Select plugin Css Start
*/

.ezi-select-plugin {

    .css-1wa3eu0-placeholder{
        color:#B7B7B7;
    }
   
    .css-1ht9tev-control{
        background-color:transparent ;
        .css-109onse-indicatorSeparator{
            display: none;
        }
    }

    .css-1okebmr-indicatorSeparator {
        display: none;
    }

    .css-yk16xz-control {
        border: 1px solid #e9e9e9;
        background-color: transparent;
        .css-1hwfws3:focus{
            outline: 0;
            box-shadow: none;
        }
    }

    .css-26l3qy-menu {
        box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
    }

    .css-4ljt47-MenuList {
        font-size: 0.85rem;
        font-family: $Font;
        .css-1n7v3ny-option , .css-yt9ioa-option , .css-9gakcf-option {
            background: transparent;
            color: #1c1c1c;
            &:hover{
                color: #CE2E6C;
                cursor: pointer;
                background-color: #FBFBFB;
            }
        }
    }


}

/*
* React Select plugin Css End
*/



/*
* Html Editor placeholder Css Start
*/

    .ezi-editor-wrapper{

        .rdw-dropdown-wrapper{
            &:hover,&:active{
                box-shadow: none !important;
            }
    
            .rdw-dropdown-selectedtext {
                color: #1c1c1c;
                font-size: 0.85rem;
            }
    
            .rdw-dropdown-carettoopen{
                border-top: 5px solid #b7b7b7;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }
    
            .rdw-dropdown-carettoclose {
                border-bottom: 5px solid #b7b7b7;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }
    
            .rdw-dropdown-optionwrapper{
                width: 100%;
                border-color: transparent;
                transition: max-height 0.3s ease;
                margin-top: 2px;
                &:hover{
                    box-shadow: none;
                    background-color: #fff;
                }
                &::-webkit-scrollbar{
                    width:3px;
                }
                &.close-placeholder-ul {
                    max-height: 140px;
                }
                &.placeholder-ul{
                    max-height: 0;
                }
            }
            .rdw-dropdownoption-default {
                color: #b7b7b7;
                font-size: 0.78rem;
                padding: 0 7px;
                margin: 0;
                &:hover{
                    color: #CE2E6C;
                    cursor: pointer;
                    background-color: #FBFBFB;
                }
            }            
           
        }
        .rdw-option-wrapper{
            height: 22px;
            min-width: 28px;
            border: 0;
            background: transparent;
            img{
                -webkit-filter: grayscale(100%) brightness(19%) contrast(1) invert(0.6);
                filter: grayscale(100%) brightness(19%) contrast(1) invert(0.6);
            }
        }

    }

   

/*
* Html Editor placeholder Css End
*/


  .tab-working-tooltip{
    display: block;
  }



  // page theme ma[pping css start
  .ezi-right-animated-modal.theme-mapping-right-modal{
    //   overflow-y: hidden !important;
    .modal-body{
        padding: 0;
         height: calc(100% - 61px);
    }
    .Page-ThemeMappingModal{
        height: 100%;
    }
    .question-wrapper-section{
        height: 100%;      
    }
  }

.Page-ThemeMappingModal{ 

    .questions-header {
        display: flex;
        align-items: center;
        padding: 20px 30px;
        border-bottom: 1px solid #E9E9E9;
        max-height: 71px;
    }
    .que-mapping-body{
        height: calc(100% - 61px);
        overflow-y: auto; 
        padding-top: 20px;
        &::-webkit-scrollbar{
            height:8px;
            width: 3px;
        }
    }
    .question-heading{
        color:#CE2E6C;
        font-family: $SemiBoldFont;
        font-size: 1rem;
        display: inline-flex;
    }
    .question-theme-select-wrap {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
    .question-theme-select{
        margin-right: 10px;
    }
    .question-theme-select,.question-subtheme-select {
        -moz-appearance: none;
        -webkit-appearance: none;
        background: transparent;
        border-radius: 6px;
        padding: 0 35px 0 15px;
        min-height: 34px;
        height: 34px;
        border: 1px solid #C2C2C2;
        color: #5A4E63;
        font-family: "NunitoSans";
        font-size: 0.8rem;
        max-width: 160px;
        width: 100%;
        min-width: 110px;
        background-image: url(./assets/images/dropdown-arrow_c2.svg);
        background-size: 12px;
        background-position: center right 15px;
        background-repeat: no-repeat;
        &:focus{
            outline:0;
        }
    }
    .question-save-wrap{
        margin-left: auto;
        .question-save{
            padding: 0 30px;
            margin-right: 12px;
        }
        .question-reset{
            background-color: transparent;
            border:0;
            color:#B7B7B7;
            font-family: $Font;
            font-size: 0.8rem;
        }
    }
    .question-wrapper-section {
        background-color: #fff;
        border-radius: 5px;
        -moz-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.16);
        box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.16);
        padding-bottom: 30px;
        
    }
    .question-wrapper {
        display: flex;
        align-items: center;
        padding: 10px 20px;
    }
    .question-list{
        margin:0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 20px;
        color:#5A4E63;
        width: 100%;
        font-family: $Font;
        font-size: 1rem;
    }

  
}
// page theme mapping css End



/*
*  Sweet ezi tab Css Start 
*/

.ezi-sweet-tab {
    .nav.nav-pills {
        .nav-item{
            position: relative;
            &:after{
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                border-style: solid;
                border-width: 0 30px 40px 0;
                border-color: transparent #F2F5F8 transparent transparent;
            }
        }
        .nav-link {
            background: #F9F9F9;
            color: #B7B7B7;
            padding: 12px 80px 12px 50px;
            font-family: $SemiBoldFont;
            font-size: 0.85rem;
            border-radius: 8px 0 0 0;
            &.active{
                background: #fff;
                color: #CE2E6C;
            }
        }
    }
    .react-bootstrap-table{
        box-shadow: none;
    }
}

.global-checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
    color: #DBDBDB;
    font-size: 13px;
}
.global-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.global-checkbox .checkmark {
    position: absolute;
    top: 48%;
    left: 3px;
    height: 18px;
    width: 19px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    transform: translateY(-50%);
}

.global-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.global-checkbox input:checked ~ .checkmark:after {
    background: url(./assets/images/check.png);
    background-size: 21px;
    background-position: bottom;
    background-repeat: no-repeat;
}
.global-checkbox .checkmark:after {
    left: -2px;
    top: -16px;
    width: 30px;
    height: 30px;
}

// Sweet ezi tab Css End 




/*
 * Sweet Datepicker Css Start 
 */

.sweet-date-picker{
    z-index: 9999;
    .react-calendar{
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        margin-top: 10px;
        padding: 0 10px;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.09);
    }
    .react-calendar__navigation{
        .react-calendar__navigation__label{
            color: #504658;
            font-family: $SemiBoldFont;
            font-size: 0.95rem;
        }
    }
    .react-calendar__tile--hasActive {
        background: rgba(206,46,108,0.20);
    }
    .react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--hasActive:enabled:focus{
        background: rgba(206,46,108,0.20);
    }
    .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
        background: rgba(206,46,108,0.20);
        color: #212529;
    }
    .react-calendar__tile--hasActive.react-calendar__tile--rangeEnd{
        background: rgba(206,46,108,0.20);
        border-radius: 0 25px 25px 0;
    }
    .react-calendar__tile--rangeEnd.react-calendar__tile--hasActive:enabled:hover, .react-calendar__tile--rangeEnd.react-calendar__tile--hasActive:enabled:focus {
        background: rgba(206,46,108,0.20);
        border-radius: 0 25px 25px 0;
    }
    .react-calendar__tile--hasActive.react-calendar__tile--rangeStart{
          border-radius: 25px 0 0 25px ;
    }
    .react-calendar__month-view__weekdays{      
        .react-calendar__month-view__weekdays__weekday{
            color: #504658;
            font-family: $BoldFont;
            font-weight: normal;
            font-size: 0.75rem;
            abbr{
                text-decoration: none;
            }
        }
       
    }
    .react-calendar__month-view__days{
        font-family: $Font;
        font-weight: normal;
        font-size: 0.85rem;
    }
    .react-calendar__tile {
        margin-bottom: 3px;
        
    }

    .react-calendar__tile--active{
        background: #CE2E6C;
        color: #fff;
        &.react-calendar__tile--rangeStart{
            border-radius: 25px 0 0 25px;
            background: rgba(206,46,108,0.20);
            color: #212529;
        }
        &.react-calendar__tile--rangeEnd{
            border-radius: 0 25px 25px 0;
            background: rgba(206,46,108,0.20);
            color: #212529;
        }
        &.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd{
            border-radius: 30px;
        }

    }
   
}

// Sweet Datepicker Css End 


.select2-container--classic.select2-container--open .select2-dropdown{
    border-color:#e9e9e9 !important;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #e1e1e1 !important;
    border-radius: 5px !important;
}

.ezi-survey-header{
    display: block;
    text-align: center;
    color: #E54885;
    background-color: #FFE4EE;
    padding: 4px 20px;
    font-family: $SemiBoldFont;
    font-size: 1.2rem;
    border-radius: 3px;
    margin-bottom: 20px;
}

.prompt-alert{
    .ezi-prompt-input {
        width: 100%;
        border:0;
        border-bottom: 1px solid #b7b7b7;
        margin-bottom: 30px;
        font-size: 1rem;
        color:#504658;
        &:focus{
            outline: 0;
        }
    }
}



.ezi-switch-toggle {
    position: relative;
    display: inline-block;
    width: 66px;
    height: 32px;
    border: 1px solid #E9E9E9;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    &.on{
        background-color: #CE2E6C;
        border-color:#CE2E6C; ;
    }
    &.off,
    &.on {
      border-radius: 25px;
      padding: 5px;
      cursor: pointer;
    }
    &.off:before,
    &.off:after {
      -webkit-transition: 0.6s;
      transition: all 0.6s;
    }
    &.on:before,
    &.on:after {
      -webkit-transition: 0.6s;
      transition: all 0.6s;
    }

    &.off:before,
    &.on:before {
        display: block;
        position: absolute;
        content: "";
        height: 23px;
        width: 23px;
        left: 4px;
        top: 3px;
        background-color: #DBDBDB;
        -webkit-transition: 0.6s;
        transition: all 0.6s;
        -webkit-transition-delay: 0.01s;
        transition-delay: 0.01s;
        -moz-box-shadow: inset -8px -8px 6px -6px #DBDBDB;
        -webkit-box-shadow: inset -8px -8px 6px -6px #DBDBDB;
        border-radius: 50%;
        box-shadow: inset -8px -8px 6px -6px #DBDBDB;
    }

    &.off:after {
        display: block;
        position: absolute;
        content: "";
        -o-transform: rotate(-270deg);
        -webkit-transform: rotate(-270deg);
        -ms-transform: rotate(-270deg);
        transform: rotate(-270deg);
        left: 17px;
        top: 15px;
      }
      
      &.on:before {
        -webkit-transform: translateX(32px);
        -ms-transform: translateX(32px);
        transform: translateX(32px);
        background-color: #fff;
        -moz-box-shadow: inset -8px -8px 6px -6px #fff;
        -webkit-box-shadow: inset -8px -8px 6px -6px #fff;
        box-shadow: inset -8px -8px 6px -6px #fff;
      }
      
      &.on:after {
        position: absolute;
        content: "";
        -o-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        left: 56px;
        top: 15px;
      }


  }
  
  .active-survey-datepicker {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}
.survey_extend_date_err {
    font-size: 0.9em;
    color: red;
    text-align: center;
    margin-bottom: 6px;
}
.dynamic-participant-download{
        height: 30px;
        width: 30px;
        min-width: 30px;
        background-image: url(./assets/images/report/survey-analysis.svg);
        background-color: transparent;
        background-size: 26px;
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        position: absolute;
        right: 30px;
        top: 28px;
        z-index: 999;
        text-indent: -999px;
        cursor: pointer;
}
.response-participant-download{
        height: 30px;
        width: 30px;
        min-width: 30px;
        background-image: url(./assets/images/report/survey-analysis.svg);
        background-color: transparent;
        background-size: 26px;
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        position: absolute;
        right: 152px;
        top: 11px;
        z-index: 999;
        text-indent: -999px;
        cursor: pointer;
}
.dropzone-wrap.survey-dropzone-wrap {
    position: relative;
}
.dropzone-wrap {
    .dropzone-area {
        background: #fff;
        margin-top: 40px;
        min-height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: $ReportWidgetRadius;
        &:focus {
            outline: 0;
        }
        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            border: 1px dashed #707070;
            height: calc(100% - 40px);
            width: calc(100% - 40px);
            transform: translate(-50%, -50%);
            border-radius: $ReportWidgetRadius;
        }
        .dropzone-content {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            .drag-text {
                color: #b7b7b7;
                font-family: $SemiBoldFont;
                font-size: 1.1rem;
                padding: 0 30px;
                &.active {
                    color: #1c1c1c;
                    font-family: $Font;
                }
            }
        }
        .dropzone-icon {
            background-image: url(./assets/images/dropzone_icon.svg);
            background-size: 26px;
            background-position: center;
            background-repeat: no-repeat;
            height: 50px;
            width: 50px;
            display: inline-block;
        }
    }
}
.dropzone-supported-file {
	color: #b7b7b7;
	font-size: 0.7rem;
	font-family: "NunitoSans";
	display: block;
	text-align: center;
	margin: 10px 0;
}
.dropzone-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 50px 0 0;
	.dropzone-area {
		display: inline-flex;
		align-items: center;
		background-color: transparent;
		border-radius: 25px;
		font-size: 1rem;
		color: #fff;
		font-family: $Font;
		position: relative;
		padding: 8px 30px;
		min-width: 180px;
		max-width: 180px;
		.drag-text {
			display: block;
			text-indent: -9999px;
		}
		&:focus {
			outline: 0;
		}
	}
	.upload-btn {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background-color: $ThemeColor;
		border-radius: 25px;
		font-size: 0.9rem;
		color: #fff;
		height: 40px;
		padding: 0 30px;
		font-family: $Font;
		border: 0;
		&:after {
			content: "";
			background-image: url(./assets/images/dropzone_btn_icon.png);
			background-size: 14px;
			background-position: right top 6px;
			background-repeat: no-repeat;
			height: 30px;
			width: 30px;
			display: inline-block;
		}
		&:focus {
			outline: 0;
		}
	}
}
  
/*
 *  React Select Search Css Start 
 */

 .react-select-search_c {
    .select-search{
        width: 220px;
    }
    .select-search__input {
        box-shadow: none;
        border: 1px solid #e9e9e9;
        padding-right: 30px;
    }
    .select-search:not(.select-search--multiple) .select-search__input:hover {
        border-color: #e9e9e9;
    }
    .select-search__value::after {
        content: '';
        right: 15px;
        width: 9px;
        height: 9px;
        border-color: #5A4E63 !important;
    }
    .select-search__options {
        padding: 0;
        margin: 0;
    }
    button.select-search__option {
        height: initial;
        padding: 10px 15px;
    }
    .select-search__option.is-highlighted, .select-search__option:not(.is-selected):hover{
        background:#f2f5f8;
    }
    .select-search__option.is-selected {
        background: #CE2E6C;
        color: #FFF;
    }
    .select-search__option.is-highlighted.is-selected, .select-search__option.is-selected:hover{
        background: #CE2E6C;
        color: #FFF;
    }
}
/*
 *  React Select Search Css End 
 */

 /* Editor type custom widget hide*/
.svd_container .svd_content .svd_survey_designer .svd_toolbox{
    .svd_toolbox_item.svd_toolbox_item_icon-editor_custom{
        display: none !important;
    }
}

  
  
  
  
  
  
    