@import "./variable";
@import "./placeholder";

.category-search{
    position: relative;
    margin: 10px 0;
    .category-dropdown-btn{
        border:0;
        color:#504658;
        font-family: $Font;
        font-size: 1.3rem;
        background-color: transparent;
        background-image: url(../../assets/images/themes-dropdown.svg);
        background-size: 16px;
        background-position: right;
        background-repeat: no-repeat;
        padding: 0 35px 0 0;
        &:focus{
            outline: 0;
        }
        &.selected{
            color : #CE2E6C;
            background-image: url(../../assets/images/show-entry_ic.svg);
        }
    }
    .category-inner-dropdown{
        display: none;
        background-color: #fff;
        border-radius: 20px;
        padding: 0;
        max-width: 275px;
        width: 100%;
        min-height: 280px;
        flex-direction: column;
        overflow: hidden;
        position: absolute;
        z-index: 9999;
        top: 100%;
        left: 0;
        margin-top: 8px;
        -moz-box-shadow: 4px 7px 23px rgba(0,0,0,0.11); 
        -webkit-box-shadow: 4px 7px 23px rgba(0,0,0,0.11); 
        box-shadow:4px 7px 23px rgba(0,0,0,0.11);
        &.active{
            display: inline-flex;        
        }
        .category-dropdown-header {
            position: relative;
            border-bottom: 1px solid #E9E9E9;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-between;
            label{
                margin: 0;
                font-family: $SemiBoldFont;
                color:#E9E9E9;
                font-size: 0.8rem;
                min-height: 40px;
                line-height: 40px;
                padding-left: 30px;
                white-space: nowrap;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .category-dropdown-search{
            border:0;
            font-family: $Font;
            color:#1C1C1C;
            font-size: 0.8rem;
            background-image: url(../../assets/images/theme-drop-search.svg);
            background-size: 12px;
            background-position: top 13px right 30px;
            background-repeat: no-repeat;
            padding: 0 55px 0 0;
            width: 100%;
            max-width: 100px;
            margin-left: auto;
            min-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: max-width 0.3s ease;           

            @include placeholder {
                color: #E9E9E9;
                font-family: $ItalicFont;
            }
            &:focus{
                outline:0;
                background-color: #FBFBFB;
                max-width: 100%;
                padding-left: 30px;
                + label{
                    display: none;
                }
            }
        }
        ul {
            padding: 12px 0 15px;
            max-height: 270px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 4px;
            }
            li {
                list-style-type: none;
                padding: 6px 30px;
                font-family: $Font;
                color:#B7B7B7;
                transition: color 0.3 ease;
                position: relative;
                font-size: 0.82rem;
                text-transform: capitalize;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &:hover{
                    color:#CE2E6C;
                    cursor: pointer;
                    background-color: #FBFBFB;
                    &:after{
                        content: '';
                            width:3px;
                            height:100%;
                            position: absolute;
                            left:0;
                            top:0;
                            background-color:#CE2E6C;
                    }
                    
                }

            }
        }
    }
}