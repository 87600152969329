@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";
.Page-QuestionBank{
    padding: 40px 7%;
    overflow: hidden;
    .themes-subtitle{
        color: #B7B7B7;
        font-family: $Font;
        font-size: 0.9rem;
        margin: 10px 0;
        display: block;
    }
    .themes-dropdown{
        position: relative;
        margin: 10px 0;
    }
    .themes-dropdown-btn{
        border:0;
        color:#504658;
        font-family: $Font;
        font-size: 1.3rem;
        background-color: transparent;
        background-image: url(../../assets/images/themes-dropdown.svg);
        background-size: 16px;
        background-position: right;
        background-repeat: no-repeat;
        padding: 0 35px 0 0;
        
        &:focus{
            outline: 0;
        }
        &.selected{
            color : #CE2E6C;
            background-image: url(../../assets/images/show-entry_ic.svg);
        }

    }
    .themes-inner-dropdown{
        display: none;
        background-color: #fff;
        border-radius: 20px;
        padding: 0;
        max-width: 275px;
        width: 100%;
        min-height: 250px;
        flex-direction: column;
        overflow: hidden;
        position: absolute;
        z-index: 9999;
        top: 100%;
        left: 0;
        margin-top: 8px;
        -moz-box-shadow: 4px 7px 23px rgba(0,0,0,0.11); 
        -webkit-box-shadow: 4px 7px 23px rgba(0,0,0,0.11); 
        box-shadow:4px 7px 23px rgba(0,0,0,0.11);
        &.active{
            display: inline-flex;        
        }
        .themes-dropdown-header {
            position: relative;
            border-bottom: 1px solid #E9E9E9;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-between;
            label{
                margin: 0;
                font-family: $SemiBoldFont;
                color:#E9E9E9;
                font-size: 0.8rem;
                min-height: 40px;
                line-height: 40px;
                padding-left: 30px;
                white-space: nowrap;
                width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .themes-dropdown-search{
            border:0;
            font-family: $Font;
            color:#1C1C1C;
            font-size: 0.8rem;
            background-image: url(../../assets/images/theme-drop-search.svg);
            background-size: 12px;
            background-position: top 13px right 30px;
            background-repeat: no-repeat;
            padding: 0 55px 0 0;
            width: 100%;
            max-width: 100px;
            margin-left: auto;
            min-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: max-width 0.3s ease;           

            @include placeholder {
                color: #E9E9E9;
                font-family: $ItalicFont;
            }
            &:focus{
                outline:0;
                background-color: #FBFBFB;
                max-width: 100%;
                padding-left: 30px;
                + label{
                    display: none;
                }
            }
        }
        ul {
            padding: 12px 0 15px;
            max-height: 270px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 4px;
            }
            li {
                list-style-type: none;
                padding: 6px 30px;
                font-family: $Font;
                color:#B7B7B7;
                transition: color 0.3 ease;
                position: relative;
                font-size: 0.82rem;
                text-transform: capitalize;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &:hover{
                    color:#CE2E6C;
                    cursor: pointer;
                    background-color: #FBFBFB;
                    &:after{
                        content: '';
                            width:3px;
                            height:100%;
                            position: absolute;
                            left:0;
                            top:0;
                            background-color:#CE2E6C;
                    }
                    
                }

            }
        }
    }

    .react-bootstrap-table .table tbody tr td:hover:after{
        display: none;
    }

    .themes-status_c {
        text-transform: capitalize;
        display: inline-flex;
        align-items: center;
        color: #1C1C1C;
        font-family: $SemiBoldFont;
        font-size: 0.88rem;
        white-space: nowrap;
        margin-right: 20px;
        &:before {
            content: '';
            height: 8px;
            width: 8px;
            min-width: 8px;
            display: inline-flex;
            align-items: center;
            border-radius: 50%;
            margin-right: 6px;
        }
        &.active:before {            
            background: #B5EE5F;
        }
        &.offline:before {
            background: #FF1616;
        }
    }

    .theme-name_c{
        color: #1C1C1C;
        font-family: $SemiBoldFont;
        font-size: 0.88rem;
        white-space: nowrap;
        text-transform: capitalize;
        &:hover{
            color:#CE2E6C;
            cursor: pointer;
        }
    }

    .react-bootstrap-table .table tbody tr td{
        white-space: normal;
    }

    .add-theme-btn {
        margin-right: 30px;
    }

    .theme-editDelete-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        .theme-delete {
            background: transparent;
            border: 0;
            padding: 0;
            color: #C5C5C5;
            transition: color 0.3s ease;
            font-family: $Font;
            font-size: 0.8rem;
            display: inline-flex;
            align-items: center;   
            &:focus{
                outline: 0;
            }     
            &:hover{
                color: #CE2E6C;
                &:before{
                    background-image: url(../../assets/images/table_delete_active_ic.svg); 
                }
            }
            &:before{
                content: '';
                display: inline-block;
                background-image: url(../../assets/images/table_delete_ic.svg);
                background-size: 9px;
                background-position: center;
                background-repeat: no-repeat;
                height: 24px;
                width: 24px;
                margin-right: 2px;
                transition: background-image 0.3 ease;
    
            }
        }
        .theme-edit {
            background: transparent;
            border: 0;
            padding: 0;
            margin-right: 15px;
            color: #C5C5C5;
            transition: color 0.3s ease;
            font-family: $Font;
            font-size: 0.8rem;
            display: inline-flex;
            align-items: center; 
            &:focus{
                outline: 0;
            }
            &:hover{
                color: #CE2E6C;
                &:before{
                    background-image: url(../../assets/images/table_edit_actives_ic.svg);
                }
            }
            &:before{
                content: '';
                display: inline-block;
                background-image: url(../../assets/images/table_edit_ic.svg);
                background-size: 14px;
                background-position: center;
                background-repeat: no-repeat;
                height: 24px;
                width: 24px;
                margin-right: 2px;
                transition: background-image 0.3 ease;
    
            }
        }
    }

    .react-bootstrap-table{
        .theme-datatable-row{
            .theme-des{
                font-size: 0.84rem;
            }
            .theme-editDelete-wrap{
                visibility: hidden;
                margin-right: 30px;
            }
            &:hover{
                .theme-editDelete-wrap{
                    visibility: visible;
                }
            }                     
        }
    }
    .react-bootstrap-table-pagination{
        li.page-item {
            border: 1px solid #504658;
            margin: 0 4px;
            border-radius: 3px;
            min-width: 26px;
            height: 26px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            
          }
          li.active.page-item {
            background-color: #504658 !important;
            color: #fff;
            .page-link{
                color: #fff;
            }
          }
          .page-item[title="previous page"] .page-link, .page-item[title="next page"] .page-link{
            background-size: 8px;
            height: 25px;
            width: 25px;
          }
    }
    
    .add-theme-btn-wrap {
        display: flex;
        justify-content: center;
    }
    

    .ezi-sweet-tab{

        .tab-content {
            padding: 40px 0;
            background-color: #fff;
        }

    }
    
    
    

}


.add-theme-form{
    display: flex;
    flex-direction: column;
    .close-theme-btn {
        background: transparent;
        border: 0;
        padding: 4px 10px;
        margin: 0;
        margin-right: 10px;
        color: #1C1C1C;
        font-family: $SemiBoldFont;
        font-size: 0.8rem;    
        &:focus{
            outline: 0;
        }
    }
    .add-theme-btn {
        padding: 6px 30px;
        font-family: $SemiBoldFont;
        font-size: 0.8rem;  
    }
    .theme-modal-footer {
        display: inline-flex;
        align-items: center;
        margin-left: auto;
        margin-bottom: 12px;
    }
}

.theme-modal-title {
    color: #CE2E6C;
    font-family: $SemiBoldFont;
    font-size: 1.1rem !important;
}
.theme-field-wrapper {
    padding: 10px 0;
}
.theme-modal-wrapper{

    .theme-field-control {
        flex: 1;
        margin-bottom: 20px;
        border-radius: 5px;
        border: 1px solid #B7B7B7;
        margin: 0 10px 20px;
        min-height: 38px;
        padding: 0 10px;
        color: #707070;
        font-family: $Font;
        font-size: 0.88rem; 
        resize: none; 
        &:focus{
            outline: 0;
        }
        @include placeholder {
            color: #D9D9D9;;
        }
    }
    
    .theme-field {
        display: flex;
        flex:1;
        flex-direction: column;
    }
    
    .theme-field-50 {
        display: flex;
        flex-wrap: wrap;
        align-items:flex-start;
    }
    
    .theme-field label {
        flex: 1;
        padding: 0 10px;
        color: #B7B7B7;
        font-family: $Font;
        font-size: 0.9rem;
    }
    
    .theme-error_cu{
        color:#ff0000;
        font-family: $SemiBoldFont;
        font-size: 0.9rem;
        margin-bottom: 10px;
        padding-left: 10px;
    }

    .ezi-modal-header{
        border-bottom: 1px solid #E9E9E9;
        .ezi-modal-header-title {
            display: flex;
            width: 100%;
        }
        .ezi-modal-close{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;
            background: #F2F5F8;
            border-radius: 50%;
            margin-left: auto;
            background-image: url(../../assets/images/modal_close_ic.svg);
            background-size: 11px;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }
     


}
 
