.st_heading {
    color: #504658;
    font-size: 1.4rem;
    font-family: $SemiBoldFont;
    margin-bottom: 30px;
}

.st_card-wrap {
    margin: 20px -15px 0;
    display: flex;
    flex-wrap: wrap;
}
.st_card {
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    padding: 20px;
    -moz-box-shadow: -1px 7px 60px rgba(0,0,0,.08);
    -webkit-box-shadow: -1px 7px 60px rgba(0,0,0,.08);
    box-shadow: -1px 7px 60px rgba(0,0,0,.08);
    border-radius: 9px;
    margin: 0 15px 15px;
    flex:1;
    cursor: pointer;
}
.st_card-icon-wrap {
    display: inline-flex;       
    height: 60px;
    width: 60px;
    min-width: 60px;
    border-radius: 50%;
    margin-right: 20px;
}
.st_card-icon{
    height: 100%;
    width: 100%;
    background-size:20px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    &.template_ic{
        background-image: url(../images/template/template.png); 
        background-color: #F0DECB;           
    }
    &.publish_ic{
        background-image: url(../images/template/publish.png);
        background-color: #F4D1DF;
    }
    &.question_ic{
        background-image: url(../images/template/question.png);
        background-color: #E2EBF1;
        background-size: 24px;
    }
    &.closed_ic{
        background-image: url(../images/dashboard/closed_ic.svg);
        background-color: #FEE4E4;
        background-size: 25px;
    }
}
.st_card-text-wrap {
    display: inline-flex;
    flex-direction: column;
}
.st_card-text{
    color:#B7B7B7;
    font-size: 0.85rem;
    margin-bottom: 2px;
}
.st_count{
    color:#5A4E63;
    font-family: $SemiBoldFont;
    font-size: 1.4rem;
    &.st_active{
        color:#CE2E6C;
    }
    &.st_inactive{
        color:#165888;
    }
}
.st_card-content-wrap{
    display: inline-flex;
    flex-direction: column;
    margin: 0 20px;
}

.st_action-card {
    background: #fff;
    box-shadow: -1px 7px 91px rgba(0,0,0,0.11);
    display: inline-flex;
    margin: 0 15px 20px;
    border-radius: 10px;
    min-height: 150px;       
    display: inline-flex;
    align-items: center;
    padding: 20px; 
    flex:1;
    cursor: pointer;
    label {
        margin: 0;
        max-width: 135px;
        line-height: 26px;
        font-size: 1rem;
        font-family: "NunitoSansSemiBold";
        pointer-events: none;
    }
    .st_action-icon-wrap {
        display: inline-flex;
        height: 70px;
        width: 70px;
        min-width: 70px;
        border-radius: 50%;
        margin-right: 20px;
    }
    .st_action-icon{
        height: 100%;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
    }
    &.create-card{
        background-color: #5A4E63;
        .st_action-icon{
            background-image: url(../images/template/plus.png);
            background-color: #6A5D74;
            background-size: 34px;

        }
        label{                
            color: #fff;
        }
    }
    &.existing-card{
        .st_action-icon{
            background-size: 25px;
            background-image: url(../images/template/template.png);
            background-color: #F0DECB;  
        }
        label{                
            color: #1C1C1C;
        }
    }

}
.recent-st_card-body{
    overflow-y: auto;
    margin: 20px 20px;
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #b7b7b7;
    }
    &::-webkit-scrollbar-track {
        background-color: #E9E9E9;
    }
    ul{
        margin: 0;
        padding: 0;
        li{
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding-right: 20px;
            float: unset;
            opacity: initial;
            font-weight: normal;
            text-shadow: none;
            line-height: initial;
            &.close:hover{
                opacity: initial;
            }
        }
    }
    .recent-st_index{
        height: 26px;
        width: 26px;
        min-width: 26px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #1C1C1C;
        background-color: #F2F5F8;
        margin-right:15px;
        font-size: 0.8rem;
    }
    .recent-st_name {
        color: #1C1C1C;
        font-family: $SemiBoldFont;
        font-size: 0.87rem;
        width: 100%;
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .recent-st_status {
        // border: 1px solid #E9E9E9;
        border-radius: 25px;
        padding: 6px 20px 6px 15px;
        margin: 0 15px;
        font-size: 0.85rem;
        font-family: $Font;
        display: inline-flex;
        align-items: center;
        color: #8e7d9c;
        &:before{
            content: '';
            height: 8px;
            width: 8px;
            display: inline-block;
            border-radius: 50%;
            margin-right: 10px;
        }
        &.published{
            // color:#504658;
            &:before{
                background: #B5EE5F;
            }
        }
        &.unpublished{
            // color:#FF6161;
            &:before{
                background: #FF6161;
            }
        }
        &.draft{
            // color:#B7B7B7;
            &:before{
                background: #C5C5C5;
            }
        }
    }
    .recent-st_report {
        background: transparent;
        border: 1px solid #E9E9E9;
        border-radius: 25px;
        padding: 6px 24px;
        color: #C5C5C5;
        font-size: 0.85rem;
        font-family: $Font;
        white-space: nowrap;
        transition: all 0.3s ease ;
        &:focus{
            outline:0;
        }
        &:hover{
            background-color: #E9E9E9;
            color: #656565;
        }
    }
}

.recent-st_card {
    display:flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 9px;
    height: calc(100% - 174px);
    box-shadow: -1px 7px 60px rgba(0,0,0,0.08);
    position: relative;
}
.st_create-exsting-wrap {
    display: flex;
    flex-direction: column;
    width: calc(60% - 15px);
    margin: 10px 0 0;
    min-height: 500px;
    max-height: 500px;
}
.st_action-card-wrap {
    margin: 0px -15px 5px;
    display: flex;
    flex-wrap: wrap;
}
.recent-st_card-header {
    border-bottom: 1px solid #E8E9EC;
    padding: 10px 20px;
    color: #5A4E63;
    text-transform: capitalize;
}

.highlight-card{
    display:flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 18px;
    width: calc(40% - 15px);
    margin: 10px 0 0;   
    box-shadow: -1px 7px 91px rgba(0,0,0,0.11);
    min-height: 500px;
    max-height: 500px;
}
.action-hightlight-card-wrap{
    display: flex;
    height: 100%;
    min-height: 500px;
    max-height: 500px;
    // margin: 0 -15px;
    justify-content: space-between;
}
.highlight-card-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 25px;
    border-bottom: 1px solid #E8E9EC;
    .highlight-header-heading {
        margin: 0;
        color: #5A4E63;
        font-size: 1rem;
    }
}
.highlight-clear-btn {
    padding: 0;
    border: 0;
    background: transparent;
    color: #B7B7B7;
    font-size: 0.8rem;
}

.highlight-card-body {
    overflow-y: auto;
    margin: 20px 20px;
    position: relative;
    height:100%;
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #b7b7b7;
    }
    &::-webkit-scrollbar-track {
        background-color: #E9E9E9;
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            display: flex;
            align-items: center;
            padding-right: 10px;
            margin-bottom: 15px;
        }
    }
    .highlight-card-msg-icon {
        height: 26px;
        width: 26px;
        min-width: 26px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #fff;
        text-indent: -9999px;
        background-size:24px;
        background-position: top 1px center;
        background-repeat: no-repeat;
        margin-right: 15px;
        &.hightlight-success{
                background-image: url(../images/template/highlight-success.svg);
                background-color: #B5EE5F;                
                background-position: top 1px left 1px;           
        }
        &.hightlight-info{               
                background-image: url(../images/template/highlight-info.svg);                
                background-color: #0086F2;
        }
        &.hightlight-warning{             
                background-image: url(../images/template/highlight-warning.svg);
                background-color: #FFCF46;        }
        &.hightlight-error{              
                background-image: url(../images/template/highlight-error.svg);
                background-color: #FF5054;
        }
    }
    .highlight-des-wrap{
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        .highlight-card-des {
            color: #4D4F5C;
            font-size: 0.9rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }        
        .highlight-card-time {
            color: #A3A4AB;
            font-size: 0.75rem;
        }
    }
    .no-activity {
        color: #b7b7b7;
        display: block;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        font-size: 0.85rem;
    }
}


.recent-surveys-status_st {
    border-radius: 25px;
    padding: 6px 20px 6px 15px;
    margin: 0 15px;
    font-size: 0.85rem;
    font-family: $SemiBoldFont;
    display: inline-flex;
    align-items: center;
    min-width: 100px;
    text-transform: capitalize;
    float: unset;
    font-weight: normal;
    line-height: initial;
    text-shadow: none;
    opacity: 1;
    color: #8e7d9c;
    &:before{
        content: '';
        height: 8px;
        width: 8px;
        min-width: 8px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 10px;
    }
    &.active{
        // color:#504658;
        &:before{
            background: #B5EE5F;
        }
    }
    &.inactive{
        // color: #8e7d9c;
        &:before{
            background: #504658;
        }
    }
    &.close{
        //  color:#FF6161;
        &:hover{
            opacity: initial !important;
            color: #8e7d9c;
        }
        &:before{
            background: #FF6161;
        }
    }
    &.draft{
        // color:#B7B7B7;
        &:before{
            background: #C5C5C5;
        }
    }
}


@media (max-width:1000px) {
    .action-hightlight-card-wrap{
        flex-direction: column;
        margin: 0;
        
    }
    .st_create-exsting-wrap{
        width:100%;
        margin: 10px 0 0;
        min-height: initial;
        max-height: initial;
    }
    .recent-st_card{
        height: 330px;
    }
    .highlight-card{
        width:100%;
        margin: 25px 0 0;
    }
}
