
.modal-backdrop.ezi-alert-backdrop{
        background-color: #fff;
        &.show{
            opacity: 0.7;
        }
}

.ezi-alert-modal{

    .modal-dialog{
        max-width: 410px;
    }

    .modal-content{
        border: 0;
        background: #fff;
        border-radius: 24px;
        padding: 12px 25px 20px;
        -moz-box-shadow: -3px 0 32px rgba(0,0,0,0.10);
        -webkit-box-shadow: -3px 0 32px rgba(0,0,0,0.10);
        box-shadow: -3px 0 32px rgba(0,0,0,0.10);
    }
    .ezi-alert-text {
        color: #1c1c1c;
        font-size: 1.05rem;
        text-align: center;
        display: block;
        width: 100%;
        padding: 20px 0 30px;
        max-width: 230px;
        line-height: 30px;
        margin: 0 auto;
        font-family: "NunitoSansSemiBold";
    }

    .ezi-alert-footer {
        display: flex;
        justify-content: center;
    }

    .confirm-alert-btn {
        border: 0;
        color: #fff;
        background-color: #CE2E6C;
        border-radius: 25px;
        min-height: 32px;
        height: 32px;
        padding: 0 20px;
        font-size: 0.85rem;
        letter-spacing: 0.5px;
        min-width: 80px;
        &:focus{
            outline:0;
        }
    }

    .close-alert-btn {
        border: 0;
        background: transparent;
        color: #B7B7B7;
        margin-left: 10px;
        border-radius: 25px;
        min-height: 32px;
        height: 32px;
        padding: 0 10px;
        font-size: 0.85rem;
        letter-spacing: 0.5px;
        &:focus{
            outline:0;
        }
    }

    .close-alert-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        min-width: 30px;
        background: transparent;
        border-radius: 50%;
        margin-left: auto;
        background-image: url(../../assets/images/modal_close_ic.svg);
        background-size: 12px;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        right: 0;
        text-indent: -9999px;
        border: 0;
        padding: 0;
        &:focus{
            outline:0;
        }
    }

}
