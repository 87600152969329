@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";
.Page-EditCompany {
    padding: 40px 7%;
    background-color: #F2F5F8;

    .column-header{        
        .column-header-btn{    
            .clear_all{
                background : transparent;
                color: #BFBFBF;
            }
               
        }
    }


    .tab-header-wrap{
        border-bottom: 1px solid #E9E9E9;
    }
    .tab-left-header .nav-item {
        margin-bottom: 10px;
        a{
            padding-bottom: 15px;
        }
    }
  
    .tab-left-header .nav-pills .nav-link.active:after, .tab-left-header .nav-pills .show > .nav-link:after{
        bottom: -11px;
    }
    .add-single-card {
        background-color: #fff;
        border-radius: 10px;
        padding: 40px 40px;
        margin-bottom: 30px;
        margin-top: 50px;
        box-shadow: 0 3px 2px rgba(0,0,0,.1);
    }
    .single-card-header{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }
    .card-user-info{

        .name{
            font-family: $Font;
            color:#8D8D8D;
            font-size: 1.4rem;
            margin: 6px 0 10px;
        }
        .role{
            color: #B7B7B7;
            font-family: $Font;
            font-size: 0.8rem;
            margin: 6px 0;
        }
    }
    .single-card-btn{
        margin-left: auto;
        display: inline-flex;
        align-items: center;
        .single-clear {
            padding: 5px 10px;
            border: 0;
            color: #BFBFBF;
            background-color: transparent;
            margin-right: 20px;
            font-family: $Font;
            font-size: 0.9rem;
            transition: color 0.3s ease;
            &:hover{
                color: #CE2E6C;
            }
            &:focus{
                outline:0;
            }
        }
        .single-save{
            padding: 6px 30px;
            border: 0;
            color: #fff;
            background-color: #CE2E6C;
            font-family: $Font;
            font-size: 0.9rem;
            border-radius: 25px;
            &:focus{
                outline:0;
            }
        }
    }
    
        .tab-content{
            padding: 40px 0;
        }
        label{
            display: flex;
            flex-direction: column;
            color: #C5C5C5;
            font-family: $Font;
            margin-bottom: 5px;
            padding: 14px 20px 0;
            border:1px solid transparent;
            font-size: 0.85rem;
            &:focus-within {
                background: #FBFBFB;
                border:1px solid #E9E9E9;
                border-radius: 8px;
            }
            input,select{
                border: 0;
                padding: 15px 0;
                background-color: transparent;
                color:#1C1C1C;
                font-family: $Font;
                font-size: 1rem;
                &:focus{
                    outline:0;
                }
                @include placeholder {
                    color: #e9e9e9;;
                }
                
            }
            select{
                -webkit-appearance: none;
                cursor: default;
                background: url(../../assets/images/select_arrow.svg);
                background-size: 10px;
                background-position: right;
                background-repeat: no-repeat;
            }
            .error_cu{
                color:#ff0000;
                font-family: $SemiBoldFont;
                font-size: 0.9rem;
                margin-bottom: 10px;
            }
        }

        .save-btn{
            background-color: #CE2E6C;
            color: #fff;
            border: 0;
            height: 50px;
            padding: 0 56px;
            border-radius: 25px;
            font-family: $Font;
            font-size: 1.1rem;
            text-transform: uppercase;
            cursor:pointer;
            &:focus{
                outline: 0;
            }
        }
    

    .radio-wrap{
        display: flex;
        margin: 10px 0;
        .radio-inner-wrap {
            margin-right: 20px;
        }
    }




    /* The container */
.ezi-radio {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    font-family: $Font;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .ezi-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    padding: 0;
    left: 3px;
    top: 2px;
    z-index: 999;
    width:100%;
    height:100%;
  }
  
  /* Create a custom radio button */
  .ezi-checkmark {
      position: absolute;
      top: 2px;
      left: 0;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      border:1px solid #b4b4b4;
  }
  
  /* On mouse-over, add a grey background color */
  .ezi-radio:hover input ~ .ezi-checkmark {
    background-color: #eee;
  }
  
  /* When the radio button is checked, add a blue background */
  .ezi-radio input:checked ~ .ezi-checkmark {
       border: 1px solid #CE2E6C;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .ezi-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .ezi-radio input:checked ~ .ezi-checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .ezi-radio .ezi-checkmark:after {
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #CE2E6C;
  }

  .datepicker-wrap{
    padding: 15px 0;
    .react-date-picker{
        display: flex;
        .react-date-picker__wrapper{
            width:100%;
            input{
                padding: 0;
            }
        }
    }
  }

    .react-date-picker__wrapper{
        border:0;
    }
  .react-date-picker__button{
    &:focus{
        outline:0;
    }
  }
    .react-date-picker__clear-button{
        display: none;
    }


    .single-select{
        .css-yk16xz-control {
            border: 0;
        }
        .css-1okebmr-indicatorSeparator {
            background-color: transparent;
        }
        .css-1hwfws3{
            padding: 10px 0;
        }
        .css-1wa3eu0-placeholder{
                color: #e9e9e9;            
        }
    } 

    .add-company-form-wrap{
        background-color: #fff;
        border-radius: 10px;
        padding: 40px 40px;
        margin-bottom: 30px;
        margin-top: 50px;
        box-shadow: 0 3px 2px rgba(0,0,0,.1);

    }
    .company-info-header{            
        display: flex;
        justify-content: space-between;
    }
    .conpany-name-id-wrap{
        display: flex;
        flex-direction: column;
        .title{
            color:#1c1c1c;
            font-size: 1.2rem;
            margin-bottom: 7px;
        }
        .subtitle{
            color:#B7B7B7;
            font-size: 0.85rem;
        }
    }

    .company-add-logo-wrap {
        margin-right: 20px;
        position: relative;
        display: inline-flex;

        .company-logo-upload-position {
            position: absolute;
            right: 0px;
            bottom: 0px;
            display: inline-flex;
        }

        .company-logo-upload-wrapper{
            position: relative;
            overflow: hidden;
            display: inline-flex;
          }
          
          .company-logo-upload-btn {
            border:0;
            background-color: #CE2E6C;
            padding: 0;           
            background-image: url(../../assets/images/edit-icon-white.svg);
            background-size: 13px;
            background-position: center;
            background-repeat: no-repeat;
            height: 24px;
            width: 24px;
            min-width: 24px;
            display: inline-flex;
            border-radius: 50%;
            cursor: pointer;
          }
          
          .company-logo-upload-input{
            font-size: 100px;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
          }

        .company-default-logo {
            height: 70px;
            width: 70px;
            min-width: 70px;
        }
    }


}