
/* ------------------------------------
    Added Samir 
       - Css Font Variable
-------------------------------------- */
$Font: 'NunitoSans',Helvetica, Arial, sans-serif;
$SemiBoldFont: 'NunitoSansSemiBold',Helvetica, Arial, sans-serif;
$LightFont: 'NunitoSansLight',Helvetica, Arial, sans-serif;
$BoldFont: 'NunitoSansBold',Helvetica, Arial, sans-serif;
$ItalicFont:'NunitoSansItalic',Helvetica, Arial, sans-serif ;
$ReportWidgetRadius:3px;
$ReportWidgetMargin:15px;
$PositiveColor:#5aaf2b;
$NeutralColor:#e1c63b;
$NegativeColor:#e43e3d;
//********* Sam changes*********
$CeTitle: 1.2rem;
$ThemeColor: #ce2e6c;
$OtherText: 0.85rem;
$SurveyName: 1.05rem;
$labelFormColor: #656565;
$inputBorderColor: #d1d1d1;