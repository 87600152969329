
    .survey_card_skeleton{
        position: relative;
        background-color: #fff;
        border-radius: 18px;
        display: inline-flex;
        flex-direction: column;
        width: calc(25% - 60px);
        min-width: 230px;
        max-width: 230px;
        padding: 30px;
        margin: 0 20px 40px;
        box-shadow: -1px 7px 91px rgba(0, 0, 0, 0.08);
        max-height: 180px;
        min-height: 180px;
        overflow: hidden;
        cursor: pointer;             
      
        .skeleton-line{
            width: 100%;
            height: 10px;
            margin-top: 12px;
            border-radius: 2px;
            background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
            background-size: 600px;
            animation: shine-lines 1.2s infinite linear;
        } 
        .skeleton-heading{
            height:18px;
            margin-top:0;
            margin-bottom:18px;
        }
        .skeleton-45{ width:45%; }
        .skeleton-85{ width:85%; }
        .skeleton-60{ width:60%; }

    }
   

    @keyframes shine-lines {
        0% {
            background-position: -100px;
        }
    
        40%, 100% {
            background-position: 140px;
        }
    }


