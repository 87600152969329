@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";
.Page-CommunicationTemplates {
  padding: 40px 7%;
  .communication-template-subtitle {
    color: #b7b7b7;
    font-family: $Font;
    font-size: 0.9rem;
    margin: 10px 0;
    display: block;
  }
  .react-bootstrap-table .table tbody tr td:hover:after {
    display: none;
  }
  .communication-template-status_c {
    text-transform: capitalize;
    display: inline-flex;
    align-items: center;
    color: #1c1c1c;
    font-family: $SemiBoldFont;
    font-size: 0.88rem;
    white-space: nowrap;
    margin-right: 20px;
    &:before {
      content: "";
      height: 8px;
      width: 8px;
      min-width: 8px;
      display: inline-flex;
      align-items: center;
      border-radius: 50%;
      margin-right: 6px;
    }
    &.active:before {
      background: #b5ee5f;
    }
    &.offline:before {
      background: #ff1616;
    }
  }
  .communication-template-name_c {
    color: #1c1c1c;
    font-family: $SemiBoldFont;
    font-size: 0.88rem;
    white-space: nowrap;
    text-transform: capitalize;
    &:hover {
      color: #ce2e6c;
      cursor: pointer;
    }
  }
  .react-bootstrap-table .table tbody tr td {
    white-space: normal;
  }

  .add-communication-template-btn {
    margin-right: 30px;
  }
  .communication-template-editDelete-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .communication-template-delete {
      background: transparent;
      border: 0;
      padding: 0;
      color: #c5c5c5;
      transition: color 0.3s ease;
      font-family: $Font;
      font-size: 0.8rem;
      display: inline-flex;
      align-items: center;
      &:focus {
        outline: 0;
      }
      &:hover {
        color: #ce2e6c;
        &:before {
          background-image: url(../../assets/images/table_delete_active_ic.svg);
        }
      }
      &:before {
        content: "";
        display: inline-block;
        background-image: url(../../assets/images/table_delete_ic.svg);
        background-size: 9px;
        background-position: center;
        background-repeat: no-repeat;
        height: 24px;
        width: 24px;
        margin-right: 2px;
        transition: background-image 0.3 ease;
      }
    }
    .communication-template-edit {
      background: transparent;
      border: 0;
      padding: 0;
      margin-right: 15px;
      color: #c5c5c5;
      transition: color 0.3s ease;
      font-family: $Font;
      font-size: 0.8rem;
      display: inline-flex;
      align-items: center;
      &:focus {
        outline: 0;
      }
      &:hover {
        color: #ce2e6c;
        &:before {
          background-image: url(../../assets/images/table_edit_actives_ic.svg);
        }
      }
      &:before {
        content: "";
        display: inline-block;
        background-image: url(../../assets/images/table_edit_ic.svg);
        background-size: 14px;
        background-position: center;
        background-repeat: no-repeat;
        height: 24px;
        width: 24px;
        margin-right: 2px;
        transition: background-image 0.3 ease;
      }
    }
  }
  .react-bootstrap-table {
    .communication-template-datatable-row {
      .communication-template-des {
        font-size: 0.84rem;
      }
      .communication-template-editDelete-wrap {
        visibility: hidden;
        margin-right: 30px;
      }
      &:hover {
        .communication-template-editDelete-wrap {
          visibility: visible;
        }
      }
    }
  }
  .add-communication-template-btn-wrap {
    display: flex;
    justify-content: center;
  }
  .ezi-sweet-tab {
    .tab-content {
      padding: 40px 0;
      background-color: #fff;
    }
  }
}
.add-communication-template-form {
  display: flex;
  flex-direction: column;
  .close-communication-template-btn {
    background: transparent;
    border: 0;
    padding: 4px 10px;
    margin: 0;
    margin-right: 10px;
    color: #1c1c1c;
    font-family: $SemiBoldFont;
    font-size: 0.8rem;
    &:focus {
      outline: 0;
    }
  }
  .add-communication-template-btn {
    padding: 6px 30px;
    font-family: $SemiBoldFont;
    font-size: 0.8rem;
  }
  .communication-template-modal-footer {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    margin-bottom: 12px;
  }
}
.communication-template-modal-title {
  color: #ce2e6c;
  font-family: $SemiBoldFont;
  font-size: 1.1rem !important;
}
.communication-template-field-wrapper {
  padding: 10px 0;
}
.communication-template-modal-wrapper {
  .communication-template-field-control {
    flex: 1;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #b7b7b7;
    margin: 0 10px 20px;
    min-height: 38px;
    padding: 0 10px;
    color: #707070;
    font-family: $Font;
    font-size: 0.88rem;
    resize: none;
    &:focus {
      outline: 0;
    }
    @include placeholder {
      color: #d9d9d9;
    }
  }
  .communication-template-field {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .communication-template-field-50 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .communication-template-field label {
    flex: 1;
    padding: 0 10px;
    color: #b7b7b7;
    font-family: $Font;
    font-size: 0.9rem;
  }
  .communication-template-error_cu {
    color: #ff0000;
    font-family: $SemiBoldFont;
    font-size: 0.9rem;
    margin-bottom: 10px;
    padding-left: 10px;
  }
  .ezi-modal-header {
    border-bottom: 1px solid #e9e9e9;
    .ezi-modal-header-title {
      display: flex;
      width: 100%;
    }
    .ezi-modal-close {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      background: #f2f5f8;
      border-radius: 50%;
      margin-left: auto;
      background-image: url(../../assets/images/modal_close_ic.svg);
      background-size: 11px;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}
.Page-SaveCommunicationTemplate {
  height: 100%;
  overflow-y: auto;
  padding: 40px 7%;
  .template-add-beadcrumb {
    border-bottom: 0;
  }
  .error-message {
    color: #ff0000;
    font-size: 14px;
    margin: 5px 0;
    display: block;
    padding-left: 30px;
    font-family: $SemiBoldFont;
  }

  form {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .survey-category-wrap {
    display: flex;
    flex-direction: column;
    max-width: 550px;
    width: 100%;
    .category_label {
      color: #504658;
      font-size: 1.8rem;
      font-family: $Font;
      text-align: center;
      margin: 0 0 40px;
    }
    .category-field-control {
      margin-bottom: 20px;
    }
    .create-category-input {
      width: 100%;
      background-color: #fff;
      border-radius: 25px;
      border: 0;
      padding: 14px 30px;
      box-shadow: -1px 7px 39px rgba(0, 0, 0, 0.11);
      font-size: 1rem;
      color: #504658;
      resize: none;
      &:focus {
        outline: 0;
      }
      @include placeholder {
        color: #b7b7b7;
      }
      &::-webkit-scrollbar {
        width: 4px;
        background-color: #f5f5f5;
      }
    }
    .category_add_btn_wrap {
      display: flex;
      justify-content: center;
    }
    .category_add_btn {
      background: #504658;
      color: #fff;
      border: 0;
      border-radius: 25px;
      padding: 8px 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &:focus {
        outline: 0;
      }
      .spinner-grow {
        margin-left: 10px;
      }
    }
  }

  .category-field-control .ezi-select-plugin {
    background-color: #fff;
    border-radius: 25px;
    border: 0;
    width: 100%;
    padding: 7px 0px;
    box-shadow: -1px 7px 39px rgba(0, 0, 0, 0.11);
    font-size: 1rem;
    color: #504658;
    resize: none;
    &.css-14jk2my-container {
      pointer-events: initial;
      &:hover {
        cursor: not-allowed;
        pointer-events: initial;
        .css-1ht9tev-control {
          pointer-events: none;
          cursor: not-allowed;
        }
        .css-1hwfws3 {
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }
    .css-c8odyh-control,
    .css-1l3nnzh-control {
      border-radius: 25px;
      padding: 0 20px;
    }
    .css-26l3qy-menu {
      border-radius: 12px;
      padding: 10px 0;
    }
    .css-1ht9tev-control {
      padding: 0 20px;
    }
  }
  .placeholder-btn-wrap {
    display: flex;
    padding: 10px 10px;
    overflow-x: hidden;
    flex-wrap: wrap;
    &::-webkit-scrollbar {
      height: 4px;
    }
    .placeholder-btn-inner {
      padding: 0 5px;
      margin-bottom: 5px;
      &:last-child {
        padding-right: 30px;
      }
    }
    .insert-placeholder-btn {
      background-color: #ce2e6c;
      color: #fff;
      border: 0;
      min-height: 36px;
      border-radius: 25px;
      padding: 0 20px;
      font-family: "NunitoSans";
      font-size: 0.8rem;
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      &:focus {
        outline: 0;
      }
    }
  }
}
