.svd_container a:focus, .svd_container div:focus, .svd_container span:focus, .svd_container select:focus, .svd_container tr:focus, .svd_container input[type="checkbox"]:focus + span.checkmark{
    outline: 0;
}
.svd_container {
    .svd_content {
        padding: 0 0 30px;
        background-color: transparent;
        .svd_survey_designer {
            // display: flex;
            // width: initial;
            .svd-designer-container--left-side {
                // width: unset;
                // max-width: 100%;
                // flex: 0 0 25%;
                min-width: 270px !important;
                width: 270px !important;
                background: #fff;
                border-radius: 5px;
                + svd-splitter {
                    .svd-splitter {
                        display: none;
                    }
                }
                .svd-icon-container--left-open,
                .svd-icon-container--left-close {
                    display: none;
                }
            }
            .icon.svd-icon-container {
                // display: none;
            }
            .svd-designer-container {
                // overflow: initial;
            }
            .svd-designer-container__header {
                padding: 16px 20px 16px;
                border-bottom: 1px solid #e9e9e9;
                text-transform: capitalize;
                font-weight: normal;
                font-family: $SemiBoldFont;
                font-size: 0.9rem;
                margin: 0;
                height: initial;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .svd-designer-container__header-title {
                padding: 16px 20px 16px;
                text-transform: capitalize;
                font-weight: normal;
                font-family: $SemiBoldFont;
                font-size: 0.9rem;
            }
            .svd_toolbox {
                background-color: #fff;
                max-width: 100%;
                flex: 0 0 25%;
                padding: 20px 20px;
                border-radius: 5px;
                margin: 0;

                // > .svd_toolbox_title{
                //     text-align: left;
                //     color:#1C1C1C;
                //     font-family: $SemiBoldFont;
                //     font-size: 0.85rem;
                //     margin-bottom: 25px;
                //     position: relative;
                //     padding: 0 0 15px;
                //     &:after{
                //         content: '';
                //         position: absolute;
                //         left: -20px;
                //         bottom: 0;
                //         width: calc(100% + 40px);
                //         height: 1px;
                //         background: #E9E9E9;
                //     }
                // }
                .svd_toolbox_item {
                    border-radius: 9px;
                    background-color: #f2f5f8;
                    margin-bottom: 10px;
                    border: 0;
                    padding: 6px 10px;
                    display: flex;
                    align-items: center;
                    &:focus {
                        outline: 0;
                    }
                    .svd_toolbox_item_text {
                        color: #656565;
                        font-family: $SemiBoldFont;
                        font-size: 0.85rem;
                        margin-left: 8px;
                    }
                    svg-icon {
                        height: 30px;
                        min-width: 30px;
                        background: transparent;
                        background-size: 20px;
                        background-position: center;
                        background-repeat: no-repeat;
                        display: inline-block;
                        opacity: 0.8;
                    }
                }
                // svd_toolbox_item_icon-text_custom
                $ToolboxPath: "../images/widget/toolbox/";
                $icon-base-class: "svd_toolbox_item_icon-";
                $image-url: ("#{$icon-base-class}radiogroup_custom", "#{$ToolboxPath}multiple-choice-single.svg"),
                    ("#{$icon-base-class}checkbox_custom", "#{$ToolboxPath}multiple-choice-many.svg"),
                    ("#{$icon-base-class}text_custom", "#{$ToolboxPath}single-line.svg"),
                    ("#{$icon-base-class}imagepicker_custom", "#{$ToolboxPath}image-type.svg"),
                    ("#{$icon-base-class}datepicker_custom", "#{$ToolboxPath}calendar.svg"),
                    ("#{$icon-base-class}dropdown_custom", "#{$ToolboxPath}dropdown.svg"),
                    ("#{$icon-base-class}barrating_custom", "#{$ToolboxPath}star-rating.svg"),
                    ("#{$icon-base-class}rating_custom", "#{$ToolboxPath}rating-scale.svg"),
                    ("#{$icon-base-class}comment_custom", "#{$ToolboxPath}multiple-line.svg"),
                    ("#{$icon-base-class}file_custom", "#{$ToolboxPath}file-upload.svg"),
                    ("#{$icon-base-class}sortablejs_custom", "#{$ToolboxPath}ranking.svg"),
                    ("#{$icon-base-class}tagbox_custom", "#{$ToolboxPath}tag.svg"),
                    ("#{$icon-base-class}html_custom", "#{$ToolboxPath}html.svg"),
                    ("#{$icon-base-class}default_custom", "#{$ToolboxPath}form.svg"),
                    ("#{$icon-base-class}matrix_custom", "#{$ToolboxPath}matrix-single.svg"),
                    ("#{$icon-base-class}matrixdropdown_custom", "#{$ToolboxPath}matrix-multi.svg"),
                    ("#{$icon-base-class}matrixdynamic_custom", "#{$ToolboxPath}matrix-custom.svg"),
                    ("#{$icon-base-class}boolean_custom", "#{$ToolboxPath}yes-no.svg"),
                    ("#{$icon-base-class}sortablejs_custom", "#{$ToolboxPath}ranking.svg"),
                    ("#{$icon-base-class}personal-info_custom", "#{$ToolboxPath}contact-information.svg"),
                    ("#{$icon-base-class}bootstrap-slider_custom", "#{$ToolboxPath}slider-scale.svg");

                .svd_toolbox_item {
                    @each $color in $image-url {
                        &.#{nth($color,1)} {
                            svg-icon {
                                background-image: url(#{nth($color,2)});
                            }
                        }
                    }

                    &.svd_toolbox_item_icon-datepicker_custom_custom svg-icon {
                        background-size: 16px;
                    }
                    &.svd_toolbox_item_icon-default_custom svg-icon {
                        background-size: 16px;
                    }
                    &.svd_toolbox_item_icon-checkbox_custom svg-icon {
                        background-size: 16px;
                    }
                }
            }

            .svd_editors {
                // flex:1 !important;
                padding: 0 15px;
                .svd_toolbar {
                    display: -webkit-inline-flex;
                    display: inline-flex;
                    position: relative;
                    top: -4px;
                    margin: 0 0 10px;
                    
                    .svd_action {
                        display: inline-flex;
                        min-height: 40px;
                        height: 40px;
                        margin: 0;
                        background: #fff;
                        padding: 4px 5px;
                        -moz-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
                        -webkit-box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
                        box-shadow: -1px 7px 60px rgba(0, 0, 0, 0.08);
                        &:focus {
                            outline: 0;
                        }
                        .btn.btn-primary {
                            color: #8c9bab;
                            background-color: transparent;
                            border-color: 0;
                            min-width: initial;
                            margin: 0;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            font-family: $SemiBoldFont;
                            font-weight: normal;
                            font-size: 0.82rem;
                            &:focus,
                            &:active {
                                outline: 0;
                                box-shadow: none;
                            }
                            .svd-toolbar-button__image {
                                margin: 0 5px;
                                .svd-svg-icon {
                                    fill: #8c9bab;
                                    margin: 0;
                                    vertical-align: top;
                                }
                            }
                        }
                    }
                    #svd-survey-settings{
                        border-radius: 5px;
                        padding: 0 15px;
                        margin: 0 7px 0 0;
                        button{
                            padding: 0 7px 0 2px;
                            .svd-toolbar-button__image{
                                margin-left: 0;
                                margin-top: -2px;
                            }
                        }
                    }
                    #thememapping{
                        border-radius: 5px;
                        margin: 0 7px 0 0;
                    }
                    #questionmapping{
                        border-radius: 5px;
                        margin: 0 7px 0 0;
                    }
                    #svd-undo{
                        border-radius: 5px 0 0 5px;    
                        display: none;                
                    }
                    #svd-redo{
                        border-radius: 0 5px 5px 0;
                        display: none;   
                    }
                }
                svd-pages-editor {
                    display: inline-flex;
                    width: initial;
                    max-width: 410px;
                    .svd-page-selector{
                        background-color: transparent;
                        margin-bottom: 10px;
                        min-height: inherit;
                        height: initial;
                        line-height: initial;
                        border: 0;
                        // margin-right: 15px;
                        max-width: 150px;
                        min-width: 150px;
                        .svd-toolbar-dropdown{
                            width: 100%;
                        }
                        .svd_custom_select{
                            width: 100%;
                            &::before{
                                content: '';
                                background-color: #fff;
                                padding: 0;
                                width: 0;
                                height: 0;
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                border-top: 6px solid #1C1C1C;
                                position: absolute;
                                right: 10px;
                                top: 50%;
                                z-index: 1;
                                -webkit-transform: translateY(-50%);
                                transform: translateY(-50%);
                            }
                            select{   
                                padding: 2px 15px;                             
                                background-color: #fff;
                                border-radius: 5px;
                                min-height: 40px;
                                height: 40px;
                                border:0;
                                // line-height: 40px;
                                -moz-box-shadow: -1px 7px 60px rgba(0,0,0,0.08);
                                -webkit-box-shadow: -1px 7px 60px rgba(0,0,0,0.08);
                                box-shadow: -1px 7px 60px rgba(0,0,0,0.08);
                                padding-right: 30px;
                                &:focus{
                                    outline: 0;
                                }
                            }
                        }
                    }
                    .svd-page-scroller-arrow {
                        margin: 0;
                        height: 40px;
                        background-color: #fff;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        // display: none;
                        margin-left: 10px;
                        padding-top: 0;
                        &:focus {
                            outline: 0;
                        }
                        &:hover {
                            svg-icon svg {
                                fill: rgb(80, 70, 88);
                            }
                        }
                        span {
                            line-height: initial;
                        }
                        svg-icon svg {
                            fill: #8c9bab;
                        }
                    }
                    .svd-page-add {
                        border-radius: 5px;
                        margin: 0;
                        height: 40px;
                        background-color: #fff;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 10px;
                        display: none;
                        svg-icon svg {
                            margin-top: 1px;
                            fill: #8C9BAB;
                        }
                    }
                    .svd-pages {
                        width: initial;
                        max-width: initial;
                        height:40px;
                        // display: none;
                        .icon-gear .svd-svg-icon{
                            fill: rgb(80, 70, 88);
                        }
                        .svd-page{
                            height:40px;
                            border: 0;
                            margin: 0 7px;
                            border-radius: 5px;
                            background: transparent;
                            border: 1px solid #ccc;
                            padding: 8px 20px;
                        }

                        +  .svd-page-scroller-arrow{
                            margin-left: 0;
                        }
                    }
                }
                .svd_questions_editor {
                    height: initial;
                    overflow-y: auto;
                    overflow-x: hidden;
                    border-radius: 5px;
                    .svd-survey-placeholder-root {
                        position: initial;
                        top: initial;
                        bottom: initial;
                        left: initial;
                        right: initial;
                        .svd-empty-message-container {
                            position: initial;
                            top: initial;
                            bottom: initial;
                            left: initial;
                            right: initial;
                            transform: initial;
                            width: 100%;
                            img {
                                display: none;
                            }
                            .svd-empty-message {
                                border: 1px dashed #8d8d8d;
                                padding: 20px;
                                border-radius: 5px;
                                font-size: 1.1rem;
                                font-family: "NunitoSansSemiBold";
                                color: #1c1c1c;
                                max-width: 100%;
                                width: calc(100% - 100px);
                                min-height: 200px;
                                display: inline-flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                position: initial;
                                left: unset;
                                top: unset;
                                transform: unset;
                                &:after {
                                    content: "";
                                    height: 60px;
                                    width: 60px;
                                    min-width: 60px;
                                    background: #e9e9e9;
                                    border-radius: 50%;
                                    margin-top: 20px;
                                    background-image: url(../images/drop-question.svg);
                                    background-size: 34px;
                                    background-position: center;
                                    background-repeat: no-repeat;
                                }
                            }
                        }
                    }
                    .svd-survey-placeholder {
                        display: flex;
                        justify-content: center;
                        padding: 30px 0;
                    }

                    /*
                    ** Survey designer tab id wrapper for all child css START
                    */
                    .svd_surveyjs_designer_container {
                        .sv_main.sv_default_css {
                            background-color: #fff;
                            .sv_progress {
                                display: block;
                                max-width: 300px;
                                margin: 0 auto;
                                background-color: #f9e4ec;
                                height: 10px;
                                z-index: 0;
                                position: relative;
                                border-radius: 30px;
                                margin-top: 4%;
                                margin-bottom: 90px;
                                .sv_progress_bar {
                                    height: 100%;
                                    position: relative;
                                    border-radius: 25px;
                                    background: #ce2e6c;
                                    + span {
                                        margin: 15px 0;
                                        color: #8d8d8d;
                                        font-family: "NunitoSansSemiBold";
                                        font-size: 0.85rem;
                                        display: block;
                                        text-align: center;
                                    }
                                }
                            }
                            .sv_container .sv_body .sv_p_root {
                                padding: 0;
                                .sv_page_title.title_editable {
                                    padding: 20px 50px 10px;
                                    color: #ce2e6c;
                                    font-size: 1.2rem;
                                    margin-bottom: 0;
                                    // text-transform: capitalize;
                                    title-editor .svda-content .svda-title-editor-content {
                                        color: #ce2e6c;
                                        font-size: 1.2rem;
                                        border-bottom: 1px solid transparent;
                                        input {
                                            color: #ce2e6c;
                                            font-size: 1.2rem;
                                            &:focus {
                                                border-color: #e1e1e1 !important;
                                            }
                                        }
                                    }
                                }
                                .description_editable {
                                    padding: 0 50px;
                                    margin-bottom: 20px;
                                    color: #b7b7b7;
                                    font-size: 0.8rem;
                                    title-editor .svda-content .svda-title-editor-content {
                                        color: #b7b7b7;
                                        font-size: 0.8rem;
                                        border-bottom: 1px solid transparent;
                                        input {
                                            color: #b7b7b7;
                                            font-size: 0.8rem;
                                            &:focus {
                                                border-color: #e1e1e1 !important;
                                            }
                                        }
                                    }
                                }
                                .sv_row {
                                    border-bottom: 1px solid #e9e9e9;
                                    // padding: 20px 50px 40px;
                                    background: transparent;
                                    // margin-bottom: 25px;
                                    &:last-child {
                                        border: 0;
                                    }
                                    .svda-question-actions {
                                        left: 50px;
                                        right: 20px;
                                        top: 15px;
                                        select {
                                            background-color: transparent;
                                        }
                                    }
                                    .ezi-survey-header {
                                        margin-top: 30px;
                                    }
                                    .select2-container--default .select2-selection--multiple {
                                        border: 1px solid #e9e9e9;
                                        min-height: 46px;
                                    }
                                    .sv_cw_pretty_checkbox_checkbox {
                                        color: #504658;
                                        margin-bottom: 20px;
                                        .pretty {
                                            width: 100%;
                                            margin: 0;
                                            padding: 0 10px;
                                        }
                                        .pretty input:checked ~ .state.p-success label:after,
                                        .pretty.p-toggle .state.p-success label:after {
                                            background-color: #ce2e6c !important;
                                            background-image: url(../images/chechbox-check.png);
                                            background-size: 13px;
                                            background-position: center;
                                            background-repeat: no-repeat;
                                        }
                                    }
                                    .sv_cw_pretty_checkbox_radiogroup {
                                        margin-bottom: 20px;
                                        .pretty {
                                            width: 100%;
                                            margin: 0;
                                            padding: 0 10px;
                                        }
                                        .pretty input:checked ~ .state.p-success label:after,
                                        .pretty.p-toggle .state.p-success label:after {
                                            background-color: #ce2e6c !important;
                                            transform: scale(0.7);
                                        }
                                        .pretty.p-round .state label:before {
                                            border-radius: 50%;
                                            width: 16px;
                                            min-width: 16px;
                                            height: 16px;
                                            margin-top: -1px;
                                        }
                                        .pretty.p-round .state label:after {
                                            border-radius: 50% !important;
                                        }
                                    }
                                    .pretty .state label {
                                        text-indent: 1.1em;
                                        font-size: 0.85rem;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        width: 100%;
                                        color: #1c1c1c;
                                        font-family: $Font;
                                        &::first-letter {
                                            text-transform: uppercase;
                                        }
                                    }
                                    .pretty .state label:before {
                                        border-color: #b7b7b7;
                                    }
                                    .pretty .state label:before,
                                    .pretty .state label:after {
                                        border-radius: 3px;
                                        margin-top: 0;
                                    }
                                    .pretty.p-default .state label:after {
                                        transform: scale(1);
                                    }

                                    table.sv_q_matrix {
                                        border: 1px solid #e9e9e9;
                                        border-radius: 18px;
                                        border-collapse: separate;
                                        padding: 0 15px 15px;
                                        position: relative;
                                        thead {
                                            border-bottom: 1px solid #e9e9e9;
                                            th {
                                                padding: 12px 10px 30px;
                                                span {
                                                    font-size: 0.82rem;
                                                    font-family: $SemiBoldFont;
                                                    color: #504658;
                                                    letter-spacing: 0.2px;
                                                }
                                            }
                                            &:after {
                                                content: "";
                                                position: absolute;
                                                left: 0;
                                                width: 100%;
                                                background: #e9e9e9;
                                                height: 1px;
                                                margin-top: -17px;
                                            }
                                        }
                                        tbody tr td {
                                            padding: 0px 10px;
                                            span {
                                                font-size: 0.82rem;
                                                font-family: $SemiBoldFont;
                                                color: #504658;
                                                letter-spacing: 0.2px;
                                            }
                                            label.sv_q_m_label {
                                                position: relative;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                padding: 10px 0;
                                            }
                                        }
                                    }

                                    .svd_question {
                                        border: 0;
                                        .sv_q_text_root {
                                            border-radius: 5px;
                                            height: 38px;
                                            max-width: 400px;
                                            width: 100%;
                                            border: 1px solid #e9e9e9;
                                        }
                                        textarea {
                                            border-radius: 5px;
                                            max-width: 300px;
                                            width: 100%;
                                            border: 1px solid #e9e9e9;
                                            resize: none;
                                        }
                                        fieldset.sv_qcbc {
                                            .svda-add-new-item {
                                                color: #ccc;
                                                padding-left: 0;
                                                padding-right: 16px;
                                                transition: all 0.3s ease;
                                                svg {
                                                    fill: #ccc;
                                                }
                                                &:hover {
                                                    color: #ce2e6c;
                                                    svg {
                                                        fill: #ce2e6c;
                                                    }
                                                }
                                            }
                                            .sv_q_select_column {
                                                min-width: initial;
                                            }
                                            .sv_q_checkbox {
                                                border: 0;
                                                padding: 10px 0;
                                                margin-bottom: 15px;
                                                border-radius: 5px;
                                                // display: flex;
                                                .sv_q_checkbox_label {
                                                    margin: 0;
                                                    display: flex;
                                                    align-items: center;
                                                    white-space: nowrap;
                                                    .sv_q_checkbox_control_label {
                                                        white-space: nowrap;
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                    }
                                                }
                                                .form-group {
                                                    margin-bottom: 0;
                                                }
                                            }
                                            .sv_q_radiogroup {
                                                border: 0;
                                                padding: 10px 0;
                                                margin-bottom: 15px;
                                                border-radius: 5px;
                                                // display: flex;
                                                overflow: hidden;
                                                .sv_q_radiogroup_label {
                                                    margin: 0;
                                                    display: flex;
                                                    align-items: center;
                                                    white-space: nowrap;
                                                    .circle {
                                                        margin-right: 5px;
                                                    }
                                                    .item_editable {
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                    }
                                                }
                                            }
                                            .br-theme-css-stars .br-widget a {
                                                width: 30px;
                                                height: 30px;
                                                display: inline-flex;
                                                align-items: center;
                                                justify-content: center;
                                                &:after {
                                                    color: #d2d2d2;
                                                    font-size: 35px;
                                                }
                                            }
                                        }
                                        .sv_select_wrapper {
                                            select {
                                                -moz-appearance: none;
                                                -webkit-appearance: none;
                                                width: 100%;
                                                border: 1px solid #e9e9e9;
                                                padding: 10px 20px;
                                                border-radius: 5px;
                                            }
                                            .svda-select-items-title {
                                                top: -30px;
                                                right: 40px;
                                                .svd-main-color {
                                                    color: #ce2e6c;
                                                }
                                            }
                                            .svda-select-items-content {
                                                .svda-select-items-collection {
                                                    .item_draggable {
                                                        padding: 4px 10px;
                                                    }
                                                }
                                                .item_editable {
                                                    padding: 4px 10px;
                                                    font-family: $Font;
                                                    font-size: 0.8rem;
                                                    color: #656565;
                                                }
                                            }
                                        }
                                    }
                                    .title_editable {
                                        color: #1c1c1c;
                                        font-size: 1.3rem;
                                        // text-transform: capitalize;
                                        margin: 20px 0;
                                    }
                                    .br-theme-css-stars .br-widget a {
                                        height: 40px;
                                        width: 40px;
                                        &:after {
                                            content: "";
                                            background-image: url(../images/widget/blank-star.svg);
                                            background-size: 30px;
                                            background-position: center;
                                            background-repeat: no-repeat;
                                            display: inline-flex;
                                            height: 40px;
                                            width: 40px;
                                        }
                                    }

                                    // survey js first page preview css  start
                                    .sv_q.sv_qstn {
                                        overflow: initial;
                                        border: 0;
                                        margin-top: 0;
                                        padding: 50px 50px 40px;
                                        width: 100% !important;
                                        &:hover {
                                            background-color: #fbfbfb;
                                        }
                                        &:focus {
                                            outline: 0;
                                        }
                                        &.svd_q_selected {
                                            background-color: #fbfbfb;
                                            .svda-question-actions {
                                                background-color: #fbfbfb;
                                                question-actions {
                                                    display: block;
                                                }
                                            }
                                        }

                                        .sv_select_wrapper {
                                            background: transparent;
                                        }
                                        .sv_q_text_root {
                                            border-radius: 5px;
                                            min-height: 38px;
                                            border: 1px solid #e9e9e9;
                                            padding: 0 15px;
                                            width: 100%;
                                            max-width: 400px;
                                            &:focus {
                                                background-color: #f2f5f8;
                                                outline: 0;
                                            }
                                        }
                                        textarea {
                                            border-radius: 15px;
                                            border: 1px solid #e9e9e9;
                                            resize: none;
                                            background-color: #fdfdfd;
                                            padding: 15px;
                                            width: 100%;
                                            max-width: 400px;
                                            &:focus {
                                                outline: 0;
                                            }
                                        }
                                        fieldset.sv_qcbc {
                                            .svda-add-new-item {
                                                color: #ccc;
                                                transition: all 0.3s ease;
                                                svg {
                                                    fill: #ccc;
                                                }
                                                &:hover {
                                                    color: #ce2e6c;
                                                    svg {
                                                        fill: #ce2e6c;
                                                    }
                                                }
                                            }
                                            .sv_q_select_column {
                                                min-width: initial;
                                            }
                                            .sv_q_checkbox {
                                                border: 0;
                                                padding: 10px 0;
                                                margin-bottom: 0;
                                                border-radius: 5px;
                                                // display: flex;
                                                .sv_q_checkbox_label {
                                                    margin: 0;
                                                    display: flex;
                                                    align-items: center;
                                                    white-space: nowrap;
                                                }
                                            }
                                            .sv_q_radiogroup {
                                                border: 0;
                                                padding: 10px 0;
                                                margin-bottom: 0;
                                                border-radius: 5px;
                                                // display: flex;
                                                .sv_q_radiogroup_label {
                                                    margin: 0;
                                                    display: flex;
                                                    align-items: center;
                                                    white-space: nowrap;
                                                    .circle {
                                                        margin-right: 5px;
                                                    }
                                                }
                                            }
                                            .br-theme-css-stars .br-widget a {
                                                width: 30px;
                                                height: 30px;
                                                display: inline-flex;
                                                align-items: center;
                                                justify-content: center;
                                                &:after {
                                                    color: #d2d2d2;
                                                    font-size: 35px;
                                                }
                                            }
                                        }
                                        .sv_select_wrapper {
                                            select {
                                                -moz-appearance: none;
                                                -webkit-appearance: none;
                                                width: 100%;
                                                border: 1px solid #e9e9e9;
                                                padding: 10px 20px;
                                                border-radius: 7px;
                                                max-width: 400px;
                                                background-image: url(../images/dropdown-arrow_c2.svg);
                                                background-size: 14px;
                                                background-position: center right 14px;
                                                background-repeat: no-repeat;
                                                &:focus {
                                                    outline: 0;
                                                }
                                            }
                                            .svda-select-items-title {
                                                top: -30px;
                                                right: 40px;
                                                .svd-main-color {
                                                    color: #ce2e6c;
                                                }
                                            }
                                            .svda-select-items-content {
                                                .svda-select-items-collection {
                                                    .item_draggable {
                                                        padding: 4px 10px;
                                                    }
                                                }
                                                .item_editable {
                                                    padding: 4px 10px;
                                                    font-family: $Font;
                                                    font-size: 0.8rem;
                                                    color: #656565;
                                                }
                                            }
                                        }

                                        .sv_q_matrix_dropdown {
                                            margin-top: 20px;
                                            thead {
                                                background-color: #ce2e6c;
                                                color: #fff;

                                                tr {
                                                    th {
                                                        text-align: center;
                                                        font-size: 0.85rem;
                                                        font-family: $SemiBoldFont;
                                                        padding: 10px 0;
                                                        border: 1px solid #e9e9e9;
                                                    }
                                                }
                                            }
                                            tbody {
                                                tr,
                                                td {
                                                    padding: 10px;
                                                    border: 1px solid #e9e9e9;
                                                    text-align: left;
                                                }
                                                td {
                                                    &:first-child {
                                                        font-size: 0.85rem;
                                                        color: #504658;
                                                        font-family: $SemiBoldFont;
                                                    }
                                                }
                                                td .sv_qcbc {
                                                    .sv_q_radiogroup {
                                                        border: 0;
                                                        padding: 0px 10px;
                                                        margin-bottom: 0px;
                                                        border-radius: 5px;
                                                        display: inline-block;
                                                        .sv_q_radiogroup_label {
                                                            font-weight: normal;
                                                            font-family: $Font;
                                                            font-size: 0.8rem;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .sv_q_title {
                                        color: #1c1c1c;
                                        font-size: 1rem;
                                        // text-transform: capitalize;
                                        margin: 20px 0 0;
                                        font-family: $SemiBoldFont;
                                        line-height: 28px;
                                        border-bottom: 1px solid transparent;
                                        title-editor .svda-content .svda-title-editor-content {
                                            color: #1c1c1c;
                                            font-size: 1rem;
                                            border-bottom: 1px solid transparent;
                                            input {
                                                color: #1c1c1c;
                                                font-size: 1rem;
                                                &:focus {
                                                    border-color: #e1e1e1 !important;
                                                }
                                            }
                                        }

                                        + .sv_q_description.description_editable {
                                            padding: 0;
                                            margin-bottom: 30px;
                                        }
                                    }
                                    .sv_q_num {
                                        color: #ce2e6c;
                                        font-size: 0.9rem;
                                    }

                                    .sv_q_imgsel.sv_q_imagepicker_inline.checked .sv_q_imgsel_label > div {
                                        border-color: #ce2e6c;
                                        &:after {
                                            content: "";
                                            background-image: url(../images/widget/image-check.svg);
                                            background-size: 18px;
                                            background-position: center;
                                            background-repeat: no-repeat;
                                            display: inline-flex;
                                            height: 24px;
                                            width: 24px;
                                            position: absolute;
                                            top: -10px;
                                            right: -12px;
                                        }
                                    }

                                    .sv_imgsel .sv_q_imgsel .sv_q_imgsel_label > div {
                                        background: transparent;
                                        border-radius: 7px;
                                        overflow: hidden;
                                        padding: 8px;
                                        border-color: #e9e9e9;
                                        position: relative;
                                        overflow: visible;
                                        .sv_q_imgsel_image {
                                            border-radius: 7px !important;
                                            object-fit: cover !important;
                                            width: 100px;
                                            height: 80px;
                                        }
                                    }
                                    .sv_imgsel .sv_q_imgsel:checked .sv_q_imgsel_label > div {
                                        background-color: transparent;
                                    }

                                    .br-theme-css-stars .br-widget a {
                                        height: 40px;
                                        width: 40px;
                                        margin-right: 15px;
                                        &:last-child {
                                            margin-right: 0;
                                        }
                                        &:after {
                                            content: "";
                                            background-image: url(../images/widget/blank-star.svg);
                                            background-size: 30px;
                                            background-position: center;
                                            background-repeat: no-repeat;
                                            display: inline-flex;
                                            height: 40px;
                                            width: 40px;
                                        }
                                        &.br-active:after {
                                            background-image: url(../images/widget/pink-star.svg);
                                        }
                                        &.br-selected:after {
                                            background-image: url(../images/widget/pink-star.svg);
                                        }
                                    }

                                    select-items-editor {
                                        display: none;
                                    }

                                    .sv_q_rating {
                                        margin: 0 -10px;
                                        .sv_q_rating_item {
                                            padding: 0 10px;
                                            display: inline-block;
                                            margin-bottom: 15px;
                                        }
                                        .sv_q_rating_item_text {
                                            border: 1px solid #b7b7b7;
                                            padding: 7px 10px;
                                            border-radius: 3px;
                                            color: #707070;
                                            font-size: 0.85rem;
                                            font-family: $SemiBoldFont;
                                            display: inline-flex;
                                            align-items: center;
                                        }
                                        .svda-add-new-rating-item {
                                            margin-top: 4px;
                                            background-color: #e1e1e1;
                                            display: inline-flex;
                                            padding: 10px 10px;
                                            border-radius: 3px;
                                            cursor: pointer;
                                        }
                                    }

                                    .sv_q_file {
                                        .sv_q_file_choose_button {
                                            position: relative;
                                            min-width: 164px;
                                            max-width: 164px;
                                            border: 0;
                                            background: transparent;
                                            height: 38px;
                                            text-indent: 8px;
                                            cursor: pointer;
                                            &:before {
                                                content: "";
                                                background-image: url(../images/widget/upload-file.svg);
                                                background-size: 163px;
                                                background-position: top left;
                                                background-repeat: no-repeat;
                                                height: 100%;
                                                min-width: 164px;
                                                max-width: 164px;
                                                position: absolute;
                                                left: 0;
                                                top: 0;
                                                z-index: 9999;
                                            }
                                            &:focus {
                                                outline: 0;
                                            }
                                        }
                                        .sv_q_file_remove_button {
                                            background-color: #504658;
                                            color: #fff;
                                            border: 0;
                                            min-height: 30px;
                                            border-radius: 5px;
                                            padding: 0 16px;
                                            margin: 0;
                                            font-family: "NunitoSans";
                                            font-size: 0.8rem;
                                            display: inline-flex;
                                            align-items: center;
                                        }

                                        .sv_q_file_preview {
                                            background: transparent;
                                            border-radius: 7px;
                                            padding: 8px;
                                            border-color: #e9e9e9;
                                            position: relative;
                                            overflow: visible;
                                            img {
                                                border-radius: 7px;
                                            }
                                            a {
                                                color: #504658;
                                                font-size: 0.73rem;
                                                margin: 0 0 10px;
                                                display: inline-block;
                                                max-width: 220px;
                                            }
                                            .sv_q_file_remove {
                                                color: #c5c5c5;
                                                font-size: 0.85rem;
                                                display: flex;
                                                margin: 10px 0 2px;
                                                align-items: center;
                                                justify-content: center;
                                                &:before {
                                                    content: "";
                                                    display: inline-block;
                                                    background-image: url(../images/table_delete_ic.svg);
                                                    background-size: 9px;
                                                    background-position: center;
                                                    background-repeat: no-repeat;
                                                    height: 24px;
                                                    width: 24px;
                                                    margin-right: 2px;
                                                    transition: background-image 0.3 ease;
                                                }
                                                &:hover {
                                                    color: #ce2e6c;
                                                    &:before {
                                                        background-image: url(../images/table_delete_active_ic.svg);
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .slider.slider-horizontal {
                                        width: calc(100% - 60px);
                                        margin: 30px 30px 0;
                                        max-width: 500px;
                                        .slider-track {
                                            height: 10px;
                                            width: 100%;
                                            margin-top: -5px;
                                            top: 50%;
                                            left: 0;
                                            border: 1px solid #707070;
                                            background: transparent;
                                            border-radius: 25px;
                                        }
                                        .slider-selection {
                                            background: #ce2e6c;
                                        }
                                        .slider-handle {
                                            background-image: url(../images/widget/slider-scale-circle.svg);
                                            background-size: 30px;
                                            background-position: center;
                                            background-repeat: no-repeat;
                                            background-color: transparent;
                                            box-shadow: none;
                                            height: 30px;
                                            width: 30px;
                                            margin-top: -3px;
                                            &:before {
                                                content: "";
                                                height: 10px;
                                                width: 10px;
                                                background: #fff;
                                                position: absolute;
                                                border: 1px solid #ce2e6c;
                                                transform: rotate(45deg) translateX(-50%);
                                                bottom: 32px;
                                                border-top-color: transparent;
                                                border-left-color: transparent;
                                                z-index: 1;
                                                left: 50%;
                                            }
                                            &:after {
                                                content: attr(aria-valuenow);
                                                height: 30px;
                                                width: 42px;
                                                display: inline-flex;
                                                justify-content: center;
                                                align-items: center;
                                                position: absolute;
                                                bottom: 40px;
                                                border: 1px solid #ce2e6c;
                                                border-radius: 7px;
                                                left: 50%;
                                                transform: translateX(-50%);
                                                color: #1c1c1c;
                                                font-size: 0.78rem;
                                                font-family: $SemiBoldFont;
                                            }
                                        }
                                    }

                                    .widget-datepicker {
                                        width: 100%;
                                        border: 1px solid #e9e9e9;
                                        padding: 10px 20px;
                                        border-radius: 7px;
                                        max-width: 400px;
                                        background-image: url(../images/widget/calendar.svg);
                                        background-size: 18px;
                                        background-position: center right 14px;
                                        background-repeat: no-repeat;
                                        &:focus {
                                            outline: 0;
                                            box-shadow: none;
                                        }
                                    }

                                    .sjs-sortablejs-root {
                                        max-width: 500px;
                                    }
                                    .sjs-sortablejs-result {
                                        border: 1px solid #e9e9e9 !important;
                                        border-radius: 5px;
                                        padding: 20px;
                                        color: #c5c5c5;
                                        font-size: 0.8rem;
                                        font-weight: normal;
                                        min-height: 100px !important;
                                        margin-bottom: 30px;
                                        > div {
                                            display: flex;
                                            align-items: center;
                                            width: 100%;
                                            &:before {
                                                content: "";
                                                height: 44px;
                                                background: transparent;
                                                border: 1px solid #f2f5f8;
                                                border-radius: 6px;
                                                display: inline-flex;
                                                justify-content: center;
                                                align-items: center;
                                                overflow: hidden;
                                                width: 60px;
                                                min-width: 60px;
                                                background-image: url(../images/field_tab_icon-active.svg);
                                                background-size: 20px;
                                                background-position: center;
                                                background-repeat: no-repeat;
                                                margin-right: 10px;
                                            }
                                        }
                                        .sjs-sortablejs-item {
                                            background: transparent !important;
                                            color: #ce2e6c !important;
                                            border-radius: 6px;
                                            width: 100%;
                                            border: 1px solid #ce2e6c;
                                            padding: 10px 20px !important;
                                            font-size: 1rem;
                                        }
                                    }
                                    .sjs-sortablejs-source {
                                        border: 1px solid #e9e9e9 !important;
                                        border-radius: 5px;
                                        padding: 20px;
                                        min-height: 100px !important;
                                        > div {
                                            display: flex;
                                            align-items: center;
                                            width: 100%;
                                            &:before {
                                                content: "";
                                                height: 44px;
                                                background: transparent;
                                                border: 1px solid #f2f5f8;
                                                border-radius: 6px;
                                                display: inline-flex;
                                                justify-content: center;
                                                align-items: center;
                                                overflow: hidden;
                                                width: 60px;
                                                min-width: 60px;
                                                background-image: url(../images/field_tab_icon.svg);
                                                background-size: 20px;
                                                background-position: center;
                                                background-repeat: no-repeat;
                                                margin-right: 10px;
                                            }
                                        }
                                        .sjs-sortablejs-item {
                                            background: transparent !important;
                                            color: #8d8d8d !important;
                                            border-radius: 6px;
                                            width: 100%;
                                            border: 1px solid #f2f5f8;
                                            padding: 10px 20px !important;
                                        }
                                    }
                                    .sv-boolean--indeterminate {
                                        .sv-boolean__slider {
                                            // margin-left: unset;
                                        }
                                    }

                                    .sv-boolean__switch {
                                        border: 1px solid #dbdbdb;
                                        background-color: transparent;
                                        height: 36px;
                                        border-radius: 25px;
                                        width: 70px;
                                        padding: 4px 9px 4px 5px;
                                        .sv-boolean__slider {
                                            background-color: #dbdbdb;
                                            height: 26px;
                                            width: 26px;
                                        }
                                    }
                                    .sv-boolean--checked.checked {
                                        .sv-boolean__switch {
                                            background-color: #ce2e6c;
                                            border-color: #ce2e6c;
                                        }
                                        .sv-boolean__slider {
                                            background-color: #fff;
                                        }
                                    }

                                    // survey js first page preview css  End
                                }
                            }
                        }
                    }
                    /*
                    ** survey designer tab id wrapper for all child css End
                    */
                }
            }
            svd-splitter {
                // display: none;
            }
        }

        .svd-designer-container--right-side {
            position: relative;

            .svd-icon-container--right-open,
            .svd-icon-container--right-close {
                position: absolute;
                left: -16px;
            }

            .svd-designer-container {
                background-color: #fff;
                border-radius: 5px;
            }
            .svd-designer-container__header {
                text-align: left;
            }
            .svd_properties {
                background-color: #fff;
                svd-accordion {
                    .svd-accordion-tab-header {
                        border-radius: 9px;
                        background-color: #f2f5f8;
                        margin-bottom: 20px;
                        border: 0;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                        > span {
                            display: block;
                            width: 100%;
                            padding: 6px 20px;
                            background-image: url(../images/dropdown-arrow_c2.svg);
                            background-size: 14px;
                            background-position: center right 20px;
                            background-repeat: no-repeat;
                            &.svd-main-color {
                                background-color: #504658;
                                color: #fff;
                                background-image: url(../images/dropdown_icon.svg);
                            }
                        }
                    }
                    svg-icon {
                        display: none;
                        // margin-left: auto;
                        // background-image: url(../images/breadcrumb‭_arrow.svg);
                        // background-size: 7px;
                        // background-position: center;
                        // background-repeat: no-repeat;
                        // float: unset;
                        // height: 20px;
                        // width: 20px;
                        // transform: rotate(90deg);
                        // .svd-svg-icon{
                        //   display: none;
                        // }
                    }
                }
                .svd_editor_control {
                    border: 1px solid #e9e9e9;
                    border-radius: 5px;
                    line-height: initial;
                    font-size: 0.85rem;
                }
                textarea {
                    resize: none;
                    border: 1px solid #e9e9e9;
                    border-radius: 5px;
                }

                .svd_custom_select {
                    &:before {
                        border-radius: 0 5px 5px 0;
                    }
                    select {
                        border: 1px solid #e9e9e9;
                        border-radius: 5px;
                        line-height: inherit;
                        padding: 0 12px;
                    }
                }
            }

            .svd_custom_select.svd_property_editor_dropdown {
                &:before {
                    border-radius: 0 5px 5px 0;
                }
            }

            .propertyeditor-condition {
                .svd-expression-header {
                    border-radius: 5px;
                    svg-icon {
                        display: block;
                    }
                }
            }
        }
    }

    /*
    ** Test Survey   tab id wrapper for all child css START
    */
    #surveyjsExample {
        .svd-simulator-content {
            background-color: #ffffff;
            padding: 30px 7%;
            border-radius: 5px;
            overflow-x: hidden;
            position: relative;
            .sv_page_title {
                color: #ce2e6c;
                font-size: 1.2rem;
                // text-transform: capitalize;
                + div[data-bind*="description"] {
                    color: #b7b7b7;
                    font-size: 0.8rem;
                }
            }

            .sv_main.sv_default_css {
                background-color: #ffffff;
            }
            .sv_body.sv_body_empty {
                height: 200px;
                position: static;
                top: initial;
                left: initial;
                transform: initial;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        label[for="testSurveyPageChanged"],
        label[for="testDeviceSelector"],
        label[for="testDeviceOrientation"] {
            margin: 10px 10px 10px 0;
            font-family: $SemiBoldFont;
        }
        #testShowInvisibleElements {
            margin: 6px 20px 6px 0;
            font-family: $SemiBoldFont;
        }
        #testSurveyPageChanged {
            margin: 0 20px 0 0;
            width: initial;
            select {
                background-color: transparent;
                border-radius: 5px;
                padding: 0 10px;
                height: initial;
                min-height: 36px;
                line-height: 36px;
                min-width: 200px;
                font-size: 0.85rem;
                text-transform: capitalize;
            }
        }

        .svd_test_results {
            .svd_results_header {
                button {
                    padding: 10px 20px;
                    border-radius: 25px;
                    font-family: $SemiBoldFont;
                    font-weight: normal;
                    font-size: 0.8rem;
                    color: #fff;
                    margin: 12px 0;
                }
                h2 {
                    font-family: $SemiBoldFont;
                    font-weight: normal;
                    font-size: 1.6rem;
                    color: #504658;
                    margin: 20px 0;
                }
            }
        }

        #testDeviceSelector {
            margin: 0 10px 20px 10px;
            width: initial;
            select {
                background-color: transparent;
                border-radius: 5px;
                padding: 0 10px;
                height: initial;
                min-height: 36px;
                line-height: initial;
                min-width: 200px;
            }
        }

        // @import "preview";
    }

    /*
    ** Test Survey   tab id wrapper for all child css End
    */

    /*
    ** Survey Logic   tab id wrapper for all child css Start
    */

    .propertyeditor-condition {
        textarea {
            resize: none;
            border-radius: 5px;
            background: transparent;
            min-height: 100px;
        }
    }

    .svd_wrap_elements {
        overflow: hidden;
        // @import "preview";
        .sv_main.sv_default_css {
            background-color: #fff;
        }
    }

    /*
    ** Survey Logic   tab id wrapper for all child css End
    */
}

.svd-logic-tab {
    background: #fff;
    padding: 30px;
    height: initial;
    .svd-survey-placeholder-root .svd-survey-placeholder {
        background-color: #fff;
        height: initial;
        .svd-empty-message-container {
            position: initial;
            top: initial;
            bottom: initial;
            left: initial;
            right: initial;
            transform: initial;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            height: 300px;
            .svd-empty-image {
                display: none;
            }
            .svd-empty-message {
                color: #b7b7b7;
                font-size: 1.2rem;
                font-family: $Font;
                font-weight: normal;
            }
            .svd-empty-action {
                border-radius: 25px;
                padding: 0 30px;
                font-family: $Font;
                font-size: 0.85rem;
                font-weight: normal;
                &:focus,
                &:active {
                    outline: 0;
                    box-shadow: none;
                    background-color: #504658;
                    border-color: #504658;
                }
            }
        }
    }
}

/*
* Translation Tab Start
*/
.svd-translation-tab {
    .svd-translation-no-strings {
        font-size: 1.4rem;
        background: #fff;
    }
}
/*
* Translation Tab End
*/
