   /* The ezi-checkbox */
   .ezi-checkbox {
    display: inline-flex;
    align-items: center;
    position: relative;
    height: 25px;
    width: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 20px;
  }
  
  /* Hide the browser's default checkbox */
  .ezi-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .ezi-checkbox-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 20px;
    border: 1px solid #C5C5C5;
    border-radius:3px;
  }
  
  /* On mouse-over, add a grey background color */
  .ezi-checkbox:hover input ~ .ezi-checkbox-mark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .ezi-checkbox input:checked ~ .ezi-checkbox-mark {
    background-color: #CE2E6C;
    border-color: #CE2E6C;
  }
  
  /* Create the ezi-checkbox-mark/indicator (hidden when not checked) */
  .ezi-checkbox-mark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the ezi-checkbox-mark when checked */
  .ezi-checkbox input:checked ~ .ezi-checkbox-mark:after {
    display: block;
  }
  
  /* Style the ezi-checkbox-mark/indicator */
  .ezi-checkbox .ezi-checkbox-mark:after {
    left: 6px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }