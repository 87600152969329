@import "../../assets/scss/variable";
@import "../../assets/scss/placeholder";
.Page-AddCategory{
    height: 100%;
    overflow-y: auto;
    padding: 40px 7%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    .template-add-beadcrumb{
        border-bottom: 0;
    }
    .error-message {
        color: #ff0000;
        font-size: 14px;
        margin: 5px 0;
        display: block;
        padding-left: 30px;
        font-family: $SemiBoldFont;    
    }

    form{
        width:100%;
        display: flex;
        justify-content: center;
    }
    .survey-category-wrap{
        display: flex;
        flex-direction: column;
        max-width: 450px;
        width: 100%;
        .category_label{          
            color: #504658;
            font-size: 1.8rem;
            font-family: $Font;
            text-align: center;
            margin: 0 0 40px;
        }
        .category-field-control{            
            margin-bottom: 30px;
        }
        .create-category-input{
            width:100%;
            background-color: #fff;
            border-radius: 25px;
            border: 0;
            padding: 14px 30px;
            box-shadow: -1px 7px 39px rgba(0,0,0,0.11);
            font-size: 1rem;
            color:#504658;
            resize: none;
            &:focus{
                outline: 0;
            }
            @include placeholder {
                color: #B7B7B7
            }
            &::-webkit-scrollbar {
                width: 4px;
                background-color: #F5F5F5;
            }
        }
        .category_add_btn_wrap{
            display: flex;
            justify-content: center;
        }
        .category_add_btn{
            background: #504658;
            color: #fff;
            border: 0;
            border-radius: 25px;
            padding: 8px 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            &:focus{
                outline: 0;
            }
            .spinner-grow{
                margin-left: 10px;
            }
            
        }
    }

    .category-field-control .ezi-select-plugin {
        background-color: #fff;
        border-radius: 25px;
        border: 0;
        width: 100%;
        padding: 7px 0px;
        box-shadow: -1px 7px 39px rgba(0, 0, 0, 0.11);
        font-size: 1rem;
        color: #504658;
        resize: none;
        &.css-14jk2my-container{
            pointer-events: initial;
            &:hover{
                cursor: not-allowed ;
                pointer-events: initial;
                .css-1ht9tev-control{
                    pointer-events: none;
                    cursor: not-allowed ;
                }
                .css-1hwfws3{
                    pointer-events: none;
                    cursor: not-allowed ;
                }
            }
            
        }
        .css-c8odyh-control,.css-1l3nnzh-control{
            border-radius: 25px;
            padding: 0 20px;
        }
        .css-26l3qy-menu{
            border-radius: 12px;
            padding: 10px 0;
        }
        .css-1ht9tev-control{
            padding: 0 20px;
        }
    }
    
}

.Page-SurveyCategory{
    padding: 40px 7%;
    background-color: #F2F5F8;
    &.no-result-formatter{
        height: 100%;
        .survey_card_c-wrap{
            height: calc(100% - 112px);
        }
    }
    .survey_card_c-wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;
        .survey_card_c {
            position: relative;
            background-color: #fff;
            border-radius: 18px;
            display: inline-flex;
            flex-direction: column;
            width:calc(25% - 60px);
            min-width: 230px;
            padding: 30px;
            margin: 0 20px 40px;
            -moz-box-shadow: -1px 7px 91px rgba(0,0,0,0.08);
            -webkit-box-shadow: -1px 7px 91px rgba(0,0,0,0.08);
            box-shadow: -1px 7px 91px rgba(0,0,0,0.08);
            max-height: 180px;
            min-height: 180px;
            overflow: hidden;
            cursor: pointer;
            &:hover:before{
                content: '';
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                border-radius: 18px;
                border:1px solid #CE2E6C;
            }
            .category-icon-wrapper{
                position: absolute;
                right: 7px;
                top: 5px;
            }            
            @mixin icon-default{
                background-position: center;
                background-repeat: no-repeat;
                height: 30px;
                width: 30px;
                cursor: pointer; 
                display: none;
              }

            &:hover .category-delte_ic{   
                background-image: url(../../assets/images/table_delete_ic.svg); 
                background-size: 13px;
                display: inline-block;
                &:hover{
                    background-image: url(../../assets/images/table_delete_active_ic.svg);
                }
            }
            &:hover .category-edit_ic{
                background-image: url(../../assets/images/table_edit_ic.svg);                
                background-size: 19px;
                display: inline-block;
                &:hover{
                    background-image: url(../../assets/images/table_edit_actives_ic.svg);
                }
            }
            .category-delte_ic{
                @include icon-default ;               
            }
            .category-edit_ic{
                @include icon-default ;   
            }

            .survey_card_add_ic{
                background-image: url(../../assets/images/create_new_survey_ic.svg);
                background-size: 33px;
                background-position: center;
                background-repeat: no-repeat;
                background-color: #CE2E6C;
                display: inline-flex;
                align-items: center;
                height: 60px;
                min-height: 60px;
                width: 60px;
                min-width: 60px;
                border-radius: 50%;
                justify-content: center;
                margin: 0 auto 10px;
            }
            .survey_card_add-label{
                color: #CE2E6C;
                font-family: $SemiBoldFont;
                font-size: 1rem;
                text-align: center;
                margin: 0 ;
            }
        }
        .survey_card_c-label{
            color:#CE2E6C;
            font-family: $SemiBoldFont;
            font-size: 1rem;
            text-align:center;
            margin: 0 0 5px;
            text-transform: capitalize;
        }
        .survey_card_c-des{
            color:#C5C5C5;
            font-family:$LightFont;
            font-size: 0.85rem;
            line-height: 24px;
            margin: 0;
            text-align:center;
            overflow: hidden;
        }
    }

    @media (max-width:600px){
        .survey_card_c-wrap{
            justify-content: center;
        }
    }

}